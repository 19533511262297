import React from 'react'
import {
  PiFile,
  PiFileAudio,
  PiFileImage,
  PiFileText,
  PiFileVideo,
} from 'react-icons/pi'
import { DocumentType } from '@/enums/DocumentType.ts'

export const getDocumentIcon = (type: DocumentType) => {
  const icons: { [key: string]: React.ReactNode } = {
    [DocumentType.Pdf]: <PiFileImage className="w-5 h-5 text-red-500" />,
    [DocumentType.Image]: <PiFileImage className="w-5 h-5 text-blue-500" />,
    [DocumentType.Video]: <PiFileVideo className="w-5 h-5 text-purple-500" />,
    [DocumentType.Audio]: <PiFileAudio className="w-5 h-5 text-green-500" />,
    [DocumentType.Text]: <PiFileText className="w-5 h-5 text-gray-500" />,
    [DocumentType.WebPage]: <PiFile className="w-5 h-5 text-blue-500" />,
    [DocumentType.AiContent]: <PiFile className="w-5 h-5 text-blue-500" />,
    [DocumentType.AiContentCode]: <PiFile className="w-5 h-5 text-blue-500" />,
  }
  return icons[type] || <PiFile className="w-5 h-5 text-gray-500" />
}
