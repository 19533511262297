import React from 'react'
import { Button, Card } from '@nextui-org/react'
import { ChevronRightIcon } from '@nextui-org/shared-icons'
import { getDocumentIcon } from '@/utils/getDocumentIcon.tsx'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import useChatStore from '@states/chatStore.ts'
import { shallow } from 'zustand/shallow'

type DocumentItemCardProps = {
  item: iDocumentItem
  onPress: (item: iDocumentItem, pageNumber?: number) => void
}

const DocumentItemCard = ({ item, onPress }: DocumentItemCardProps) => {
  const fileType = item.documentType
  const filename = item.title

  const { messageInView } = useChatStore(
    (state) => ({
      messageInView: state.messageInView,
    }),
    shallow,
  )

  const matchingReferences: {
    startOffset: number
    sourceId: number
    description: string
    timestamp: string
  }[] = []

  item._documentChunks?.forEach((chunk) => {
    chunk.references.forEach((ref) => {
      if (ref.messageId === messageInView) {
        matchingReferences.push({
          startOffset: chunk.excerpt.startOffset,
          sourceId: chunk.sourceId,
          description: ref.description,
          timestamp: ref.timestamp,
        })
      }
    })
  })

  const hasMatchingReference = matchingReferences.length > 0

  return (
    <Card
      className={`p-4 hover:shadow-md w-full mt-2
      ${hasMatchingReference ? '' : 'bg-default bg-opacity-15'}`}
    >
      <div className="flex w-full items-center gap-3 truncate">
        {getDocumentIcon(fileType)}
        <div className="flex-1 text-start min-w-0 truncate">
          <h3 className="font-medium truncate">{filename}</h3>
          <p className="text-sm text-gray-500">
            Found {item._references?.length || 0} reference(s)
          </p>
          {hasMatchingReference && (
            <div className="mt-2">
              <p className="text-xs">Message References:</p>
              <ul className="text-sm">
                {matchingReferences.map((ref, index) => (
                  <li
                    key={index}
                    className="cursor-pointer hover:underline"
                    onClick={() => onPress(item, ref.startOffset)}
                  >
                    <span className="text-xs text-blue-500">[{ref.sourceId}]</span>
                    <span className="text-xs "> - </span>
                    {ref.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Button isIconOnly variant="light" size="lg" onPress={() => onPress(item)}>
          <ChevronRightIcon className="w-4 h-4" />
        </Button>
      </div>
    </Card>
  )
}

export default DocumentItemCard
