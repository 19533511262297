import React, { useCallback, useEffect, useState } from 'react'
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  Spinner,
} from '@nextui-org/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import SearchResultItem from '@components/spotlight/SearchResultItem.tsx'
import sessionService from '@/services/sessionService.ts'
import toolService from '@/services/toolService.ts'
import knowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { useNavigate } from 'react-router-dom' // For navigation

interface SpotlightSearchProps {
  onClose: () => void
}

function SpotlightSearch({ onClose }: SpotlightSearchProps) {
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate() // For navigation

  // Function to execute search
  const executeSearch = async () => {
    if (!searchValue.trim()) {
      setSearchResults([])
      return
    }

    setLoading(true)
    try {
      const [
        messagesResult,
        sessionsResult,
        toolsResult,
        knowledgeContainersResult,
      ] = await Promise.all([
        sessionService.getMessages('all', { searchValue, limit: 3 }),
        sessionService.getSessions({ searchValue, limit: 3 }),
        toolService.getTools({ searchValue, limit: 3 }),
        knowledgeContainerService.getKnowledgeContainers({ searchValue, limit: 3 }),
      ])

      const results = [
        ...messagesResult.data.map((item) => ({ type: 'message', item })),
        ...sessionsResult.data.map((item) => ({ type: 'session', item })),
        ...toolsResult.data.map((item) => ({ type: 'tool', item })),
        ...knowledgeContainersResult.data.map((item) => ({
          type: 'knowledgeContainer',
          item,
        })),
      ]

      setSearchResults(results)
    } catch (error) {
      console.error('Error during search:', error)
    } finally {
      setLoading(false)
    }
  }

  // Debounce the search execution
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      executeSearch()
    }, 300) // Adjust debounce time as needed

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue])

  const addToContext = useCallback((item: any, type: string) => {
    // Implement the logic to add the item to the current context
    console.log(`Adding ${type} to context:`, item)
    // You might want to update some global state or call an API here
  }, [])

  return (
    <Modal
      scrollBehavior="inside"
      backdrop="blur"
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: 'max-w-3xl w-full',
        header: 'border-b border-gray-200 dark:border-gray-700',
        body: 'p-0',
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <Input
            placeholder="Search across all resources..."
            value={searchValue}
            onValueChange={setSearchValue}
            startContent={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />}
            fullWidth
            autoFocus={true}
            autoComplete={'on'}
            size="lg"
            classNames={{
              input: 'text-lg',
              inputWrapper: 'h-12',
            }}
          />
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner size="lg" />
            </div>
          ) : searchResults.length > 0 ? (
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {searchResults.map((result, index) => (
                <li key={index}>
                  <SearchResultItem
                    result={result}
                    onClose={onClose}
                    navigate={navigate}
                    addToContext={addToContext}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center py-12 text-gray-500 dark:text-gray-400">
              No results found. Try adjusting your search terms.
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SpotlightSearch
