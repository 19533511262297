import { createWithEqualityFn } from 'zustand/traditional'
import { iSession } from '@/interfaces/iSession.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'
import AssistantService from '@/services/assistantService.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import SessionService from '@/services/sessionService.ts'
import { iAssistantAction } from '@/interfaces/iAssistantAction.ts'
import { uuidv4 } from '@/utils/uuidv4.ts'
import UserService from '@/services/UserService.ts'

export interface iChatState {
  chatControlContentRoute: { contentName?: string; route?: string; value?: any }

  isClearingChatMessages: boolean
  isActivatingSession: boolean // is  true if session is switched
  isAssistantGenerating: boolean
  isAssistantRunning: boolean
  isLoadingAssistant: boolean
  isLoadingMessages: boolean
  isLoadingKnowledgeContainer: boolean
  isLoadingTools: boolean
  isLoadingSession: boolean // set during GET/PUT/POST/DELETE session
  messages: iMessage[]
  knowledgeContainers: iKnowledgeContainer[]
  tools: iTool[]
  session: iSession | null
  assistant: iAssistant | null
  currentAssistantAction: iAssistantAction | null
  isChatControlContentOpen: boolean
  isChatControlContentPinned: boolean
  messageInView: string | null
  messageToScrollToId: string | null
}

export interface iChatStore extends iChatState, Omit<iDefaultStore, 'init'> {
  fetchMessages: (
    sessionId: string,
    options?: { offset?: number; disableLoading?: boolean },
  ) => Promise<{ data: iMessage[]; total: number }>
  setMessages: (messages: iMessage[]) => void
  setKnowledgeContainers: (messages: iKnowledgeContainer[]) => void
  setTools: (messages: iTool[]) => void
  setSession: (session: iSession | null) => void
  activateChatSession: (session: iSession) => Promise<void>
  setAssistant: (assistant: iAssistant) => void
  setIsLoadingSession: (loading: boolean) => void
  setIsAssistantGenerating: (generating: boolean) => void
  setIsAssistantRunning: (running: boolean) => void
  setCurrentAssistantAction: (action: iAssistantAction | null) => void
  setIsClearingChatMessages: (clearing: boolean) => void
  setIsChatControlContentOpen: (open: boolean) => void
  setIsChatControlContentPinned: (pinned: boolean) => void
  clearChatMessages: () => Promise<void>
  fetchTools: (assistantId: string) => Promise<void>
  fetchKnowledgeContainers: (assistantId: string) => Promise<void>

  setChatControlContentRoute<T>(contentName: string, route?: string, value?: T): void
  changeAssistant: (assistant: iAssistant) => Promise<void>
  createMessage: (message: iMessage) => Promise<iMessage | undefined>
  deleteMessage: (message: iMessage) => Promise<void>
  updateMessage: (message: iMessage) => Promise<void>
  updateSession: (session: iSession) => Promise<void>
  updateAssistant: (session: iAssistant) => Promise<void>
  setMessageInView: (messageId: string | null) => void
  goToMessageById: (messageId: string | null) => void
}

const initialState: iChatState = {
  chatControlContentRoute: {},
  isActivatingSession: true,
  // is true if message is generating
  isAssistantGenerating: false,
  // is true until assistant isn´t finished eg. with title generation ...
  isAssistantRunning: false,
  isLoadingAssistant: true,
  isLoadingMessages: true,
  isLoadingKnowledgeContainer: true,
  isLoadingTools: true,
  isLoadingSession: true,
  messages: [],
  assistant: null,
  knowledgeContainers: [],
  tools: [],
  session: null,
  currentAssistantAction: null,
  isClearingChatMessages: false,
  isChatControlContentOpen: false,
  isChatControlContentPinned: false,
  messageInView: null,
  messageToScrollToId: null,
}
const useChatStore = createWithEqualityFn<iChatStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async () => {
    getState().reset()
  },
  setChatControlContentRoute: (contentName, route, value) =>
    set({
      chatControlContentRoute: {
        ...{
          contentName,
          route,
          value,
        },
      },
    }),
  setIsLoadingSession: (isLoadingSession) => set({ isLoadingSession }),
  setAssistant: (assistant) => set({ assistant: { ...assistant } }),
  setSession: (session) => set({ session: session ? { ...session } : session }),
  setCurrentAssistantAction: (currentAssistantAction: iAssistantAction | null) =>
    set({ currentAssistantAction }),
  setIsAssistantGenerating: (isAssistantGenerating) =>
    set({ isAssistantGenerating }),
  setIsClearingChatMessages: (isClearingChatMessages) =>
    set({ isClearingChatMessages }),
  setIsAssistantRunning: (isAssistantRunning: boolean) =>
    set({ isAssistantRunning }),
  setIsChatControlContentOpen: (isChatControlContentOpen: boolean) =>
    set({ isChatControlContentOpen }),
  setIsChatControlContentPinned: (isChatControlContentPinned: boolean) =>
    set({ isChatControlContentPinned }),
  setTools: (tools) => set({ tools }),
  setMessages: (messages) => set({ messages: [...messages] }),
  setKnowledgeContainers: (knowledgeContainers) => set({ knowledgeContainers }),
  setMessageInView: (messageId) => set({ messageInView: messageId }),
  goToMessageById: (messageId) => set({ messageToScrollToId: messageId }),
  updateSession: async (session) => {
    getState().setIsLoadingSession(true)
    getState().setSession(session)
    await SessionService.updateSession(session)
    getState().setIsLoadingSession(false)
  },
  fetchMessages: async (
    sessionId: string,
    options?: { offset?: number; disableLoading?: boolean },
  ) => {
    !options?.disableLoading && set({ isLoadingMessages: true })
    const response = await SessionService.getMessages(sessionId, {
      offset: options?.offset,
    })
    const newMessages =
      (options?.offset ?? 0) > 0
        ? [...getState().messages, ...response.data]
        : response.data
    getState().setMessages(newMessages)
    !options?.disableLoading && set({ isLoadingMessages: false })
    return response
  },
  fetchTools: async (assistantId: string) => {
    set({ isLoadingTools: true })
    AssistantService.getAssistantTools(assistantId)
      .then((response) => {
        getState().setTools(response)
      })
      .finally(() => {
        set({ isLoadingTools: false })
      })
  },
  fetchKnowledgeContainers: async (assistantId: string) => {
    set({ isLoadingKnowledgeContainer: true })
    AssistantService.getAssistantKnowledgeContainer(assistantId)
      .then((response) => {
        getState().setKnowledgeContainers(response)
      })
      .finally(() => {
        set({ isLoadingKnowledgeContainer: false })
      })
  },
  changeAssistant: async (assistant) => {
    const session = getState().session
    if (!session) return
    const assistantId = assistant.id!
    // update Session
    session.assistantId = assistantId
    getState().updateSession(session)

    await AssistantService.updateAssistant(assistant)

    //load assistant specific tools
    getState().fetchTools(assistantId)

    // load assistant specific kc´s
    getState().fetchKnowledgeContainers(assistantId)
  },
  updateAssistant: async (assistant) => {
    set({ isLoadingAssistant: true })
    getState().setAssistant(assistant)
    await AssistantService.updateAssistant(assistant)
    set({ isLoadingAssistant: false })
  },

  activateChatSession: async (session: iSession) => {
    set({ isActivatingSession: true })
    // reset store to default state values to avoid data mix ups
    getState().reset()
    try {
      // to be up-to-date, we fetch a session again
      const fetchedSession = await SessionService.getSession(session.id!)
      set({ session: fetchedSession })

      // fetch session specific assistant based on blueprint or default
      const assistant = await AssistantService.getAssistant(session.assistantId)
      getState().setAssistant(assistant)

      const assistantId = session?._assistant?.baseAssistantId ?? session.assistantId

      if (session) await UserService.setLastSessionId(session.id!)
      //load assistant specific tools
      getState().fetchTools(assistantId)

      // load assistant specific kc´s
      getState().fetchKnowledgeContainers(assistantId)
    } catch (error) {
      console.error(error)
    } finally {
      set({ isActivatingSession: false })
    }
  },

  clearChatMessages: async () => {
    set({ isClearingChatMessages: true })
    const session = getState().session
    if (!session) return
    try {
      await SessionService.deleteMessages(session.id!)
      getState().setMessages([])
    } catch (error) {
      console.error(error)
    } finally {
      set({ isClearingChatMessages: false })
    }
  },

  createMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const tmpId = uuidv4()
      message._tmpId = tmpId
      message._isLoading = true
      messages.unshift(message)
      // set message to display it before calling be
      getState().setMessages(messages)

      const msg = await SessionService.createMessage(session.id!, message)
      const messageIndex = messages.findIndex((message) => message._tmpId! === tmpId)
      messages[messageIndex] = msg
      delete messages[messageIndex]._isLoading
      getState().setMessages(messages)
      return msg
    } catch (error) {
      console.error('Error: ', error)
    }
    return undefined
  },
  updateMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const messageIndex = messages.findIndex((msg) => message.id! === msg.id)
      messages[messageIndex]._isLoading = true
      getState().setMessages(messages)
      await SessionService.updateMessage(session.id!, message)
      delete messages[messageIndex]._isLoading
      getState().setMessages(messages)
    } catch (error) {
      console.error(error)
    } finally {
      /* empty */
    }
  },
  deleteMessage: async (message: iMessage) => {
    try {
      const session = getState().session
      if (!session) return
      const messages = getState().messages || []
      const messageIndex = messages.findIndex((msg) => message.id! === msg.id)
      messages[messageIndex]._isLoading = true
      getState().setMessages(messages)
      await SessionService.deleteMessage(session.id!, message.id!)
      // create an array without the message to delete, go by id
      const newMessages = messages.filter((msg) => msg.id !== message.id)
      getState().setMessages(newMessages)
    } catch (error) {
      console.error(error)
    } finally {
      /* empty */
    }
  },
}))

export default useChatStore
