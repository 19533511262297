import axiosClient from '../utils/axiosClient.ts'
import { iSecret } from '../interfaces/iSecret.ts'

class SecretService {
  private static instance: SecretService

  private constructor() {}

  public static getInstance(): SecretService {
    if (!SecretService.instance) {
      SecretService.instance = new SecretService()
    }
    return SecretService.instance
  }

  public async getSecrets(): Promise<iSecret[]> {
    return (await axiosClient.get(`/secrets`)).data as iSecret[]
  }

  public async createSecret(secret: iSecret): Promise<iSecret> {
    secret.expiryDate = new Date(secret.expiryDate).toJSON()
    return (await axiosClient.post(`/secrets`, secret)).data as iSecret
  }

  public async deleteSecret(secretId: string) {
    return await axiosClient.delete(`/secrets/${secretId}`)
  }

  public async updateSecret(secret: iSecret) {
    secret.expiryDate = new Date(secret.expiryDate).toJSON()
    return await axiosClient.put(`/secrets/${secret.id}`, secret)
  }
}

export default SecretService.getInstance()
