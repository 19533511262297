import { Skeleton } from '@nextui-org/react'
import SkeletonAvatar from '@components/skeletons/SkeletonAvatar.tsx'
import React from 'react'

type SkeletonChatMessageProps = {
  left?: boolean
  messageHeight?: string
}

const SkeletonChatMessage = ({ left, messageHeight }: SkeletonChatMessageProps) => {
  return (
    <div className={`${left && 'flex-row-reverse'} flex items-end`}>
      <Skeleton
        className={`${messageHeight ? messageHeight : 'h-14'} w-full rounded-medium mx-2`}
      />
      <SkeletonAvatar></SkeletonAvatar>
    </div>
  )
}

export default SkeletonChatMessage
