import { iSession } from '@/interfaces/iSession.ts'
import useSessionStore from '@states/sessionStore.ts'
import { iTool } from '@/interfaces/iTool.ts'
import AssistantService from '@/services/assistantService.ts'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import { FAQ_ID_KNOWLEDGE_CONTAINERS, FAQ_ID_TOOLS } from '@/constants/faqIDs.ts'
import FaqItem from '@components/faq/FaqItem.tsx'
import React, { useState } from 'react'
import ToolService from '@/services/toolService.ts'
import { Skeleton, Tab, Tabs } from '@nextui-org/react'
import { Popup } from '@/enums/PopupTypes.ts'
import AssistantSettings from '@components/session/AssistantSettings.tsx'
import ItemSelection from '@components/tools/ItemSelection.tsx'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import useMainStore from '@states/mainState.ts'
import AssistantAvatar from '@components/assistants/AssistantAvatar.tsx'
import useChatStore from '@/states/chatStore.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import SingleItemSelection from '@components/tools/SingleItemSelection.tsx'

const SessionGeneralView = () => {
  const session = useChatStore().session
  const assistant = useChatStore().assistant!
  const updateAssistant = useChatStore().updateAssistant
  const changeAssistant = useChatStore().changeAssistant
  const isLoadingAssistant = useChatStore().isLoadingAssistant
  const isLoadingTools = useChatStore().isLoadingTools
  const isLoadingKnowledgeContainer = useChatStore().isLoadingKnowledgeContainer
  const tools = useChatStore().tools
  const knowledgeContainers = useChatStore().knowledgeContainers
  const setTools = useChatStore().setTools
  const setKnowledgeContainers = useChatStore().setKnowledgeContainers

  const setPopup = useMainStore((state) => state.setPopup)

  const toolFAQ = getFAQById(FAQ_ID_TOOLS)
  const knowledgeContainerFAQ = getFAQById(FAQ_ID_KNOWLEDGE_CONTAINERS)

  const onChangeTools = async (tools: iTool[]) => {
    assistant!.toolIds = tools.map((tool) => tool.id) as string[]
    assistant!._tools = tools

    return updateAssistant(assistant).then(() => {
      setTools(tools)
    })
  }

  const onChangeKnowledgeContainers = async (
    knowledgeContainers: iKnowledgeContainer[],
  ) => {
    assistant!.knowledgeContainerIds = knowledgeContainers.map(
      (kc) => kc.id,
    ) as string[]
    assistant!._knowledgeContainers = knowledgeContainers
    return updateAssistant(assistant).then(() => {
      setKnowledgeContainers(knowledgeContainers)
    })
  }
  const onChangeAssistant = async (assistant: iAssistant) => {
    setTools([])
    setKnowledgeContainers([])
    await changeAssistant(assistant)
  }
  const onChangeAssistantSettings = async (assistant: iAssistant) => {
    console.trace()
    await updateAssistant(assistant)
  }

  return (
    <div>
      <SingleItemSelection
        selectedItem={assistant}
        label="Select Assistant"
        placeholder="Search for an assistant"
        onChange={async (selectedAssistant: iAssistant | any) => {
          if (selectedAssistant) {
            await onChangeAssistant(selectedAssistant)
          } else {
            // Handle deselection (when "x" is clicked)
            // You might want to reset the assistant or perform other actions
            console.log('Assistant deselected')
            // For example:
            // await updateAssistant(null);
          }
        }}
        isDisabled={false}
        loadFunc={AssistantService.getAssistants}
      />

      <Tabs variant="underlined">
        <Tab key="general" title="General">
          <h1 className="text-2xl">Tools</h1>
          {isLoadingTools ? (
            <Skeleton className="rounded-large m-2 w-[340px] h-[40px]"></Skeleton>
          ) : (
            <ItemSelection
              label="Add tools"
              placeholder="Seek for tools"
              loadFunc={ToolService.getTools}
              selectedItems={tools}
              onChange={(tools) => onChangeTools(tools as iTool[])}
            />
          )}
          <h1 className="text-2xl">Knowledge Containers</h1>
          {isLoadingKnowledgeContainer ? (
            <Skeleton className="rounded-large m-2 w-[340px] h-[40px]"></Skeleton>
          ) : (
            <ItemSelection
              label="Add knowledge containers"
              placeholder="Seek for knowledge containers"
              loadFunc={KnowledgeContainerService.getKnowledgeContainers}
              selectedItems={knowledgeContainers}
              onChange={(kc) =>
                onChangeKnowledgeContainers(kc as iKnowledgeContainer[])
              }
            ></ItemSelection>
          )}

          <h1 className="text-2xl">Help Center</h1>

          {toolFAQ && <FaqItem markdown={toolFAQ?.markdown}></FaqItem>}
          {knowledgeContainerFAQ && (
            <FaqItem markdown={knowledgeContainerFAQ?.markdown}></FaqItem>
          )}
        </Tab>
        <Tab key="model-settings" title="Model Settings">
          <div className="w-full">
            <AssistantSettings
              assistant={assistant}
              updateAssistant={onChangeAssistantSettings}
            ></AssistantSettings>
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}

export default SessionGeneralView
