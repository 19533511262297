import React, { useMemo } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTheme } from 'next-themes'

type GaiaLogoProps = React.HTMLAttributes<SVGAElement> & {
  dark?: boolean
  opposite?: boolean
}

const GaiaLogo = ({ className, dark, opposite }: GaiaLogoProps) => {
  const classes = twMerge('w-full ', className, clsx({}))
  const { theme } = useTheme()
  const oppositeTheme = theme === 'dark' ? 'light' : 'dark'
  const displayLogoAsDark: boolean = useMemo(() => {
    return dark !== undefined
      ? dark
      : opposite
        ? oppositeTheme !== 'light'
        : theme === 'dark'
  }, [theme, dark, opposite])
  return (
    <svg
      fill={displayLogoAsDark ? 'white' : 'dark'}
      width="100%"
      height="100%"
      viewBox="0 0 3312 2367"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g transform="matrix(2.77778,0,0,2.77778,-2643,-380.107)">
        <g transform="matrix(2.24479,1.71602,-4.02333,4.79217,3035.13,-3821.08)">
          <path d="M500.466,653.933C500.175,652.376 501.57,650.888 504.208,649.94C506.846,648.991 510.398,648.702 513.735,649.163C523.858,650.561 536.93,652.367 544.94,653.473C549.306,654.076 552.479,655.842 552.853,657.878C555.697,673.347 567.471,737.383 571.91,761.523C572.239,763.315 574.748,764.921 578.415,765.685C582.082,766.45 586.294,766.246 589.336,765.157C606.118,759.147 632.532,749.689 649.15,743.738C652.159,742.66 653.529,740.893 652.712,739.143C651.895,737.392 649.025,735.944 645.248,735.377C634.86,733.817 622.652,731.985 615.139,730.857C610.886,730.218 607.834,728.474 607.477,726.477C606.819,722.796 605.738,716.753 604.892,712.024C604.61,710.446 606.059,708.943 608.767,708.002C611.475,707.061 615.096,706.803 618.452,707.313C642.092,710.9 687.448,717.782 704.633,720.389C708.765,721.016 711.765,722.69 712.232,724.629C713.457,729.712 715.822,739.521 716.941,744.166C717.327,745.765 715.927,747.309 713.21,748.282C693.183,755.453 569.422,799.77 546.007,808.155C542.967,809.243 538.758,809.448 535.093,808.685C531.427,807.922 528.917,806.318 528.582,804.527C522.974,774.487 505.349,680.088 500.466,653.933Z" />
        </g>
        <g transform="matrix(2.24479,1.71602,-4.02333,4.79217,3701.75,-3568.89)">
          <path d="M430.194,710.201C429.75,708.597 431.106,707.037 433.806,706.044C436.507,705.051 440.192,704.759 443.621,705.266C477.596,710.283 558.827,722.281 596.142,727.792C599.933,728.352 602.823,729.799 603.652,731.553C604.48,733.307 603.111,735.08 600.095,736.16C581.999,742.64 552.145,753.33 534.055,759.808C531.039,760.888 526.871,761.099 523.223,760.355C519.575,759.612 517.044,758.037 516.647,756.261C515.61,751.635 514.415,746.302 513.678,743.009C513.251,741.104 510.377,739.443 506.357,738.777C491.552,736.325 455.917,730.421 441.274,727.995C437.341,727.343 434.497,725.738 433.982,723.878C433.033,720.452 431.46,714.774 430.194,710.201Z" />
        </g>
        <g transform="matrix(2.24479,1.71602,-4.02333,4.79217,3702.35,-3568.27)">
          <path d="M358.528,700.336C358.192,698.76 359.587,697.245 362.261,696.284C364.934,695.324 368.544,695.04 371.915,695.527C383.557,697.205 399.23,699.465 408.186,700.757C412.44,701.37 415.532,703.09 415.957,705.079C416.727,708.682 417.982,714.553 418.969,719.175C419.305,720.748 417.917,722.26 415.254,723.221C412.592,724.182 408.993,724.47 405.626,723.991C394.002,722.338 378.339,720.112 369.369,718.836C365.094,718.229 361.981,716.504 361.554,714.507C360.781,710.885 359.521,704.983 358.528,700.336Z" />
        </g>
      </g>
    </svg>
  )
}

export default GaiaLogo
