// src/states/iconStore.ts

import { createWithEqualityFn } from 'zustand/traditional'
import AssistantService from '@/services/assistantService'
import ToolService from '@/services/toolService'
import KnowledgeContainerService from '@/services/knowledgeContainerService'
import TeamService from '@/services/teamService'
import { EntityType } from '@components/basic/icon/IconFetcher.tsx'

interface IconData {
  url: string
  expiresAt: number
}

interface IconState {
  icons: Record<string, IconData>
  getIcon: (
    entityId: string,
    entityType: EntityType,
    imageId: string,
  ) => Promise<string>
  setIcon: (key: string, url: string) => void
  createIcon: (entityId: string, entityType: EntityType) => Promise<string>
  refreshExpiredIcon: (key: string) => Promise<void>
}

const ICON_EXPIRATION_TIME = 55 * 60 * 1000 // 55 minutes in milliseconds

const useIconStore = createWithEqualityFn<IconState>((set, get) => ({
  icons: {},

  getIcon: async (entityId: string, entityType: EntityType, imageId: string) => {
    const key = `${entityType}-${entityId}-${imageId}`
    const cachedIcon = get().icons[key]
    if (cachedIcon) {
      if (Date.now() < cachedIcon.expiresAt) {
        return cachedIcon.url
      } else {
        // Icon has expired, refresh it
        await get().refreshExpiredIcon(key)
        return get().icons[key].url
      }
    }

    let iconUrl = ''

    try {
      if (imageId) {
        switch (entityType) {
          case 'Assistant':
            iconUrl = await AssistantService.getAssistantIconUrl(entityId, imageId)
            break
          case 'Tool':
            iconUrl = await ToolService.getToolIconUrl(entityId, imageId)
            break
          case 'KnowledgeContainer':
            iconUrl = await KnowledgeContainerService.getKnowledgeContainerIconUrl(
              entityId,
              imageId,
            )
            break
          case 'Team':
            iconUrl = await TeamService.getTeamIconUrl(entityId, imageId)
            break
          default:
            throw new Error(`Unsupported entity type: ${entityType}`)
        }
      } else {
        iconUrl = await get().createIcon(entityId, entityType)
      }

      get().setIcon(key, iconUrl)
      return iconUrl
    } catch (error) {
      console.error('Error fetching icon URL:', error)
      return ''
    }
  },

  setIcon: (key: string, url: string) => {
    set((state) => ({
      icons: {
        ...state.icons,
        [key]: { url, expiresAt: Date.now() + ICON_EXPIRATION_TIME },
      },
    }))
  },

  createIcon: async (entityId: string, entityType: EntityType) => {
    try {
      let iconId = ''
      switch (entityType) {
        case 'Assistant':
          iconId = await AssistantService.createAssistantIcon(entityId)
          break
        case 'Tool':
          iconId = await ToolService.createToolIcon(entityId)
          break
        case 'KnowledgeContainer':
          iconId =
            await KnowledgeContainerService.createKnowledgeContainerIcon(entityId)
          break
        case 'Team':
          iconId = await TeamService.createTeamIcon(entityId)
          break
        default:
          throw new Error(`Unsupported entity type: ${entityType}`)
      }
      return iconId
    } catch (error) {
      console.error(`Error creating ${entityType} icon:`, error)
      return ''
    }
  },

  refreshExpiredIcon: async (key: string) => {
    const [entityType, entityId, ...imageIdParts] = key.split('-')
    const imageId = imageIdParts.join('-')
    let newUrl = ''

    try {
      switch (entityType as EntityType) {
        case 'Assistant':
          newUrl = await AssistantService.getAssistantIconUrl(entityId, imageId)
          break
        case 'Tool':
          newUrl = await ToolService.getToolIconUrl(entityId, imageId)
          break
        case 'KnowledgeContainer':
          newUrl = await KnowledgeContainerService.getKnowledgeContainerIconUrl(
            entityId,
            imageId,
          )
          break
        case 'Team':
          newUrl = await TeamService.getTeamIconUrl(entityId, imageId)
          break
      }

      get().setIcon(key, newUrl)
    } catch (error) {
      console.error('Error refreshing expired icon:', error)
    }
  },
}))

export default useIconStore
