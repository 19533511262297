import React, { useEffect, useState } from 'react'
import { Key } from '@react-types/shared'
import {
  Switch,
  Select,
  SelectItem,
  SharedSelection,
  ListboxItem,
  Listbox,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
} from '@nextui-org/react'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import UserProfile from '@components/user/UserProfile.tsx'
import { useTranslation } from 'react-i18next'
import { iUser } from '@/interfaces/iUser.ts'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import useUserProfileStore from '@states/userProfileState.ts'
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { useTheme } from 'next-themes'

const UserSettings = () => {
  return (
    <DefaultPageLayout
      title="User Settings"
      editAble={false}
      onEditToggle={() => {}}
      headerContent={<UserProfile />}
      tabs={[
        { title: 'General', key: 'general' },
        { title: 'Privacy', key: 'privacy' },
      ]}
    >
      {(selectedTab) => {
        switch (selectedTab) {
          case 'general':
            return <AccountSettings />
          case 'privacy':
            return <PrivacySettings />
          default:
            return <></>
        }
      }}
    </DefaultPageLayout>
  )
}

const AccountSettings: React.FC = () => {
  const { i18n } = useTranslation()
  const [value, setValue] = React.useState<SharedSelection>(new Set([i18n.language]))
  const userProfile = useUserProfileStore().userProfile
  const setUserProfile = useUserProfileStore().setUserProfile
  // const updateAssistantVariable = useUserProfileStore().updateAssistantVariable
  // const deleteAssistantVariable = useUserProfileStore().deleteAssistantVariable
  const [user, setUser] = useState<iUser | null>(null)
  const { theme, setTheme } = useTheme()
  //
  // const [newVariableName, setNewVariableName] = useState('')
  // const [newVariableValue, setNewVariableValue] = useState('')

  const changeLanguageHandler = (lang: SharedSelection) => {
    const langValue = lang[Symbol.iterator]().next().value
    i18n
      .changeLanguage(langValue as string)
      .then((d) => console.log('finished :', d))
  }
  useEffect(() => {
    if (userProfile) {
      setUser({
        ...userProfile,
        assistantVariables: userProfile.assistantVariables || [],
      })
    }
  }, [userProfile])

  if (!user) {
    return <SkeletonDetailPage />
  }

  //
  // const handleAddVariable = () => {
  //   if (newVariableName && newVariableValue) {
  //     const updatedVariables = [
  //       ...user.assistantVariables,
  //       { name: newVariableName, value: newVariableValue },
  //     ]
  //     setUser({ ...user, assistantVariables: updatedVariables })
  //     updateAssistantVariable({ name: newVariableName, value: newVariableValue })
  //       .then(() => {
  //         setNewVariableName('')
  //         setNewVariableValue('')
  //         toast.success('Variable added successfully')
  //       })
  //       .catch((error) => {
  //         console.error('Error adding variable:', error)
  //         toast.error('Failed to add variable')
  //       })
  //   }
  // }
  //
  // const handleDeleteVariable = (variable: iAssistantVariable) => {
  //   const updatedVariables = user.assistantVariables.filter(
  //     (v) => v.name !== variable.name,
  //   )
  //   setUser({ ...user, assistantVariables: updatedVariables })
  //   deleteAssistantVariable(variable)
  //     .then(() => {
  //       toast.success('Variable deleted successfully')
  //     })
  //     .catch((error) => {
  //       console.error('Error deleting variable:', error)
  //       toast.error('Failed to delete variable')
  //     })
  // }

  return (
    <div className="space-y-4 max-w-4xl">
      <div className="flex flex-col gap-4 mt-4">
        <SectionCard
          className="min-w-96 "
          title="Personal Information"
          description="Your account details"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input readOnly label="Name" value={user.name || ''} />
            <Input readOnly label="Email" value={user.email || ''} />
            <Input readOnly label="Department" value={user.department || ''} />
            <Input readOnly label="Company" value={user.companyName || ''} />
            <Input readOnly label="Cost Center" value={user.costCenterId || ''} />
            <Input readOnly label="Country" value={user.country || ''} />
          </div>
        </SectionCard>
      </div>

      <div className="flex flex-col gap-4 mt-4">
        <SectionCard
          className="min-w-96 "
          title="App Settings"
          description="Customize your app experience"
        >
          <div className="flex flex-col gap-4">
            <Select
              selectedKeys={value}
              label="Language"
              onSelectionChange={(lang) => {
                setValue(lang)
                changeLanguageHandler(lang)
              }}
            >
              <SelectItem key="en-US" value="en">
                English
              </SelectItem>
              <SelectItem key="de" value="de">
                German
              </SelectItem>
              <SelectItem key="fr" value="fr">
                French
              </SelectItem>
            </Select>
            <div className="flex items-center gap-4">
              <Switch
                isSelected={theme === 'dark'}
                onChange={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                size="lg"
                startContent={<SunIcon className="h-5 w-5 text-yellow-500" />}
                endContent={<MoonIcon className="h-5 w-5 text-gray-600" />}
              />
              <label className="text-default-500">
                {theme === 'light' ? 'Light Mode' : 'Dark Mode'}
              </label>
            </div>
          </div>
        </SectionCard>
      </div>

      {/*<div className="flex flex-col gap-4 mt-4">*/}
      {/*  <Card className="flex-1 min-w-96 p-2">*/}
      {/*    <CardHeader className={'gap-4'}>*/}
      {/*      <h2 className="text-xl font-semibold">Assistant Variables</h2>*/}
      {/*      <p className="text-small text-default-500">*/}
      {/*        Customize variables for AI assistants*/}
      {/*      </p>*/}
      {/*    </CardHeader>*/}
      {/*    <CardBody>*/}
      {/*      <div className="flex flex-col gap-4">*/}
      {/*        {user.assistantVariables?.map((variable, index) => (*/}
      {/*          <div key={index} className="flex items-center gap-4">*/}
      {/*            <Input*/}
      {/*              key={index}*/}
      {/*              type="text"*/}
      {/*              label={variable.name}*/}
      {/*              className="w-full"*/}
      {/*              value={variable.value}*/}
      {/*              onChange={(e) => {*/}
      {/*                setNewVariableValue(e.target.value)*/}
      {/*                setNewVariableName(variable.name!)*/}
      {/*                handleAddVariable()*/}
      {/*              }}*/}
      {/*            />*/}
      {/*            <Button onPress={() => handleDeleteVariable(variable)}>*/}
      {/*              Delete*/}
      {/*            </Button>*/}
      {/*          </div>*/}
      {/*        ))}*/}
      {/*        <div className="flex items-center gap-4">*/}
      {/*          <Input*/}
      {/*            type="text"*/}
      {/*            label="Variable Name"*/}
      {/*            className="w-full"*/}
      {/*            value={newVariableName}*/}
      {/*            onChange={(e) => setNewVariableName(e.target.value)}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            type="text"*/}
      {/*            label="Variable Value"*/}
      {/*            className="w-full"*/}
      {/*            value={newVariableValue}*/}
      {/*            onChange={(e) => setNewVariableValue(e.target.value)}*/}
      {/*          />*/}
      {/*          <Button onPress={handleAddVariable}>Add</Button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </CardBody>*/}
      {/*  </Card>*/}
      {/*</div>*/}
    </div>
  )
}

import legalJSON from '../../public/legal/legal.json'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

const PrivacySettings: React.FC = () => {
  const fileName = `./legal/${
    legalJSON.termsOfUse.find(
      (tou: { lang: string; fileName: string }) => tou.lang === 'en',
    )?.fileName
  }`

  return (
    <div className="space-y-6 max-w-4xl">
      <SectionCard
        className="w-full"
        title="Privacy Settings"
        description=" Manage your privacy preferences"
      >
        <div className="flex flex-col gap-4">
          <Switch
            isDisabled={true}
            defaultSelected={false}
            disabled
            title="Receive notifications"
          >
            <p className="text-default-500">
              Receive notifications about new Assistants, Knowledge Containers,
              Tools, and more
            </p>
          </Switch>
        </div>
      </SectionCard>

      <SectionCard
        className="w-full"
        title="Legal Documents"
        description="Review our terms and policies"
      >
        <Listbox
          aria-label="Legal Documents"
          className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
          itemClasses={{
            base: 'px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80',
          }}
        >
          <ListboxItem key="terms" href={fileName} target="_blank">
            Terms of Use
          </ListboxItem>
          {/*<ListboxItem key="privacy" href="/privacy-policy">*/}
          {/*  Privacy Policy*/}
          {/*</ListboxItem>*/}
          {/*<ListboxItem key="cookies" href="/cookie-policy">*/}
          {/*  Cookie Policy*/}
          {/*</ListboxItem>*/}
        </Listbox>
      </SectionCard>
    </div>
  )
}

export default UserSettings
