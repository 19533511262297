import { twMerge } from 'tailwind-merge'
import useSessionStore from '@states/sessionStore.ts'
import React, { useState } from 'react'
import SessionsSearch from '../../../session/SessionsSearch.tsx'
import SessionFolderList from '@components/session/SessionFolderList.tsx'
import { Spacer } from '@nextui-org/react'

type AppSidenavFolderContentProps = React.HTMLAttributes<HTMLDivElement> & {}
const AppSidenavFolderContent = ({ className }: AppSidenavFolderContentProps) => {
  const classes = twMerge('flex-1 gap-4', className)

  const isLoading = useSessionStore().isLoading

  const [searchValue, setSearchValue] = useState('')

  return (
    <div className={classes}>
      <SessionsSearch
        className={'sticky top-0 bg-content1 mb-10'}
        isLoading={isLoading}
        onValueChange={(value: string) => setSearchValue(value)}
      />
      <SessionFolderList searchValue={searchValue}></SessionFolderList>
    </div>
  )
}

export default AppSidenavFolderContent
