import React, { useEffect } from 'react'
import { Button, Skeleton } from '@nextui-org/react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import SearchInput from '../basic/searchInput/SearchInput.tsx'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'

import KnowledgeContainerCard from '@components/knowledgeContainers/KnowledgeContainerCard.tsx'
import useKnowledgeContainerStore from '@states/knowledgeContainerStore.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import {
  ROUTE_MARKETPLACE_ASSISTANTS,
  ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS,
} from '@/constants/routes.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'

function KnowledgeContainersListView() {
  const navigate = useNavigate()

  const {
    loadKnowledgeContainers,
    managedKnowledgeContainers,
    isLoadingManagedKnowledgeContainers,
    setSearch,
    search,
    sharedKnowledgeContainers,
    isLoadingSharedKnowledgeContainers,
    deleteKnowledgeContainer,
  } = useKnowledgeContainerStore((state) => ({
    sharedKnowledgeContainers: state.sharedKnowledgeContainers,
    isLoadingSharedKnowledgeContainers: state.isLoadingSharedKnowledgeContainers,
    managedKnowledgeContainers: state.managedKnowledgeContainers,
    isLoadingManagedKnowledgeContainers: state.isLoadingManagedKnowledgeContainers,
    search: state.search,
    setSearch: state.setSearch,
    loadKnowledgeContainers: state.loadKnowledgeContainers,
    deleteKnowledgeContainer: state.deleteKnowledgeContainer,
  }))

  useEffect(() => {
    if (!!sharedKnowledgeContainers.total) return
    refresh()
  }, [])

  const refresh = () => {
    loadKnowledgeContainers({
      accessRight: AccessRight.Write,
      searchTerm: search.text,
    }).catch((e) => console.error(e))
    loadKnowledgeContainers({
      accessRight: AccessRight.ReadOnly,
      searchTerm: search.text,
    }).catch((e) => console.error(e))
  }

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => {
      refresh()
    },
    // delay in ms
    800,
  )

  const handleApply = async (assistant: iKnowledgeContainer) => {
    console.log('apply')
  }

  return (
    <div className="mx-auto overflow-visible rounded-xl max-w-[1500px] w-full">
      <div className="flex flex-col w-full">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-6">
          <SearchInput
            searchTerm={search.text}
            setSearchTerm={(searchTerm) => {
              setSearch({ text: searchTerm })
              debounced()
            }}
            placeholder="Search for Knowledge-Containers, Owner, Description or Instruction"
          >
            <div className="flex items-center gap-2">
              <Button
                isIconOnly
                variant="light"
                onPress={() => refresh()}
                className="mr-2"
              >
                <ArrowPathIcon className="h-6 w-6" />
              </Button>
            </div>
          </SearchInput>
        </div>

        {/* Loading Indicator */}

        <>
          {/* Own KnowledgeContainers Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Your Knowledge-Containers</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingManagedKnowledgeContainers ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : managedKnowledgeContainers.data.length > 0 ? (
                managedKnowledgeContainers.data.map((knowledgeContainer) => (
                  <KnowledgeContainerCard
                    value={knowledgeContainer}
                    handleApply={handleApply}
                    onDelete={deleteKnowledgeContainer}
                    onEditClick={() => {
                      navigate(
                        `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                        {
                          state: { isEditMode: true },
                        },
                      )
                    }}
                    onClick={() => {
                      navigate(
                        `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                      )
                    }}
                    key={knowledgeContainer.id}
                  />
                ))
              ) : (
                <p className="text-default-600">You have no Knowledge-Containers.</p>
              )}
            </div>
          </div>

          {/* Shared Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">
              Shared Knowledge-Containers
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingSharedKnowledgeContainers ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : sharedKnowledgeContainers.data.length > 0 ? (
                sharedKnowledgeContainers.data.map((knowledgeContainer) => (
                  <KnowledgeContainerCard
                    value={knowledgeContainer}
                    handleApply={handleApply}
                    onDelete={deleteKnowledgeContainer}
                    onEditClick={() => {
                      navigate(
                        `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                        {
                          state: { isEditMode: true },
                        },
                      )
                    }}
                    onClick={() => {
                      navigate(
                        `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
                      )
                    }}
                    key={knowledgeContainer.id}
                  />
                ))
              ) : (
                <p className="text-default-600">
                  No shared Knowledge-Containers available.
                </p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default KnowledgeContainersListView
