import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, AccordionItem, Skeleton, Button } from '@nextui-org/react'
import { FolderIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { Selection } from '@react-types/shared'
import SessionFolderTitle from './SessionsFolderTitle'
import CreateSessionButton from './CreateSessionButton'
import SessionList from './SessionList'
import { iFolder } from '@/interfaces/iFolder'
import { iTeam } from '@/interfaces/iTeam'
import useSessionStore from '@states/sessionStore.ts'
import useChatStore from '@states/chatStore.ts'

interface SessionFolderProps {
  folder: iFolder
  selectedTeam: iTeam
}

const ITEMS_PER_PAGE = 10 // Adjust as needed

function SessionFolderItem({ folder, selectedTeam }: SessionFolderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [totalItems, setTotalItems] = useState<number | null>(null)
  const [selectedKeys, setSelectedKeys] = useState<Iterable<string | number>>()

  const fetchSessionByFolder = useSessionStore((state) => state.fetchSessionByFolder)
  const getSessionsByFolder = useSessionStore((state) => state.getSessionsByFolder)
  const folderId = useChatStore((state) => state.session?.folderId)

  const loadSessions = useCallback(
    async (isInitialLoad: boolean = false) => {
      if (isLoading) return
      setIsLoading(true)
      try {
        const result = await fetchSessionByFolder(folder.id!, {
          limit: ITEMS_PER_PAGE,
          offset: isInitialLoad ? 0 : offset,
        })
        setTotalItems(result.total)
        setOffset((prevOffset) => prevOffset + result.data.length)
      } catch (error) {
        console.error('Error fetching sessions:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [folder.id, offset, fetchSessionByFolder],
  )

  useEffect(() => {
    if (folderId === folder.id) {
      loadSessions(true)
      setSelectedKeys([folderId] as Iterable<string>)
    }
  }, [folderId])
  useEffect(() => {
    if (isExpanded && totalItems === null) {
      loadSessions(true)
    }
  }, [isExpanded, totalItems, loadSessions])

  const handleLoadMore = () => {
    loadSessions()
  }

  const sessions = getSessionsByFolder(folder)
  const remainingItems = totalItems !== null ? totalItems - sessions.length : null

  const handleSelectionChange = (keys: Selection) => {
    setIsExpanded(keys === 'all' || keys.has(folder.id!))
    setSelectedKeys(keys)
  }

  return (
    <Accordion
      variant="bordered"
      selectionMode="multiple"
      className="px-0 w-full"
      selectedKeys={selectedKeys}
      onSelectionChange={handleSelectionChange}
    >
      <AccordionItem
        key={folder.id}
        aria-label={`Folder: ${folder.title}`}
        title={<SessionFolderTitle folder={folder} />}
        classNames={{
          heading: 'px-4',
        }}
        className={'px-0 overflow-hidden'}
      >
        <div className="px-4">
          <CreateSessionButton
            hideDescription
            folder={folder}
            selectedTeam={selectedTeam}
          />
          <div className={'mt-4 gap-3 flex flex-col'}>
            {isLoading && sessions.length === 0 ? (
              <div className="space-y-2">
                {[...Array(3)].map((_, index) => (
                  <Skeleton key={index} className="h-12 w-full rounded-md" />
                ))}
              </div>
            ) : sessions.length > 0 ? (
              <>
                <SessionList sessions={sessions} />
                {(remainingItems === null || remainingItems > 0) && (
                  <Button
                    onPress={handleLoadMore}
                    isLoading={isLoading}
                    className="w-full mt-4"
                    color="primary"
                    variant="flat"
                  >
                    {isLoading
                      ? 'Loading...'
                      : remainingItems !== null
                        ? `Load More (${remainingItems} remaining)`
                        : 'Load More'}
                    <ChevronDownIcon className="w-4 h-4 ml-2" />
                  </Button>
                )}
              </>
            ) : (
              <p className="text-center text-gray-500 dark:text-gray-400 py-4">
                No sessions in this folder. Create a new session to get started.
              </p>
            )}
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default SessionFolderItem
