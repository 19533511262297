import { createWithEqualityFn } from 'zustand/traditional'
import ToolService from '../services/toolService.ts'
import { AccessRight, Role } from '../interfaces/iShared.ts'
import { iTool } from '../interfaces/iTool.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'

interface iToolState {
  storeIsInitialized: boolean
  isLoading: boolean
  tools: iTool[]
  search: { text: string }
  isLoadingSharedTools: boolean
  isLoadingManagedTools: boolean
  sharedTools: { data: iTool[]; total: number }
  managedTools: { data: iTool[]; total: number }
}
export interface iToolStore extends iToolState, Omit<iDefaultStore, 'init'> {
  getToolRole: (toolId: string) => Role | undefined
  setIsLoading: (isLoading: boolean) => void
  deleteTool: (id: string) => Promise<void>
  createTool: (tool: iTool) => Promise<iTool>
  hasRightTo: (minRole: Role, toolId: string) => boolean
  setTools: (teams: iTool[]) => void
  loadTools: (options?: {
    accessRight: AccessRight
    searchTerm: string
  }) => Promise<iTool[]>
  setSearch: (search: { text: string }) => void
}

const initialState: iToolState = {
  isLoading: false,
  storeIsInitialized: false,
  tools: [],
  search: { text: '' },
  sharedTools: {
    data: [],
    total: 0,
  },
  managedTools: {
    data: [],
    total: 0,
  },
  isLoadingSharedTools: true,
  isLoadingManagedTools: true,
}

const useToolStore = createWithEqualityFn<iToolStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async () => {
    getState().reset()
    set({ storeIsInitialized: true })
  },
  setTools: (tools: iTool[]) => set(() => ({ tools })),
  getToolRole: (toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const shared = tool?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  setSearch: (search: { text: string }) => {
    set(() => ({ search }))
  },
  hasRightTo: (minRole: Role, toolId) => {
    const tool = getState().tools.find((p: iTool) => p.id === toolId)
    const isOwner = tool?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getToolRole(toolId)
    if (role === undefined) return false
    return role <= minRole
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  loadTools: async (options?: {
    accessRight?: AccessRight
    searchTerm?: string
  }) => {
    if (options?.accessRight === AccessRight.Write) {
      set({ isLoadingManagedTools: true })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      set({ isLoadingSharedTools: true })
    }

    const knowledgeContainers = await ToolService.getTools({
      accessRight: options?.accessRight,
      searchValue: options?.searchTerm,
    })
    if (options?.accessRight === AccessRight.Write) {
      set({ managedTools: knowledgeContainers })
      set({ isLoadingManagedTools: false })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      set({ sharedTools: knowledgeContainers })
      set({ isLoadingSharedTools: false })
    }
    return knowledgeContainers.data
  },
  deleteTool: async (id) => {
    await ToolService.deleteTool(id)
    const sharedTools = getState().sharedTools.data.filter((tool) => tool.id !== id)
    const managedTools = getState().managedTools.data.filter(
      (tool) => tool.id !== id,
    )
    set((store) => ({
      sharedTools: { ...store.sharedTools, data: sharedTools },
    }))
    set((store) => ({
      managedTools: { ...store.managedTools, data: managedTools },
    }))
  },
  createTool: async (tool: iTool) => {
    const newTool = await ToolService.createTool(tool)
    const tmpTools = [...getState().tools, newTool]
    getState().setTools(tmpTools)
    return newTool
  },
}))

export default useToolStore
