import React, { useCallback, useEffect, useState } from 'react'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Tooltip,
} from '@nextui-org/react'
import {
  EntityType,
  iRole,
  iShareLink,
  SharableEntityType,
} from '@/interfaces/iShared.ts'
import ShareLinkService from '../../services/shareLinkService.ts'
import { generateInvitationUrl } from '@/constants/invation.ts'
import CopyToClipboard from '../basic/copyToClipboard/CopyToClipboard.tsx'
import MemberRoleSelection from '../members/MemberRoleSelection.tsx'
import { ShareType } from '@/interfaces/iItem.ts'
import { ICON_CREATE, ICON_DELETE } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

type InvitationLinkSettingsProps = {
  links: iShareLink[]
  resourceType: SharableEntityType
  allowedEntityTypes?: EntityType[]
  resourceId: string
  writeOnly?: boolean
  readOnly?: boolean
  isDisabled?: boolean
  roles?: iRole[]
  onChange?: (links: iShareLink[]) => void
}

function InvitationLinkSettings({
  resourceId,
  resourceType,
  allowedEntityTypes = [EntityType.User, EntityType.Team],
  links = [],
  writeOnly,
  readOnly,
  isDisabled,
  roles,
  onChange = () => {},
}: InvitationLinkSettingsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stateLinks, setStateLinks] = useState<iShareLink[]>(links ?? [])

  const generateNewLink = () => {
    setIsLoading(true)
    ShareLinkService.generateShareLink(resourceId, resourceType, allowedEntityTypes)
      .then((data) => {
        setStateLinks([data, ...stateLinks])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    onChange(stateLinks)
  }, [stateLinks])

  const generateLink = useCallback((linkId: string) => {
    return generateInvitationUrl(resourceId, linkId, resourceType)
  }, [])
  return (
    <SectionCard
      isDisabled={isDisabled}
      description={`Share your ${SharableEntityType[resourceType].toString()} via links`}
      title={'Sharing Links'}
      appendHeader={
        !readOnly && (
          <div className="flex justify-center">
            <Button
              isLoading={isLoading}
              variant="light"
              id="createNewAssistant"
              onPress={generateNewLink}
            >
              {!isLoading && <ICON_CREATE />}
              Generate new Link
            </Button>
          </div>
        )
      }
    >
      <div className="flex flex-col mb-4 p-4 w-full min-w-fit">
        {stateLinks.map((link) => {
          return (
            <div className="flex flex-row mb-4">
              <Tooltip content={generateLink(link.linkId)} className="max-w-96">
                <Input
                  disabled
                  type="text"
                  defaultValue={generateLink(link.linkId)}
                  className="max-w-xs mr-2"
                  endContent={
                    <CopyToClipboard textToCopy={generateLink(link.linkId)} />
                  }
                />
              </Tooltip>
              <MemberRoleSelection
                value={link.role}
                isDisabled={readOnly}
                roles={roles}
                writeOnly={writeOnly}
                onChange={(value) => {
                  link.role = value
                  setStateLinks([...stateLinks])
                }}
              />
              {!readOnly && (
                <Button
                  isIconOnly
                  variant="light"
                  className="hover:text-error ml-4"
                  onPress={() =>
                    setStateLinks(stateLinks.filter((m) => m.linkId !== link.linkId))
                  }
                >
                  <ICON_DELETE />
                </Button>
              )}
            </div>
          )
        })}
        {!readOnly && (
          <Alert
            color="information"
            variant="bordered"
            title="New links"
            description="You have to save before a new link is active."
          />
        )}
      </div>
    </SectionCard>
  )
}

export default InvitationLinkSettings
