import React from 'react'
import { Input } from '@nextui-org/react'

type SearchInputProps = React.HTMLAttributes<HTMLDivElement> & {
  searchTerm: string
  placeholder: string
  setSearchTerm: (searchTerm: string) => void
}

function SearchInput(props: SearchInputProps) {
  return (
    <div className={'w-full mt-3 justify-center self-center items-center flex'}>
      <Input
        type="text"
        size="lg"
        placeholder={props.placeholder}
        className="input input-bordered w-full justify-center "
        onChange={(e) => props.setSearchTerm(e.target.value)}
        value={props.searchTerm}
      />
      <div className={'ml-2'}>{props.children}</div>
    </div>
  )
}

export default SearchInput
