// src/components/IconManager.tsx

import React, { useState, useEffect } from 'react'
import {
  Button,
  Spinner,
  CardBody,
  Card,
  CardHeader,
  Textarea,
} from '@nextui-org/react'
import { BiRefresh } from 'react-icons/bi'
import useIconStore from '@/states/iconStore'

import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import { iTool } from '@/interfaces/iTool.ts'
import IconFetcher, { EntityType } from '@components/basic/icon/IconFetcher.tsx'
import { ICON_KNOWLEDGE_CONTAINER } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

interface Props<T> {
  entity: T
  setEntity: React.Dispatch<React.SetStateAction<T>>
  entityType: EntityType
  isDisabled?: boolean
}

const IconManager = <T extends iAssistant | iKnowledgeContainer | iTool | iTeam>({
  entity,
  setEntity,
  entityType,
  isDisabled,
}: Props<T>) => {
  const [loading, setLoading] = useState(false)
  const [iconUrl, setIconUrl] = useState('')
  const { getIcon, createIcon } = useIconStore()

  useEffect(() => {
    fetchIcon()
  }, [entity.id, entity.image, entityType])

  const fetchIcon = async () => {
    if (entity.id && entity.image) {
      const url = await getIcon(entity.id, entityType, entity.image)
      setIconUrl(url)
    }
  }

  const regenerateIcon = async () => {
    setLoading(true)
    try {
      const newIconUrl = await createIcon(entity.id!, entityType)
      setEntity({ ...entity, image: newIconUrl })
      setIconUrl(newIconUrl)
    } catch (error) {
      console.error('Error:', error)
      // Optionally, show error feedback to the user
    }
    setLoading(false)
  }

  return (
    <SectionCard className=" min-w-60" title="Icon Manager" description="">
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-1">
          <IconFetcher
            entityId={entity.id!}
            entityType={entityType}
            imageId={entity.image}
          />
          <Button
            variant="light"
            isIconOnly={true}
            color="default"
            onPress={regenerateIcon}
            isDisabled={loading || isDisabled}
            className="p-2"
          >
            {loading ? <Spinner /> : <BiRefresh className="w-5 h-5" />}
          </Button>
        </div>

        <Textarea
          id="entityImage"
          type="text"
          className="input input-bordered w-full truncate"
          value={entity.image}
          readOnly={isDisabled}
          label="Url or auto generated image id"
          onChange={(e) => {
            setEntity({ ...entity, image: e.target.value })
          }}
          required
        />
      </div>
    </SectionCard>
  )
}

export default IconManager
