export enum DocumentType {
  Pdf,
  Markdown,
  WebPage,
  Video,
  Tool,
  AiContent,
  AiContentCode,
  Image,
  Audio,
  Text,
}
