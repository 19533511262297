// StackNavigator.tsx
import React, {
  useState,
  useCallback,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './stackNavigator.css'
import { BreadcrumbItem, Breadcrumbs, Button } from '@nextui-org/react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { AssistantDetailViewHandle } from '@components/assistants/AssistantDetailView.tsx'
type RouteConfig = {
  component: React.ComponentType<any>
  props?: object
}

type Routes = {
  [key: string]: RouteConfig
}

export type StackNavigatorHandle = {
  navigation: {
    push: (routeName: string, props: Record<string, any>) => void
    pop: () => void
    reset: (routeName: string, props: Record<string, any>) => void
    replace: (routeName: string, props: Record<string, any>) => void
    getCurrentRoute: () => string
  }
}
type StackNavigatorProps = {
  initialRoute: string
  routes: Routes
}

const StackNavigator: ForwardRefRenderFunction<
  StackNavigatorHandle,
  StackNavigatorProps
> = ({ initialRoute, routes }: StackNavigatorProps, ref) => {
  const [stack, setStack] = useState([{ name: initialRoute, props: {} }])
  const [direction, setDirection] = useState<'forward' | 'backward'>('forward')

  const push = useCallback(
    (routeName: string, props = {}) => {
      if (routes[routeName]) {
        setDirection('forward')
        setStack((prevStack) => [...prevStack, { name: routeName, props }])
      } else {
        console.error(`Route "${routeName}" not found`)
      }
    },
    [routes],
  )

  useImperativeHandle(ref, () => ({
    navigation: {
      pop,
      reset,
      push,
      replace,
      getCurrentRoute: () => currentRoute.name,
    },
  }))

  const pop = useCallback(() => {
    setStack((prevStack) => {
      if (prevStack.length > 1) {
        setDirection('backward')
        return prevStack.slice(0, -1)
      }
      return prevStack
    })
  }, [])

  const reset = useCallback(
    (routeName: string, props = {}) => {
      if (routes[routeName]) {
        setDirection('forward')
        setStack([{ name: routeName, props }])
      } else {
        console.error(`Route "${routeName}" not found`)
      }
    },
    [routes],
  )

  const replace = useCallback(
    (routeName: string, props = {}) => {
      if (routes[routeName]) {
        setDirection('forward')
        setStack((prevStack) => [
          ...prevStack.slice(0, -1),
          { name: routeName, props },
        ])
      } else {
        console.error(`Route "${routeName}" not found`)
      }
    },
    [routes],
  )

  const currentRoute = stack[stack.length - 1]
  const CurrentComponent = routes[currentRoute.name].component
  const defaultProps = routes[currentRoute.name].props || {}

  const isInitialRoute = stack.length === 1

  const variants = {
    enter: (direction: string) => {
      return {
        x: direction === 'forward' ? 300 : -300,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: string) => {
      return {
        zIndex: 0,
        x: direction === 'forward' ? -300 : 300,
        opacity: 0,
      }
    },
  }

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs>
        {stack.map((route, index) => (
          <BreadcrumbItem
            key={index}
            onClick={() => {
              if (index < stack.length - 1) {
                setStack(stack.slice(0, index + 1))
                setDirection('backward')
              }
            }}
          >
            {route.name}
          </BreadcrumbItem>
        ))}
      </Breadcrumbs>
    )
  }

  return (
    <div className="stack-navigator">
      <div className="navigation-header">
        {!isInitialRoute && (
          <Button isIconOnly variant="light" onPress={pop}>
            <ChevronLeftIcon className="w-5 h-5" />
          </Button>
        )}
        {renderBreadcrumbs()}
      </div>
      <div className="h-full">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentRoute.name}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <CurrentComponent
              {...defaultProps}
              {...currentRoute.props}
              navigation={{
                push,
                pop,
                reset,
                replace,
                getCurrentRoute: () => currentRoute.name,
              }}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}

export default React.forwardRef(StackNavigator)
