import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button, Spinner, useDisclosure } from '@nextui-org/react'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import './pdfViewer.css'
import {
  ArrowsPointingOutIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline'
import useMouseDragScroll from '../../../hooks/useMouseDragScroll.ts'
import ReferencesOverlay from './ReferencesOverlay'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

type PdfViewerProps = {
  url: string | undefined
  initialPage?: number
  documentChunks: iDocumentChunk[]
}

const PDFViewer: React.FC<PdfViewerProps> = ({
  url,
  initialPage = 1,
  documentChunks,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [numPages, setNumPages] = useState<number>(0)
  const [scale, setScale] = useState<number>(1)
  const [modalScale, setModalScale] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(initialPage)

  const containerRef = useRef<HTMLDivElement>(null)
  const { events, isMoving } = useMouseDragScroll(containerRef)

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }, [])

  const documentLoading = useMemo(
    () => (
      <div className="h-full flex items-center justify-center">
        <Spinner color="primary" size="lg" />
      </div>
    ),
    [],
  )

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current
      const currentPage = Math.floor((scrollTop / scrollHeight) * numPages) + 1
      setCurrentPage(currentPage)
    }
  }, [numPages])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const toolbar = useCallback(
    (isModal = false) => {
      const setScaleMethod = isModal ? setModalScale : setScale
      const scaleValue = isModal ? modalScale : scale
      return (
        <div className="flex justify-end mb-2">
          <Button
            isIconOnly
            variant="light"
            onPress={() => setScaleMethod(scaleValue + 0.25)}
          >
            <MagnifyingGlassPlusIcon className="h-5 w-5" />
          </Button>
          <Button
            isIconOnly
            variant="light"
            onPress={() => setScaleMethod(scaleValue > 1 ? scaleValue - 0.25 : 1)}
          >
            <MagnifyingGlassMinusIcon className="h-5 w-5" />
          </Button>
          {!isModal && (
            <Button isIconOnly variant="light" onPress={onOpen}>
              <ArrowsPointingOutIcon className="h-5 w-5" />
            </Button>
          )}
        </div>
      )
    },
    [scale, modalScale, onOpen],
  )

  const renderPages = useCallback(() => {
    return Array.from(new Array(numPages), (el, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        scale={scale}
        renderTextLayer={false}
        width={
          containerRef.current?.clientWidth && containerRef.current.clientWidth > 768
            ? 768
            : containerRef.current?.clientWidth
        }
      />
    ))
  }, [numPages, scale])

  return (
    <div className="flex mx-auto text-primary relative">
      <div className="flex-1 p-4">
        {toolbar()}
        <div
          ref={containerRef}
          className="relative h-[600px] overflow-auto"
          {...events}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={documentLoading}
            className={`${isMoving ? 'cursor-grabbing' : 'cursor-grab'}`}
          >
            {renderPages()}
          </Document>
        </div>
      </div>

      <div className="absolute bottom-0 right-4 w-96 z-10">
        <ReferencesOverlay
          documentChunks={documentChunks}
          setPage={(page) => {
            if (containerRef.current) {
              const scrollHeight = containerRef.current.scrollHeight
              const pageHeight = scrollHeight / numPages
              containerRef.current.scrollTop = (page - 1) * pageHeight + 50
            }
          }}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default PDFViewer
