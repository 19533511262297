import { ParsedFaq } from '@/interfaces/iFAQ.ts'
import { getMarkdownTag } from '@/utils/markdownUtils.ts'

const faqs = import.meta.glob('/cms/faq/*.md', { import: 'default', eager: true })

export const getFAQs = () => {
  const parsedFaqs: ParsedFaq[] = []
  for (const key in faqs) {
    if (Object.prototype.hasOwnProperty.call(faqs, key)) {
      const markdown: string = faqs[key] as string
      try {
        const title = getMarkdownTag('G-Title', markdown) as string
        const description = getMarkdownTag('G-Description', markdown) as string
        const categories = getMarkdownTag('G-Categories', markdown) as string[]
        const id = getMarkdownTag('G-ID', markdown) as string

        const contentWithoutMetadata = markdown
          .replace(/^\[\/\/\]: # '.*?'\n?/gm, '')
          .trim()

        const parsedFaq: ParsedFaq = {
          id,
          markdown,
          title,
          description,
          categories,
          content: contentWithoutMetadata,
        }
        parsedFaqs.push(parsedFaq)
      } catch (error) {
        console.error(`Error parsing markdown file ${key}:`, error)
      }
    }
  }
  return parsedFaqs
}

export const getFAQById = (id: string) => {
  return getFAQs().find((faq) => faq.id === id)
}
