import { PiUpload } from 'react-icons/pi'
import FilePicker from '@components/files/FilePicker.tsx'
import React, { useState } from 'react'

type DropZoneProps = {
  onDrop: (items: DataTransferItemList) => void
  handleFilesSelect: (files: FileList) => void
}
const DropZone = ({ onDrop, handleFilesSelect }: DropZoneProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
    const items = event.dataTransfer.items
    onDrop(items)
  }
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  return (
    <div
      className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors ${
        isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <PiUpload className="w-12 h-12 mx-auto text-gray-400 mb-4" />
      <p className="text-gray-600">
        Drop files or paste links here, or{' '}
        <FilePicker onFilesSelect={handleFilesSelect} forFiles></FilePicker>
      </p>
      <p className="text-sm text-gray-500 mt-2">
        Supports CSV, EPUB, GZ, HTML, JSON, KML, Microsoft Office formats, Open
        Document formats, PDF, plain text files, RTF, XML and ZIP
      </p>
    </div>
  )
}

export default DropZone
