import React, { useCallback, useMemo } from 'react'
import { iRole, Role } from '../../interfaces/iShared'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Select, SelectItem, Tooltip } from '@nextui-org/react'

type MemberRoleSelection = {
  value: Role
  writeOnly?: boolean
  roles?: iRole[]
  isDisabled?: boolean
  onChange?: (role: number) => void
}

const MemberRoleSelection = ({
  value,
  roles,
  writeOnly,
  isDisabled,
  onChange,
}: MemberRoleSelection) => {
  const handleSelectionChange = (value: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(Number(value.target.value))
  }

  const roleTooltip = useCallback((role: Role) => {
    if (role === Role.Manager)
      return `Manager has full write access to the element, but cannot delete the element`
    if (role === Role.Member) return `Member is part of the Team with usage rights`
    if (role === Role.LimitedMember)
      return `LimitedMember has a restricted interface and cannot change any settings.`
  }, [])
  const options = useMemo(() => {
    const defaultOptions = [
      { label: 'Manager', value: Role.Manager },
      { label: 'Member', value: Role.Member },
      // { label: 'LimitedMember', value: Role.LimitedMember },
    ]
    // eg as a public sharedType you have already members right
    if (writeOnly) {
      return [{ label: 'Manager', value: Role.Manager }]
    }
    if (roles) {
      return roles.map((role) => {
        return {
          label: Role[role.type],
          value: role.type,
        }
      })
    }
    return roles ?? defaultOptions
  }, [writeOnly, roles])

  return (
    <Select
      aria-label={'role'}
      items={options}
      isDisabled={isDisabled}
      selectedKeys={[String(value)]}
      onChange={(v) => handleSelectionChange(v)}
      defaultSelectedKeys={[value] as Iterable<string | number>}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.textValue} className="flex items-center gap-2">
            <div className="flex flex-col truncate">
              <span className="truncate">{item.textValue}</span>
            </div>
          </div>
        ))
      }}
    >
      {(options) => (
        <SelectItem
          key={options.value}
          value={options.value}
          textValue={options.label}
        >
          <div className={'flex flex-row items-center'}>
            <Tooltip
              content={roleTooltip(options.value)}
              placement={'left'}
              className={'max-w-96'}
            >
              <InformationCircleIcon
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.0}
                stroke="currentColor"
                className="mr-2 w-4 h-4 ml-1"
              />
            </Tooltip>
            <span>{options.label}</span>
          </div>
        </SelectItem>
      )}
    </Select>
  )
}

export default MemberRoleSelection
