import React, { useState, useEffect } from 'react'
import { Divider } from '@nextui-org/react'
import useChatStore from '@states/chatStore.ts'
import { shallow } from 'zustand/shallow'
import SidebarItem from '../basic/sidenav/SidenavItem.tsx'
import { TrashIcon } from '@heroicons/react/24/outline'
import SideNav from '../basic/sidenav/Sidenav.tsx'
import useMainStore from '../../states/mainState.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import SidenavDetailContent from '../basic/sidenav/SidenavDetailContent.tsx'
import { ICON_ARTIFACT, ICON_DELETE, ICON_REFRESH } from '@/constants/icons.tsx'
import ChatDocumentsView from '@components/session/sessionChatControlViews/documents/ChatDocumentsView.tsx'
import SessionGeneralView from '@components/session/sessionChatControlViews/SessionGeneralView.tsx'
import SessionSharingView from '@components/session/sessionChatControlViews/SessionSharingView.tsx'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
import useAssistantStore from '@states/assistantStore.ts'

const ChatControl = () => {
  const {
    session,
    assistant,
    clearChatMessages,
    isChatControlContentPinned,
    isChatControlContentOpen,
    setIsChatControlContentPinned,
    setIsChatControlContentOpen,
    isAssistantGenerating,
    chatControlContentRoute,
    setChatControlContentRoute,
    activateChatSession,
  } = useChatStore(
    (state) => ({
      clearChatMessages: state.clearChatMessages,
      isChatControlContentPinned: state.isChatControlContentPinned,
      isChatControlContentOpen: state.isChatControlContentOpen,
      setIsChatControlContentOpen: state.setIsChatControlContentOpen,
      setIsChatControlContentPinned: state.setIsChatControlContentPinned,
      isAssistantGenerating: state.isAssistantGenerating,
      session: state.session!,
      chatControlContentRoute: state.chatControlContentRoute,
      setChatControlContentRoute: state.setChatControlContentRoute,
      assistant: state.assistant,
      activateChatSession: state.activateChatSession,
    }),
    shallow,
  )
  const [docRefCount, setDocRefCount] = useState(0)

  useEffect(() => {
    const count =
      session.documentChunks?.reduce(
        (acc, chunk) => acc + chunk.references.length,
        0,
      ) || 0
    setDocRefCount(count)
  }, [session.documentChunks])

  useEffect(() => {
    if (docRefCount > 0) {
      openSessionDetailContent('artifacts')
      setIsChatControlContentPinned(true)
    }
  }, [docRefCount])

  const setPopup = useMainStore((state) => state.setPopup)

  const handleClearSession = async () => {
    setPopup(Popup.SessionFeedback, {
      onClear: async () => {
        await clearChatMessages()
      },
    })
  }

  const openSessionDetailContent = (contentType: string) => {
    setChatControlContentRoute(contentType)
    if (chatControlContentRoute?.contentName === contentType)
      setIsChatControlContentOpen(!isChatControlContentOpen)
    else setIsChatControlContentOpen(true)
  }

  return (
    <SideNav
      open={true}
      openDetailContent={isChatControlContentOpen}
      pinnedDetailContent={isChatControlContentPinned}
      right
      contentWidth={
        ['general', 'sharing'].includes(chatControlContentRoute?.contentName ?? '')
          ? '400px'
          : chatControlContentRoute?.contentName === 'canvasContent'
            ? '1000px'
            : chatControlContentRoute?.contentName === 'artifacts'
              ? '700px'
              : '500px'
      }
      onUpdatePinned={setIsChatControlContentPinned}
      onOpenDetailContent={setIsChatControlContentOpen}
      header={<></>}
      detailContent={
        <SidenavDetailContent
          right
          title={chatControlContentRoute?.contentName ?? ''}
          subTitle={'Custom subtitle'}
        >
          {chatControlContentRoute?.contentName === 'general' && (
            <SessionGeneralView></SessionGeneralView>
          )}
          {chatControlContentRoute?.contentName === 'canvas' && (
            <div>{/*<Canvas code={generatedCode} data={data} />*/}</div>
          )}
          {chatControlContentRoute?.contentName === 'artifacts' && (
            <ChatDocumentsView
              routeParams={{
                route: chatControlContentRoute.route,
                value: chatControlContentRoute.value,
              }}
            />
          )}
          {chatControlContentRoute?.contentName === 'sharing' && (
            <SessionSharingView session={session}></SessionSharingView>
          )}
          {chatControlContentRoute?.contentName === 'canvasContent' && (
            <div>{/*<CanvasContainer canvasData={canvasData} />*/}</div>
          )}
        </SidenavDetailContent>
      }
      footer={
        <>
          <Divider className={'mb-2'}></Divider>
          <SidebarItem
            tooltip="Refresh chat"
            isDisabled={isAssistantGenerating}
            onClick={() => activateChatSession(session)}
            icon={<ICON_REFRESH />}
          ></SidebarItem>
          <SidebarItem
            tooltip="Clear chat"
            isDisabled={isAssistantGenerating}
            color="danger"
            onClick={handleClearSession}
            icon={<ICON_DELETE />}
          ></SidebarItem>
        </>
      }
    >
      <SidebarItem
        tooltip="Inspect Session"
        onClick={() => openSessionDetailContent('general')}
        isActive={location.pathname === '/faq'}
        icon={
          <IconFetcher
            entityId={assistant!.id!}
            entityType="Assistant"
            imageId={assistant?.image}
          ></IconFetcher>
        }
      ></SidebarItem>
      <Divider className={'my-2'}></Divider>
      {/*<SidebarItem*/}
      {/*  onClick={() => openSessionDetailContent('sharing')}*/}
      {/*  icon={<ICON_SHARE />}*/}
      {/*></SidebarItem>*/}
      <SidebarItem
        tooltip="Inspect Documents"
        isActive={chatControlContentRoute.contentName === 'artifacts'}
        onClick={() => openSessionDetailContent('artifacts')}
        icon={<ICON_ARTIFACT />}
      ></SidebarItem>
      {/*<SidebarItem*/}
      {/*  onClick={() => openSessionDetailContent('canvasContent')}*/}
      {/*  icon={<ICON_CANVAS_VIEW />}*/}
      {/*></SidebarItem>*/}
    </SideNav>
  )
}

export default ChatControl
