import { useEffect, useState } from 'react'

import HttpOverridableEditor from './OverridableEditor.tsx'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Button, Tooltip } from '@nextui-org/react'
import {
  iOverridableParameter,
  iOverridableParameterItem,
  OverridableParameterSet,
} from '../../interfaces/iOverridableParameter.ts'
import { ICON_ADD } from '@/constants/icons.tsx'

const tooltipOverridables = `
  These parameters can be overridden by user secrets. The values are used on tool execution.
  Parameter to secret mapping is configured in the user menu under "Secrets".
  Overridable parameters are mapped to HTTP constants in two ways:
  (1) A HTTP constant is replaced as a whole, when the parameter name is equal to a constant name.
  (2) Placeholders within HTTP constants in the form of {OVERRIDABLE_PARAMETER_NAME} are replaced by the overridable parameter value.
  NOTE: When not all overridable parameters without default value are set, the tool cannot be attached to a session.
`

type OverridableParameterSetEditorProps = {
  overridableSet: OverridableParameterSet
  onChange: (overridableSet: OverridableParameterSet) => void
  isDisabled?: boolean
}

const OverridableParameterSetEditor = ({
  overridableSet,
  onChange,
  isDisabled,
}: OverridableParameterSetEditorProps) => {
  const [overridables, setOverridables] = useState<iOverridableParameterItem[]>(
    toOverridablesList(overridableSet),
  )
  const [isExpanded, setIsExpanded] = useState(false)

  function toOverridablesList(
    overridables: OverridableParameterSet,
  ): iOverridableParameterItem[] {
    return Object.keys(overridables).map((name) => ({
      name,
      overridableParameter: overridables[name],
    }))
  }

  function toOverridablesSet(
    overridables: iOverridableParameterItem[],
  ): OverridableParameterSet {
    const set: OverridableParameterSet = {}
    overridables.forEach((item) => {
      set[item.name] = item.overridableParameter
    })
    return set
  }

  const handleNameChange = (index: number, newName: string) => {
    setOverridables(
      overridables.map((item, i) => {
        if (i === index) {
          return { name: newName, overridableParameter: item.overridableParameter }
        } else {
          return item
        }
      }),
    )
  }

  const handleValueChange = (
    index: number,
    overridableParameter: iOverridableParameter,
  ) => {
    setOverridables(
      overridables.map((item, i) => {
        if (i === index) {
          return { name: item.name, overridableParameter }
        } else {
          return item
        }
      }),
    )
  }

  useEffect(() => {
    onChange(toOverridablesSet(overridables))
  }, [overridables])

  const addNewOverridable = () => {
    setOverridables([
      ...overridables,
      {
        name: '',
        overridableParameter: { defaultValue: '', hasDefaultValue: false },
      },
    ])
    setIsExpanded(true)
  }

  const removeOverridable = (index: number) =>
    setOverridables(overridables.filter((_, i) => i !== index))

  return (
    <div className="mb-4">
      <label className="label ">
        <span className="label-text flex  text-base">
          Overridable Parameters ({overridables.length})
          <Tooltip content={tooltipOverridables} className={'max-w-96'}>
            <InformationCircleIcon
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.0}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            />
          </Tooltip>
        </span>
      </label>
      <div className="ml-0">
        {overridables &&
          overridables.map((item, i) => (
            <HttpOverridableEditor
              isDisabled={isDisabled}
              index={i}
              item={item}
              onNameChange={handleNameChange}
              onValueChange={handleValueChange}
              onRemove={removeOverridable}
            />
          ))}
      </div>
      <div className="text-center">
        <Button
          variant="light"
          isIconOnly
          isDisabled={isDisabled}
          onPress={addNewOverridable}
        >
          <ICON_ADD></ICON_ADD>
        </Button>
      </div>
    </div>
  )
}
export default OverridableParameterSetEditor
