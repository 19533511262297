import React, { useMemo } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  Divider,
  Chip,
  Accordion,
  AccordionItem,
} from '@nextui-org/react'
import FaqTitle from './FaqTitle.tsx'
import { getMarkdownTag } from '@/utils/markdownUtils.ts'
import Markdown from '../markdown/Markdown.tsx'

type FaqItemProps = {
  markdown: string
}

function FaqItem({ markdown }: FaqItemProps) {
  const title = useMemo(() => {
    return getMarkdownTag('G-Title', markdown) as string
  }, [])

  const categories: string[] = useMemo(() => {
    return getMarkdownTag('G-Categories', markdown) as string[]
  }, [])

  return (
    <Card className="max-w-[800px] overflow-auto">
      <Accordion>
        <AccordionItem
          aria-label={title}
          title={<FaqTitle markdown={markdown}></FaqTitle>}
          classNames={{
            heading: 'pr-4',
          }}
        >
          <Divider />
          <CardBody>
            <Markdown value={markdown}></Markdown>
          </CardBody>
          <Divider />
          <CardFooter>
            {categories!.map((categorie) => {
              return (
                <Chip key={categorie} className={'mr-2'}>
                  {categorie}
                </Chip>
              )
            })}
          </CardFooter>
        </AccordionItem>
      </Accordion>
    </Card>
  )
}

export default FaqItem
