//pages
export const TEST_PAGE_UPS = 'test-page-ups'
export const TEST_PAGE_FAQ = 'test-page-faq'
export const TEST_PAGE_MANAGE_YOUR_ASSISTANTS = 'test-page-manage-your-assistants'
export const TEST_PAGE_DETAIL_ASSISTANT = 'test-page-detail-assistant'
export const TEST_PAGE_DETAIL_KNOWLEDGE_CONTAINER =
  'test-page-detail-knowledge-container'
export const TEST_PAGE_DETAIL_TOOL = 'test-page-detail-tool'
export const TEST_PAGE_MANAGE_YOUR_TOOLS = 'test-page-manage-your-tools'
export const TEST_PAGE_MANAGE_YOUR_KNOWLEDGE_CONTAINERS =
  'test-page-manage-your-knowledge-containers'
export const TEST_PAGE_MANAGE_YOUR_TEAMS = 'test-page-manage-your-teams'
export const TEST_PAGE_EXPLORE = 'test-page-explore'
export const TEST_PAGE_NOT_FOUND = 'test-page-not-found'
export const TEST_PAGE_FORBIDDEN = 'test-page-forbidden'

//components
export const TEST_COMPONENT_SNACKBAR_ACTION_WRAPPER =
  'test-component-snackbar-action-wrapper'
export const TEST_COMPONENT_LOADING_SCREEN = 'test-component-loading-screen'
