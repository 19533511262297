import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Outlet, useNavigate } from 'react-router-dom'
import LoadingScreen from '../loadingScreen/LoadingScreen.tsx'
import useSignalRStore from '@/states/signalRState.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import { shallow } from 'zustand/shallow'
import { iUser } from '@/interfaces/iUser.ts'
import { AnimatePresence, motion } from 'framer-motion'
import { ROUTE_UPS } from '@/constants/routes.ts'

export const RequiredAuth: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { oktaAuth, authState } = useOktaAuth()
  const navigate = useNavigate()

  const { initSignalR } = useSignalRStore(
    (state) => ({ initSignalR: state.initSignalR }),
    shallow,
  )
  const { loadUserProfile } = useUserProfileStore(
    (state) => ({ loadUserProfile: state.loadUserProfile }),
    shallow,
  )

  useEffect(() => {
    if (authState?.isAuthenticated === false) {
      // get current uri and save it for later redirect
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      // redirect to okta login page
      oktaAuth.signInWithRedirect()
    } else if (authState?.isAuthenticated) {
      loadUserProfile()
        .then((user: iUser) => initSignalR(user.id!))
        .catch((e) => navigate(ROUTE_UPS))
        .finally(() => setIsLoading(false))
    }
  }, [authState?.isAuthenticated])

  return (
    <AnimatePresence mode={'wait'}>
      {isLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <LoadingScreen isLoading={isLoading} />
        </motion.div>
      ) : (
        <motion.div
          key="outlet"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Outlet />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
