import { createWithEqualityFn } from 'zustand/traditional'
import * as signalR from '@microsoft/signalr'
import AssistantService from '../services/WebSocketService.ts'
import ChatStore from './chatStore.ts'
import { SignalRStatus } from '../enums/SignalRStatus.ts'
import {
  signalRJoinSession,
  signalRLeaveSession,
  signalRStartAssistant,
} from '@/utils/signalR.ts'

export interface iAssistantPayload {
  sessionId?: string
  connectionId?: string
  userId?: string
  token?: string
}

interface iSignalRState {
  connection: signalR.HubConnection | null
  signalRstatus: SignalRStatus
  setConnection: (connection: signalR.HubConnection | null) => void
  setSignalRStatus: (status: SignalRStatus) => void
  joinSession: (sessionId: string, userId: string) => void
  leaveSession: (sessionId: string, userId: string) => void
  startAssistant: (sessionId: string, userId: string) => void
  initSignalR: (userId: string) => Promise<void>
  isConnected: () => boolean
}

const useSignalRStore = createWithEqualityFn<iSignalRState>((set, getState) => ({
  connection: null,
  signalRstatus: SignalRStatus.Disconnected,
  setConnection: (connection: signalR.HubConnection | null) =>
    set(() => ({ connection })),
  setSignalRStatus: (signalRstatus: SignalRStatus) => set(() => ({ signalRstatus })),
  joinSession: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      signalRJoinSession(sessionId, userId, connection)
    }
  },
  leaveSession: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      signalRLeaveSession(sessionId, userId, connection)
    }
  },

  startAssistant: (sessionId: string, userId: string) => {
    const connection = getState().connection
    if (connection) {
      signalRStartAssistant(sessionId, userId, connection)
    }
  },
  initSignalR: async (userId: string) => {
    try {
      if (getState().isConnected()) {
        return
      }

      getState().setSignalRStatus(SignalRStatus.Connecting)
      const connectionInfo = await AssistantService.connectSignalR(userId)
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(connectionInfo.url, {
          accessTokenFactory: async () => {
            // TODO: figure out why these func is double called
            const connectInfo = await AssistantService.connectSignalR(userId)
            return connectInfo.accessToken
          },
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => {
            return 5000
          },
        })
        .build()
      await hubConnection.start()
      // handle error on reconnecting
      hubConnection.onreconnecting(() => {
        console.warn('Connection lost, reconnecting...')
        getState().setSignalRStatus(SignalRStatus.Reconnecting)
      })
      // handle on ws close
      hubConnection.onclose((error) => {
        console.warn('Connection lost, reconnecting... ', error)
        getState().setSignalRStatus(SignalRStatus.Disconnected)
      })
      // handle reconnecting
      hubConnection.onreconnected(() => {
        getState().setSignalRStatus(SignalRStatus.Connected)
        const activeSession = ChatStore.getState().session
        if (activeSession) {
          getState().joinSession(activeSession.id!, userId)
        }
      })
      getState().setSignalRStatus(SignalRStatus.Connected)
      getState().setConnection(hubConnection)
    } catch (error) {
      getState().setSignalRStatus(SignalRStatus.Disconnected)
      throw new Error()
    }
  },
  isConnected: () => {
    return getState().signalRstatus === SignalRStatus.Connected
  },
}))

export default useSignalRStore
