import { createWithEqualityFn } from 'zustand/traditional'
import FolderService from '@/services/folderService.ts'
import SessionState, { getLastModifiedSession } from './sessionStore.ts'
import { iFolder } from '@/interfaces/iFolder.ts'
import { iSession } from '@/interfaces/iSession.ts'
import { IFolderStore, iFolderStore } from '@/interfaces/stores/iFolderStore.ts'
import ChatStore from '@states/chatStore.ts'

const initialState: IFolderStore = {
  isLoading: false,
  storeIsInitialized: false,
  folders: [],
}
const useFolderStore = createWithEqualityFn<iFolderStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async ({ teamId }) => {
    getState().reset()
    getState()
      .getFolders({ teamId })
      .then(() => set({ storeIsInitialized: true }))
  },
  addFolder: async (folder, withoutNewSession) => {
    try {
      const newFolder = await FolderService.createFolder(folder)
      set(() => ({ folders: [...(getState().folders || []), newFolder] }))
      if (!withoutNewSession) {
        const session: iSession = {
          assistantId: '',
          folderId: newFolder.id!,
          teamId: newFolder.teamId,
          title: 'New Session',
          _isActive: false,
          automaticTitle: true,
        }
        await SessionState.getState().addSession(session)
      }
      return newFolder
    } catch (error) {
      console.error(error)
    } finally {
    }
  },
  updateFolder: async (folder: iFolder) => {
    try {
      await FolderService.updateFolder(folder)
      const folders = getState().folders
      const updatedFolders = (folders || []).map((g) =>
        g.id === folder.id ? folder : g,
      )
      set(() => ({ folders: updatedFolders }))
    } catch (error) {
      console.error(error)
    } finally {
    }
  },
  deleteFolder: async (folder: iFolder) => {
    try {
      await FolderService.deleteFolder(folder.id!)
    } catch (error) {
      console.error(error)
    } finally {
      const foldersLeft = getState().folders.filter((g) => g.id !== folder.id)
      getState().setFolders(foldersLeft)
      // check if activeSession is based on deleted folder
      const newSessions = SessionState.getState().removeSessionsByFolder(folder)
      if (newSessions.length) {
        const lastModifiedSession = getLastModifiedSession(newSessions)
        // activate last modified session
        if (lastModifiedSession) {
          await ChatStore.getState().activateChatSession(lastModifiedSession)
        }
      } else {
        // reset chat Store if no session is left
        ChatStore.getState().reset()
      }
    }
  },
  getFolders: async ({ teamId }) => {
    getState().setIsLoading(true)
    let loadedFolders: iFolder[] = []
    try {
      loadedFolders = await FolderService.getFolders({ teamId })
      set(() => ({ folders: loadedFolders }))
      return loadedFolders
    } catch (error) {
      console.error('Error loadingScreen folders: ', error)
      return []
    } finally {
      getState().setIsLoading(false)
    }
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setFolders: (folders: iFolder[]) => set(() => ({ folders })),
}))

export default useFolderStore
