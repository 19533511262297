import React, { useEffect, useState } from 'react'
import userService from '../../services/UserService.ts'
import { EntityType, iRole, iShared, Role } from '@/interfaces/iShared.ts'
import {
  Avatar,
  Button,
  Input,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import MemberRoleSelection from './MemberRoleSelection.tsx'
import useTeamStore from '@/states/teamStore.ts'
import { shallow } from 'zustand/shallow'
import { uuidv4 } from '@/utils/uuidv4.ts'
import { iUser } from '@/interfaces/iUser.ts'
import { isDraegerEmail, isEmail } from '@/utils/regexHelper.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import UserDisplay from '@components/user/UserDisplay.tsx'
import { ICON_DELETE, ICON_SEARCH } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import TeamIcon from '@components/team/TeamIcon.tsx'

const tooltipImportMembers =
  'Import members by any text-based file. The members must be separated by a comma or semicolon. The list may contain email addresses or names. The members will be added to the list of members. You can add members by email address or name. You can add Dräger members by email address as placeholder.'

type MembersSelectionProps = {
  shared: iShared[]
  onSelectionChange: (members: iShared[]) => void
  writeOnly?: boolean
  ownerId?: string
  roles?: iRole[]
  isDisabled?: boolean
}

/**
 * Component to select members for a session or a team.
 * @param shared List of already shared members
 * @param setShared Function to set the list of shared members in the parent component
 @param writeOnly If true, the access right is always write. Used for ShareType.Public
 * @param ownerId detects and filters owner out of memberslist
 */
const MembersSelection = ({
  shared,
  onSelectionChange,
  ownerId,
  writeOnly = false,
  isDisabled,
  roles,
}: MembersSelectionProps) => {
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<iUser[]>([])
  const [teams, setTeams] = useState<iTeam[]>([])
  const [allUsers, setAllUsers] = useState<iUser[]>([])
  const [showUsers, setShowUsers] = useState(false)
  const [addingNewDraegerEmail, setAddingNewDraegerEmail] = useState(false)
  const [addingNewEmail, setAddingNewEmail] = useState(false)

  const [nonExistingUsers, setNonExistingUsers] = useState<string[]>([])

  const [showInfoPlaceHolderMembers, setShowInfoPlaceHolderMembers] = useState(false)

  const filterMembers = (users: iUser[]) => {
    return users.filter((user: iUser) => user.id !== ownerId)
  }
  const filterTeams = (teams: iTeam[]) => {
    return teams.filter((team: iTeam) => team.id !== ownerId)
  }
  const { editableTeams } = useTeamStore(
    (state) => ({
      editableTeams: state.editableTeams,
    }),
    shallow,
  )

  useEffect(() => {
    if (allUsers.length === 0) {
      setIsLoading(true)
      userService
        .searchUsers()
        .then((response) => {
          setAllUsers(filterMembers(response.data))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  /**
   * Filter the users based on the search string and the already shared users.
   */
  useEffect(() => {
    if (!showUsers) {
      setUsers([])
      setTeams([])
      return
    }

    // exclude the current user and the already shared users
    let newUsers = allUsers.filter((user) => {
      return shared.find((m) => m.id === user.id) === undefined
    })
    // exclude the current user and the already shared users
    let newTeams = editableTeams.filter((user) => {
      return shared.find((m) => m.id === user.id) === undefined
    })
    if (search.length > 0) {
      // check if the search string is part of an email
      if (isDraegerEmail(search)) {
        setAddingNewDraegerEmail(true)
        return
      } else if (!isDraegerEmail(search) && isEmail(search)) {
        setAddingNewDraegerEmail(false)
        setAddingNewEmail(true)
        return
      }

      newUsers = newUsers.filter((user) => {
        return (
          user.name?.toLowerCase().includes(search.toLowerCase()) ||
          user.email?.toLowerCase().includes(search.toLowerCase())
        )
      })
      newTeams = newTeams.filter((team) => {
        return (
          team.name?.toLowerCase().includes(search.toLowerCase()) ||
          team.description?.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    newUsers = filterMembers(newUsers)
    newTeams = filterTeams(newTeams)
    setUsers(newUsers)
    setTeams(newTeams)
  }, [shared, search, showUsers])

  const addMember = (user: iUser) => {
    if (shared.find((m) => m.id === user.id)) {
      return
    }
    const newMember: iShared = {
      name: user.name!,
      id: user.id!,
      email: user.email!,
      entityType: EntityType.User,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    onSelectionChange([...shared, newMember])
  }

  const addTeam = (team: iTeam) => {
    if (shared.find((m) => m.id === team.id)) {
      return
    }
    const newTeam: iShared = {
      name: team.name!,
      id: team.id!,
      entityType: EntityType.Team,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    onSelectionChange([...shared, newTeam])
  }

  const handleSelectionChange = (value: number, s: iShared) => {
    s.role = value
    onSelectionChange([...shared])
  }

  const getRowStyle = (index: number) => ({
    transition: 'transform 0.5s ease, opacity 0.5s ease',
    transitionDelay: `${index * 0.1}s`, // staggered delay
    opacity: 1,
    transform: 'translateY(0)',
  })

  /**
   * Import members from a list of strings. The list may contain email addresses or names.
   * @param members List of members
   */
  const massImportMembers = (members: string[]) => {
    const nonExistingUsers: string[] = []
    members.forEach((member) => {
      // check if the member is already in the list
      if (
        shared.find((m) => {
          return m.email === member || m.name === member
        })
      ) {
        return
      }

      // check if the member is in the list of all users
      let user = allUsers.find((u) => {
        return (
          u.email?.toLowerCase() === member.toLowerCase() ||
          u.name?.toLowerCase() === member.toLowerCase()
        )
      })

      // if the user is not in the list of all users, and it is not a valid @draeger.com email address,
      // add it to the list of non-existing users
      // if it is a valid @draeger.com email address, create a new user object with empty id and name
      if (!user) {
        if (!member.endsWith('@draeger.com')) {
          nonExistingUsers.push(member)
          return
        } else {
          const newUser: iUser = {
            name: '',
            email: member,
            id: '',
          }
          user = newUser
          setShowInfoPlaceHolderMembers(true)
        }
      }

      addMember(user)
    })
    setNonExistingUsers(nonExistingUsers)
  }

  return (
    <div>
      <SectionCard
        title={'Available for'}
        description={'Share with Members or Teams'}
      >
        <div className={'flex'}>
          {/*<ImportMembers*/}
          {/*  handleFile={(members) => {*/}
          {/*    massImportMembers(members)*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<label className={'label'}>*/}
          {/*  <span className="label-text flex">*/}
          {/*    <Tooltip content={tooltipImportMembers} className={'max-w-96'}>*/}
          {/*      <InformationCircleIcon*/}
          {/*        fill="none"*/}
          {/*        viewBox="0 0 24 24"*/}
          {/*        strokeWidth={1.0}*/}
          {/*        stroke="currentColor"*/}
          {/*        className="w-4 h-4 ml-1"*/}
          {/*      />*/}
          {/*    </Tooltip>*/}
          {/*  </span>*/}
          {/*</label>*/}
        </div>
        <span className={'flex mt-0'}>
          <Input
            type="text"
            name="members"
            isDisabled={isDisabled || isLoading}
            placeholder="Add Member or Team"
            value={search}
            startContent={<ICON_SEARCH />}
            onClick={() => setShowUsers(true)}
            onBlur={() => {
              setTimeout(() => {
                setShowUsers(false)
              }, 200)
            }}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </span>

        <div className="max-h-40 overflow-y-auto">
          <Table
            classNames={{
              wrapper: 'px-0',
            }}
            className={'w-full'}
            shadow="none"
          >
            <TableHeader>
              <TableColumn>Name</TableColumn>
              <TableColumn>Role</TableColumn>
              <TableColumn>Actions</TableColumn>
            </TableHeader>
            <TableBody isLoading={isLoading} loadingContent={<Spinner />}>
              {shared.map((member, index) => (
                <TableRow
                  style={getRowStyle(index)}
                  className={'content-center'}
                  key={index}
                >
                  {member.entityType === EntityType.User ? (
                    <TableCell>
                      <UserDisplay
                        description={member.email!}
                        displayName={member.name}
                        email={member.email!}
                      ></UserDisplay>
                    </TableCell>
                  ) : member.entityType === EntityType.Team ? (
                    <TableCell>
                      <div key={member.id} className="flex items-center m-1">
                        <Avatar
                          alt={member.name}
                          className="flex-shrink-0"
                          size="sm"
                          icon={<UserGroupIcon className={'h-5 w-5'} />}
                        />
                        <div className="flex-wrap flex w-full justify-between">
                          <div className={'pl-2 w-full text-sm'}>
                            {member.name} (Team)
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell align="left" className={'flex justify-end '}>
                    <div className={'flex items-center min-w-52 h-full mt-1'}>
                      <MemberRoleSelection
                        isDisabled={isDisabled}
                        value={member.role}
                        roles={roles}
                        writeOnly={writeOnly}
                        onChange={(value) => handleSelectionChange(value, member)}
                      ></MemberRoleSelection>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {!isDisabled && (
                      <Button
                        isIconOnly
                        variant={'light'}
                        className="hover:text-error ml-4"
                        onPress={() =>
                          onSelectionChange(shared.filter((m) => m.id !== member.id))
                        }
                      >
                        <ICON_DELETE />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        {
          // if a draeger email is being added, show a message with button to add the user
          (addingNewDraegerEmail && (
            <div className="flex justify-between items-start mb-1 bg-content1 rounded-md ">
              <Button
                variant="light"
                className="w-full text-left mt-2 mb-2 justify-start"
                onPress={() => {
                  const newUser: iUser = {
                    name: '',
                    email: search,
                    id: uuidv4(),
                  }
                  addMember(newUser)
                  setAddingNewDraegerEmail(false)
                }}
              >
                <div className={'flex '}>
                  <div className={'mr-4 mt-1'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                      />
                    </svg>
                  </div>
                  <div className={''}>
                    <div className="pt-2 font-light ">{search}</div>
                  </div>
                </div>
              </Button>
            </div>
          )) ||
            // list of search results, possible users to add
            ((!!users?.length || !!teams?.length) && (
              <div className="max-h-40 overflow-y-auto rounded-md p-2 bg-content1 min-w-fit">
                {users.map((user) => (
                  <div
                    key={user.id}
                    className="flex justify-between items-start mb-1 bg-content1 rounded-md"
                  >
                    <UserDisplay
                      description={user.email ?? ''}
                      displayName={user.name ?? ''}
                      email={user.email!}
                      onClick={(event) => {
                        event.preventDefault()
                        addMember(user)
                      }}
                    ></UserDisplay>
                  </div>
                ))}
                {teams.map((team) => (
                  <div
                    key={team.id}
                    className="flex flex-row pb-1"
                    onClick={(event) => {
                      event.preventDefault()
                      addTeam(team)
                    }}
                  >
                    <TeamIcon team={team}></TeamIcon>

                    <div className="flex flex-col items-start truncate ml-2">
                      <span className="text-small bold truncate max-w-full">
                        {team.name}
                      </span>
                      <span className="text-tiny align-start text-default-400 max-w-full">
                        (Team)
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))
        }

        {showInfoPlaceHolderMembers && (
          <div className={'alert alert-warning mt-2'}>
            * Placeholder members have been added to the list. They will be added to
            the list of members once they log in to GAIA.
          </div>
        )}

        {nonExistingUsers.length > 0 && (
          <div className={'alert alert-warning mt-2 max-h-36 overflow-auto'}>
            The following members could not be found. They need to log in to GAIA
            first. <br /> {nonExistingUsers.join('; ')}
          </div>
        )}
      </SectionCard>
    </div>
  )
}

export default MembersSelection
