import React from 'react'
import './sidenav.scss'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import { AnimatePresence, motion } from 'framer-motion'
type SidenavDetailContentProps = {
  title?: string
  subTitle?: string
  right?: boolean
  children?: React.ReactNode
  appendHeader?: React.ReactNode
}

const SidenavDetailContent = ({
  right,
  title,
  children,
  appendHeader,
  subTitle,
}: SidenavDetailContentProps) => {
  const variants = {
    enter: () => {
      return {
        x: 300,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: () => {
      return {
        zIndex: 0,
        x: -300,
        opacity: 0,
      }
    },
  }
  return (
    <Card
      className={`rounded-none ${right ? 'rounded-l-medium' : 'rounded-r-medium'}  shadow-medium overflow-auto bg-content1 h-full`}
    >
      {title && (
        <CardHeader>
          <div className={`flex flex-col ${right ? 'pl-7' : 'pl-2'}`}>
            <p className="text-md">{title}</p>
            {subTitle && <p className="text-small text-default-500">{subTitle}</p>}
          </div>
          {appendHeader}
        </CardHeader>
      )}
      <CardBody className="pt-0">
        <div className="relative h-full">
          <AnimatePresence initial={false}>
            <motion.div
              key={title}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                height: '100%',
              }}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </div>
      </CardBody>
    </Card>
  )
}

export default SidenavDetailContent
