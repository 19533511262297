export interface iShared {
  id: string
  name: string
  email?: string
  entityType: EntityType
  role: Role
}

export enum AccessRight {
  ReadOnly,
  Read,
  Write,
  Owner,
}

export enum EntityType {
  User,
  Team,
}
export enum Role {
  // Owner // not in shared object, has its own field in the item
  Guest = 1000,
  LimitedMember = 2000,
  Member = 3000,
  Contributor = 4000,
  Moderator = 5000,
  Manager = 6000,
  Owner = 9000, // ;)
  Admin = 10000,
}

export interface iRole {
  // Owner // not in shared object, has its own field in the item
  type: Role
  permissions: iPermission[]
}

export interface iPermission {}

export interface iShareLink {
  linkId: string
  expiration: Date
  usageCount?: number
  allowedEntityTypes: EntityType[]
  role: Role
  linkType: SharableEntityType
}

export interface iShareLinkItemPreview {
  icon: string
  itemId: string
  itemType: SharableEntityType
  name: string
  shared: iShared[]
  onwerId: string
  ownerEmail: string
}

export enum SharableEntityType {
  Assistant,
  Team,
  Tool,
  Session,
  KnowledgeContainer,
}
