import React from 'react'
import FeedbackWidget from './FeedbackWidget.tsx'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useChatStore from '@/states/chatStore.ts'
type iSessionFeedbackProps = {
  onClose?: () => void
  onClear?: () => Promise<void>
}

const ChatFeedback = ({ onClose, onClear }: iSessionFeedbackProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const sessionId = useChatStore().session?.id
  const clearSession = async () => {
    setIsLoading(true)
    onClear && (await onClear())
    setIsLoading(false)
  }
  return (
    <Modal
      isDismissable={!isLoading}
      hideCloseButton={isLoading}
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <ModalHeader>Clear this session</ModalHeader>
        <ModalBody>
          <p>Did you like this session?</p>
          <p className="py-8 text-center text-3xl">
            <FeedbackWidget
              size="lg"
              ratingType="thumbs"
              commentingEnabled={false}
              entityType="session"
              entityId={sessionId!}
              onFeedbackSaved={clearSession}
              onBeforeFeedbackSaved={() => setIsLoading(true)}
            ></FeedbackWidget>
          </p>
          <span className="text-sm">
            (Session is automatically deleted as soon as a rating has been submitted)
          </span>
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onPress={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            onPress={clearSession}
          >
            Clear
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ChatFeedback
