import axiosClient from '../utils/axiosClient'
import { iFolder } from '../interfaces/iFolder.ts'

class FolderService {
  private static instance: FolderService

  private constructor() {}

  public static getInstance(): FolderService {
    if (!FolderService.instance) {
      FolderService.instance = new FolderService()
    }

    return FolderService.instance
  }

  public async createFolder(folder: iFolder): Promise<iFolder> {
    return (await axiosClient.post(`/folders`, folder)).data as iFolder
  }

  public async deleteFolder(folderId: string): Promise<void> {
    await axiosClient.delete(`/folders/${folderId}`)
  }

  public async getFolder(folderId: string): Promise<iFolder> {
    return (await axiosClient.get(`/folders/${folderId}`)).data as iFolder
  }

  public async getFolders(options?: { teamId?: string }): Promise<iFolder[]> {
    return (
      await axiosClient.get(`/folders`, {
        params: {
          teamId: options?.teamId,
        },
      })
    ).data as iFolder[]
  }

  public async updateFolder(folder: iFolder): Promise<void> {
    await axiosClient.put(`/folders/${folder.id}`, folder)
  }
}

export default FolderService.getInstance()
