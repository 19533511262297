import React, { useEffect, useRef, useState } from 'react'

import { TEST_PAGE_DETAIL_TOOL } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import ToolDetailView, {
  ToolDetailViewHandler,
} from '@components/tools/ToolDetailView.tsx'
import ToolService from '@/services/toolService.ts'
import { iTool } from '@/interfaces/iTool.ts'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import { Role } from '@/interfaces/iShared.ts'
import { ROUTE_MARKETPLACE_TOOLS } from '@/constants/routes.ts'
import { getUser, getUserEmail } from '@/utils/authentication.ts'

const ToolDetailPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tool, setTool] = useState<iTool | undefined>()
  const { id } = useParams()
  const viewRef = useRef<ToolDetailViewHandler>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    // create an empty default assistant
    if (!id || id === 'new') {
      setIsEditMode(true)
      const defaultTool: iTool = {
        name: 'Default Knowledge Container',
        ownerEmail: getUserEmail() as string,
        ownerName: getUser().displayName as string,
      }
      setTool(defaultTool)
      setIsLoading(false)
      return
    } else {
      ToolService.getTool(id)
        .then((data) => {
          setTool(data)
        })
        .finally(() => setIsLoading(false))
    }
  }, [id])

  return (
    <DefaultPageLayout
      notFound={!tool && !isLoading}
      editMode={isEditMode}
      title={tool?.name ?? 'Tool'}
      data-testid={TEST_PAGE_DETAIL_TOOL}
      editAble={tool?.userRole! >= Role.Manager}
      onSaveClick={async () => {
        viewRef.current?.saveTool().then((tool) => {
          if (tool)
            navigate(`${ROUTE_MARKETPLACE_TOOLS}/${tool.id}`, {
              replace: true,
            })
        })
      }}
    >
      {(selectedTab, isEditMode) =>
        isLoading ? (
          <SkeletonDetailPage></SkeletonDetailPage>
        ) : (
          <ToolDetailView
            value={tool!}
            isDisabled={!isEditMode}
            ref={viewRef}
          ></ToolDetailView>
        )
      }
    </DefaultPageLayout>
  )
}

export default ToolDetailPage
