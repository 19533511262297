import React, { useState, useEffect } from 'react'
import SecretService from '../../services/secretService.ts'
import {
  Button,
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Select,
  SelectItem,
  Spinner,
} from '@nextui-org/react'
import ToolService from '@/services/toolService.ts'
import { iSecret } from '@/interfaces/iSecret.ts'
import { iTool } from '@/interfaces/iTool.ts'
import {
  fromDate,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date'
import { ICON_DELETE, ICON_SAVE, ICON_EDIT, ICON_CLOSE } from '@/constants/icons.tsx'
import { toast } from 'react-toastify'

import { FAQ_ID_SECRETS } from '@/constants/faqIDs.ts'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import FaqItem from '@components/faq/FaqItem.tsx'

type SecretManagerProps = {
  onClose: () => void
}

function SecretManager({ onClose }: SecretManagerProps) {
  const [secrets, setSecrets] = useState<iSecret[]>([])
  const [tools, setTools] = useState<iTool[]>([])
  const [secretLoading, setSecretLoading] = useState(false)
  const [newSecret, setNewSecret] = useState<iSecret | null>(null)
  const [editedSecret, setEditedSecret] = useState<iSecret | null>(null)
  const [toolLoading, setToolLoading] = useState(false)
  const [mappingLoadingIds, setMappingLoadingIds] = useState<string[]>([])

  useEffect(() => {
    setSecretLoading(true)
    SecretService.getSecrets()
      .then((fetchedSecrets) => {
        setSecrets(fetchedSecrets)
        setSecretLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching secrets:', error)
        toast.error('Failed to fetch secrets')
        setSecretLoading(false)
      })
  }, [])

  useEffect(() => {
    setToolLoading(true)
    ToolService.getTools()
      .then((toolsResult) => {
        setTools(toolsResult.data.filter((tool) => tool.overridableParameters))
        setToolLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching tools:', error)
        toast.error('Failed to fetch tools')
        setToolLoading(false)
      })
  }, [])

  const handleAddSecret = () => {
    setNewSecret({
      id: '',
      name: '',
      secretValue: '',
      displayValue: '',
      secretId: '',
      expiryDate: today(getLocalTimeZone()).toDate(getLocalTimeZone()).toISOString(),
      secretGroups: [],
    })
  }

  const handleSaveNewSecret = () => {
    if (!newSecret) return
    setSecretLoading(true)
    const secretToSave = {
      ...newSecret,
      expiryDate: newSecret.expiryDate!,
    }
    SecretService.createSecret(secretToSave)
      .then((createdSecret) => {
        setSecrets([...secrets, createdSecret])
        setNewSecret(null)
        setSecretLoading(false)
        toast.success('Secret created successfully')
      })
      .catch((error) => {
        console.error('Error creating secret:', error)
        toast.error('Failed to create secret')
        setSecretLoading(false)
      })
  }

  const handleEditSecret = (secret: iSecret) => {
    setEditedSecret({ ...secret })
  }

  const handleCancelEdit = () => {
    setEditedSecret(null)
  }

  const handleSaveEditedSecret = () => {
    if (!editedSecret) return
    setSecretLoading(true)
    const secretToUpdate = {
      ...editedSecret,
      expiryDate: editedSecret.expiryDate!,
    }
    SecretService.updateSecret(secretToUpdate)
      .then(() => {
        setSecrets(
          secrets.map((secret) =>
            secret.id === secretToUpdate.id ? secretToUpdate : secret,
          ),
        )
        setEditedSecret(null)
        setSecretLoading(false)
        toast.success('Secret updated successfully')
      })
      .catch((error) => {
        console.error('Error updating secret:', error)
        toast.error('Failed to update secret')
        setSecretLoading(false)
      })
  }

  const handleDeleteSecret = (secretId: string) => {
    if (!window.confirm('Are you sure you want to delete this secret?')) return

    setSecretLoading(true)
    SecretService.deleteSecret(secretId)
      .then(() => {
        setSecrets(secrets.filter((secret) => secret.id !== secretId))
        setSecretLoading(false)
        toast.success('Secret deleted successfully')
      })
      .catch((error) => {
        console.error('Error deleting secret:', error)
        toast.error('Failed to delete secret')
        setSecretLoading(false)
      })
  }

  const handleMapSecret = (
    secretId: string,
    groupName: string,
    toolId: string,
    parameterName: string,
  ) => {
    const mappingKey = `${toolId}-${parameterName}`
    setMappingLoadingIds((prev) => [...prev, mappingKey])

    // Remove the parameter from all secrets
    const updatedSecrets = secrets.map((secret) => {
      const updatedSecretGroups = secret.secretGroups.filter(
        (group) =>
          !(
            group.groupName === groupName &&
            group.toolId === toolId &&
            group.parameterName === parameterName
          ),
      )
      return { ...secret, secretGroups: updatedSecretGroups }
    })

    // Find the selected secret and add the mapping
    if (secretId) {
      const selectedSecret = updatedSecrets.find((s) => s.id === secretId)
      if (selectedSecret) {
        selectedSecret.secretGroups.push({
          groupName,
          toolId,
          parameterName,
        })
      }
    }

    // Update secrets in the backend
    Promise.all(updatedSecrets.map((secret) => SecretService.updateSecret(secret)))
      .then(() => {
        setSecrets(updatedSecrets)
        setMappingLoadingIds((prev) => prev.filter((id) => id !== mappingKey))
        toast.success('Secret mapped successfully')
      })
      .catch((error) => {
        console.error('Error mapping secret:', error)
        toast.error('Failed to map secret')
        setMappingLoadingIds((prev) => prev.filter((id) => id !== mappingKey))
      })
  }

  const secretsFAQ = getFAQById(FAQ_ID_SECRETS)

  // Generate TableRows for secrets
  const secretRows = secrets.map((secret) => {
    const isEditing = editedSecret?.id === secret.id
    return (
      <TableRow key={secret.id}>
        {isEditing
          ? [
              <TableCell key="name">
                <Input
                  value={editedSecret.name}
                  onChange={(e) =>
                    setEditedSecret({ ...editedSecret, name: e.target.value })
                  }
                />
              </TableCell>,
              <TableCell key="value">
                <Input
                  value={editedSecret.secretValue}
                  onChange={(e) =>
                    setEditedSecret({
                      ...editedSecret,
                      secretValue: e.target.value,
                    })
                  }
                  type="password"
                />
              </TableCell>,
              <TableCell key="expiry">
                <DatePicker
                  hideTimeZone
                  value={parseDate(editedSecret.expiryDate.split('T')[0])}
                  showMonthAndYearPickers
                  onChange={(dateValue) =>
                    setEditedSecret({
                      ...editedSecret,
                      expiryDate: dateValue!
                        .toDate(getLocalTimeZone())
                        .toISOString(),
                    })
                  }
                />
              </TableCell>,
              <TableCell key="actions">
                <div className="flex space-x-2">
                  <Button
                    isIconOnly
                    variant={'light'}
                    onPress={handleSaveEditedSecret}
                  >
                    <ICON_SAVE className={'hover:text-green-500'} />
                  </Button>
                  <Button isIconOnly variant={'light'} onPress={handleCancelEdit}>
                    <ICON_CLOSE className={'hover:text-gray-500'} />
                  </Button>
                </div>
              </TableCell>,
            ]
          : [
              <TableCell key="name">{secret.name}</TableCell>,
              <TableCell key="value">
                <Input readOnly value={secret.displayValue} />
              </TableCell>,
              <TableCell key="expiry">
                {secret.expiryDate
                  ? new Date(secret.expiryDate).toLocaleDateString()
                  : ''}
              </TableCell>,
              <TableCell key="actions">
                <div className="flex space-x-2">
                  <Button
                    isIconOnly
                    variant={'light'}
                    onPress={() => handleEditSecret(secret)}
                  >
                    <ICON_EDIT className={'hover:text-secondary'} />
                  </Button>
                  <Button
                    isIconOnly
                    variant={'light'}
                    onPress={() => handleDeleteSecret(secret.id)}
                  >
                    <ICON_DELETE className={'hover:text-danger'} />
                  </Button>
                </div>
              </TableCell>,
            ]}
      </TableRow>
    )
  })

  if (newSecret) {
    secretRows.push(
      <TableRow key="new-secret">
        {[
          <TableCell key="name">
            <Input
              value={newSecret.name}
              onChange={(e) => setNewSecret({ ...newSecret, name: e.target.value })}
            />
          </TableCell>,
          <TableCell key="value">
            <Input
              value={newSecret.secretValue}
              onChange={(e) =>
                setNewSecret({
                  ...newSecret,
                  secretValue: e.target.value,
                })
              }
              type="password"
            />
          </TableCell>,
          <TableCell key="expiry">
            <DatePicker
              hideTimeZone
              value={fromDate(new Date(newSecret.expiryDate), getLocalTimeZone())}
              showMonthAndYearPickers
              onChange={(dateValue) =>
                setNewSecret({
                  ...newSecret,
                  expiryDate: dateValue!.toDate().toDateString(),
                })
              }
            />
          </TableCell>,
          <TableCell key="actions">
            <Button isIconOnly variant={'light'} onPress={handleSaveNewSecret}>
              <ICON_SAVE className={'w-5 h-5 hover:text-success'} />
            </Button>
          </TableCell>,
        ]}
      </TableRow>,
    )
  }

  // Generate TableRows for Tool Parameter Mapping
  const toolParameterRows = tools.flatMap((tool) =>
    Object.keys(tool.overridableParameters || {}).map((param) => {
      const mappingKey = `${tool.id}-${param}`
      const isLoading = mappingLoadingIds.includes(mappingKey)

      const selectedSecretGroup = secrets
        .flatMap((secret) => secret.secretGroups.map((group) => ({ secret, group })))
        .find(
          ({ group }) =>
            group.groupName === 'default' &&
            group.toolId === tool.id &&
            group.parameterName === param,
        )

      const selectedSecret = selectedSecretGroup?.secret

      return (
        <TableRow key={mappingKey}>
          <TableCell>{tool.name}</TableCell>
          <TableCell>{param}</TableCell>
          <TableCell>
            <Select
              isLoading={isLoading}
              placeholder="Select Secret"
              listboxProps={{
                emptyContent: <>{!secrets.length && 'No results found'}</>,
              }}
              selectedKeys={
                selectedSecret ? new Set([selectedSecret.id]) : new Set()
              }
              onSelectionChange={(key) => {
                const selectedKey = Array.from(key)[0] as string
                handleMapSecret(selectedKey, 'default', tool.id!, param)
              }}
            >
              {[
                <SelectItem key="" textValue="(None)">
                  (None)
                </SelectItem>,
                ...secrets.map((secret) => (
                  <SelectItem key={secret.id} textValue={secret.name}>
                    {secret.name}
                  </SelectItem>
                )),
              ]}
            </Select>
          </TableCell>
        </TableRow>
      )
    }),
  )

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: 'max-w-4xl',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center w-full justify-between">
            <h3 className="text-xl font-semibold">Secret Manager</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex items-center justify-between mb-2">
            <h4 className={'text-lg font-semibold  ml-4'}>Secrets</h4>
            <Button onPress={handleAddSecret}>Add Secret</Button>
          </div>

          <div>
            <Table>
              <TableHeader>
                <TableColumn>Name</TableColumn>
                <TableColumn>Secret Value</TableColumn>
                <TableColumn>Expiry Date</TableColumn>
                <TableColumn>Actions</TableColumn>
              </TableHeader>
              <TableBody
                isLoading={secretLoading}
                loadingContent={<Spinner label="Loading..." />}
              >
                {secretRows}
              </TableBody>
            </Table>
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-between mb-2">
              <h4 className={'text-lg font-semibold ml-4'}>
                Map Secrets to Tool Parameters
              </h4>
            </div>
            <Table>
              <TableHeader>
                <TableColumn>Tool</TableColumn>
                <TableColumn>Parameter</TableColumn>
                <TableColumn>Secret</TableColumn>
              </TableHeader>
              <TableBody
                isLoading={toolLoading}
                loadingContent={<Spinner label="Loading..." />}
              >
                {toolParameterRows}
              </TableBody>
            </Table>
          </div>

          {secretsFAQ && <FaqItem markdown={secretsFAQ?.markdown} />}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onPress={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SecretManager
