import { Card, CardBody, CardHeader } from '@nextui-org/react'
import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type SectionCardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string
  icon?: ReactNode
  prependHeader?: ReactNode
  appendHeader?: ReactNode
  description?: string
  isDisabled?: boolean
}
const SectionCard = ({
  title,
  description,
  children,
  className,
  icon,
  isDisabled,
  appendHeader,
  prependHeader,
}: SectionCardProps) => {
  const classes = twMerge('flex-1 p-2', className)
  return (
    <Card aria-label={title} className={classes} isDisabled={isDisabled}>
      <CardHeader className={'gap-4'}>
        {prependHeader}
        {icon}
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="text-small flex-1 text-default-500">{description}</p>
        {appendHeader}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  )
}
export default SectionCard
