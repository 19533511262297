import React, { useEffect, useRef, useState } from 'react'
import { Card, Button } from '@nextui-org/react'
import { CloseIcon } from '@nextui-org/shared-icons'
import { PiBookmark, PiFile, PiFilePdf, PiFileText, PiUpload } from 'react-icons/pi'
import { BiMessage } from 'react-icons/bi'
import { MdSummarize, MdTranscribe } from 'react-icons/md'
import { GiExtractionOrb } from 'react-icons/gi'

import StackNavigator, {
  StackNavigatorHandle,
} from '@components/basic/stackNavigator/StackNavigator.tsx'
import ChatDocumentsGeneralView from '@components/session/sessionChatControlViews/documents/ChatDocumentsGeneralView.tsx'
import ChatDocumentsDetailView from '@components/session/sessionChatControlViews/documents/ChatDocumentsDetailView.tsx'
import useChatStore from '@states/chatStore.ts'
import ChatMessageView from '@components/session/sessionChatControlViews/ChatMessageView.tsx'
// Operation Button Component
const OperationButton: React.FC<{
  icon: React.ReactNode
  label: string
  onClick: () => void
  disabled?: boolean
}> = ({ icon, label, onClick, disabled }) => (
  <Button
    variant="bordered"
    size="sm"
    onPress={onClick}
    isDisabled={disabled}
    className="flex items-center gap-2"
  >
    {icon}
    <span>{label}</span>
  </Button>
)

// Document Operations Component
const DocumentOperations: React.FC<{
  type: string
  onOperation: (operation: string) => void
}> = ({ type, onOperation }) => {
  const operations: {
    [key: string]: Array<{ icon: React.ReactNode; label: string; action: string }>
  } = {
    pdf: [
      {
        icon: <PiFilePdf className="w-4 h-4" />,
        label: 'Summarize',
        action: 'summarize',
      },
      {
        icon: <PiBookmark className="w-4 h-4" />,
        label: 'Extract Key Points',
        action: 'extract',
      },
    ],
    video: [
      {
        icon: <PiFileText className="w-4 h-4" />,
        label: 'Transcribe',
        action: 'transcribe',
      },
      {
        icon: <BiMessage className="w-4 h-4" />,
        label: 'Summarize',
        action: 'summarize',
      },
    ],
    audio: [
      {
        icon: <PiFile className="w-4 h-4" />,
        label: 'Transcribe',
        action: 'transcribe',
      },
    ],
    text: [
      {
        icon: <BiMessage className="w-4 h-4" />,
        label: 'Summarize',
        action: 'summarize',
      },
    ],
  }

  return (
    <div className="flex flex-wrap gap-2 mt-4">
      {operations[type]?.map((op) => (
        <OperationButton
          key={op.action}
          icon={op.icon}
          label={op.label}
          onClick={() => onOperation(op.action)}
        />
      ))}
    </div>
  )
}

// Operation Result Panel Component
const OperationResultPanel: React.FC<{
  operation: string
  onClose: () => void
  result: string | null
}> = ({ operation, onClose, result }) => (
  <Card className="mt-4 p-4">
    <div className="flex justify-between items-center mb-4">
      <h3 className="font-medium flex items-center gap-2">
        {operation === 'summarize' && <MdSummarize className="w-4 h-4" />}
        {operation === 'transcribe' && <MdTranscribe className="w-4 h-4" />}
        {operation === 'extract' && <GiExtractionOrb className="w-4 h-4" />}
        {operation.charAt(0).toUpperCase() + operation.slice(1)} Result
      </h3>
      <Button isIconOnly variant="light" size="sm" onPress={onClose}>
        <CloseIcon className="w-4 h-4" />
      </Button>
    </div>
    <div className="h-64">
      <div className="space-y-2 p-2">{result || 'Processing...'}</div>
    </div>
  </Card>
)

type ChatDocumentsViewProps = {
  routeParams?: {
    route?: 'Overview' | 'Detail' | 'MessageDetail' | string
    value?: Record<string, any>
  } | null
}
// Main Component
const ChatDocumentsView = ({ routeParams }: ChatDocumentsViewProps) => {
  const documentChunks = useChatStore((state) => state.session?.documentChunks ?? [])
  const documentItems = useChatStore((state) => state.session?.documentItems ?? [])
  const stackNavigatorRef = useRef<StackNavigatorHandle>(null)

  const routes = {
    Overview: {
      component: ChatDocumentsGeneralView,
      props: { documentChunks, documentItems },
    },
    Detail: {
      component: ChatDocumentsDetailView,
      props: {},
    },
    MessageDetail: {
      component: ChatMessageView,
      props: {
        message: routeParams?.value?.message,
        selectedTab: routeParams?.value?.selectedTab,
        selectedCitation: routeParams?.value?.selectedCitation,
      },
    },
  }

  useEffect(() => {
    if (routeParams?.route) {
      const currentRoute = stackNavigatorRef.current?.navigation.getCurrentRoute()
      if (currentRoute === routeParams.route) {
        stackNavigatorRef.current?.navigation.replace(routeParams?.route, {
          ...routeParams.value,
        })
      } else {
        stackNavigatorRef.current?.navigation.push(routeParams?.route, {
          ...routeParams.value,
        })
      }
    }
  }, [routeParams])

  return (
    <StackNavigator
      ref={stackNavigatorRef}
      initialRoute={'Overview'}
      routes={routes}
    />
  )
}

export default ChatDocumentsView
