import React, { useEffect } from 'react'

import AppSideNav from '../components/app/sidenav/AppSidenav.tsx'
import InvitationDialog from '../components/invitation/InvitationDialog.tsx'
import LegalDialog from '../components/legal/LegalDialog.tsx'
import useUserProfileStore from '../states/userProfileState.ts'
import { shallow } from 'zustand/shallow'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import useSessionStore from '@states/sessionStore.ts'
import useTeamStore from '@/states/teamStore.ts'
import useFolderStore from '@states/folderStore.ts'
import { Outlet, useNavigate } from 'react-router-dom'
import { iTeam } from '../interfaces/iTeam.ts'
import ContextPopup from '../components/dialogs/ContextPopup.tsx'
import { GAIA_LEGAL_JSON } from '@/constants/legal.ts'
import { ROUTE_UPS } from '@/constants/routes.ts'

function DefaultLayout() {
  const navigate = useNavigate()

  const { userProfile } = useUserProfileStore(
    (state) => ({
      userProfile: state.userProfile,
    }),
    shallow,
  )
  const { loadAssistantModels, setAssistantModels } = useAssistantModelStore(
    (state) => ({
      loadAssistantModels: state.loadAssistantModels,
      setAssistantModels: state.setAssistantModels,
    }),
    shallow,
  )

  const { init: initSessionStore } = useSessionStore(
    (state) => ({
      init: state.init,
    }),
    shallow,
  )

  const { getTeams, setSelectedTeam } = useTeamStore(
    (state) => ({
      getTeams: state.getTeams,
      setSelectedTeam: state.setSelectedTeam,
    }),
    shallow,
  )

  const { init: initFolderStore } = useFolderStore(
    (state) => ({
      init: state.init,
    }),
    shallow,
  )

  // on initial load, get the assistants, tools and knowledge containers
  useEffect(() => {
    // load models like o1, gpt4. Can be loaded independent from other starting calls
    loadAssistantModels(['chat', 'text']).then(async (models) => {
      setAssistantModels(models)
    })
    let team: iTeam
    getTeams()
      .then((teams) => {
        // every user has an default team, if not something went wrong
        if (!teams.length) navigate(ROUTE_UPS)
        const usersLastTeam = teams.find(
          (team) => team.id === userProfile?.lastTeamId,
        )
        team = usersLastTeam ?? teams[0]
        return setSelectedTeam(team)
      })
      .then(() => {
        if (!team) navigate(ROUTE_UPS)
        return initFolderStore({ teamId: team.id! })
      })
      .then(() => {
        initSessionStore({ teamId: team.id! })
      })
  }, [])

  return (
    <>
      <ContextPopup />
      <LegalDialog termsOfUse={GAIA_LEGAL_JSON} resourceId={'gaia'}></LegalDialog>
      <InvitationDialog></InvitationDialog>
      <div className={'flex flex-row h-screen'}>
        {/* Drawer left side */}
        <AppSideNav></AppSideNav>
        <div className={`flex flex-1 transition-all  overflow-auto`}>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
