import React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Skeleton,
} from '@nextui-org/react'
import {
  ArrowRightEndOnRectangleIcon,
  KeyIcon,
  MoonIcon,
  ShieldExclamationIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { useOktaAuth } from '@okta/okta-react'
import useMainStore from '@/states/mainState.ts'
import { useTheme } from 'next-themes'
import { useTranslation } from 'react-i18next'
import { getUser } from '@/utils/authentication.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import ProfileImage from '../basic/profileImage/ProfileImage.tsx'
import { useNavigate } from 'react-router-dom'
import useUserProfileStore from '@/states/userProfileState.ts'
import UserDisplay from '@components/user/UserDisplay.tsx'
import { ROUTE_USER_SETTINGS } from '@/constants/routes.ts'

const AccountMenu = () => {
  const setPopup = useMainStore((state) => state.setPopup)
  const { theme, setTheme } = useTheme()
  const { oktaAuth } = useOktaAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userProfile = useUserProfileStore().userProfile
  const logout = async () => {
    await oktaAuth.signOut()
  }

  const { displayName, email } = getUser()
  return (
    <>
      <Skeleton isLoaded={!!userProfile} className="min-h-10">
        <Dropdown>
          <DropdownTrigger className={'h-18'}>
            <button>
              <ProfileImage contact={email}></ProfileImage>
            </button>
          </DropdownTrigger>
          <DropdownMenu aria-label={t('user:accountMenuComp.ariaLabel')}>
            <DropdownSection showDivider>
              <DropdownItem onPress={() => navigate(ROUTE_USER_SETTINGS)} key={''}>
                <UserDisplay
                  description={t('user:authRoles.' + userProfile!.role!)}
                  displayName={displayName}
                  email={email}
                ></UserDisplay>
              </DropdownItem>
            </DropdownSection>
            <DropdownSection showDivider>
              <DropdownItem
                textValue={'Secrets'}
                startContent={<KeyIcon className={'w-5 h-5'} />}
                onPress={() => setPopup(Popup.Secrets)}
                key={'secrets'}
              >
                <a>{t('user:secret_one')}</a>
              </DropdownItem>
              <DropdownItem
                textValue={'Personal access tokens'}
                startContent={<ShieldExclamationIcon className={'w-5 h-5'} />}
                onPress={() => setPopup(Popup.PatKeys)}
                key={'personal-access-tokens'}
              >
                <a>{t('user:personalAccessToken')}</a>
              </DropdownItem>
            </DropdownSection>
            <DropdownSection showDivider>
              <DropdownItem
                textValue={'ThemeToggle'}
                startContent={
                  theme === 'dark' ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )
                }
                onPress={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                key={'themeToggle'}
              >
                <a>{theme === 'light' ? 'Dark' : 'Light'}-Mode</a>
              </DropdownItem>
            </DropdownSection>
            <DropdownSection>
              <DropdownItem
                textValue={'Logout'}
                startContent={<ArrowRightEndOnRectangleIcon className={'w-5 h-5'} />}
                onPress={logout}
                key={'logout'}
              >
                <a>{t('general:logout')}</a>
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </Skeleton>
    </>
  )
}

export default AccountMenu
