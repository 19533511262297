export enum Popup {
  None,
  Files,
  Wiki,
  ToolSelector,
  Tools,
  Workitems,
  KnowledgeContainer,
  KnowledgeContainerSelector,
  UserManagement,
  Roadmap,
  UserSettings,
  AssistantNew,
  AssistantContainer,
  AssistantEdit,
  AssistantInspect,
  AssistantOnlyInspect,
  PatKeys,
  Changelog,
  Okta,
  SessionFeedback,
  Secrets,
  Spotlight,
}
