import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import useUserProfileStore from '../../states/userProfileState.ts'
import { shallow } from 'zustand/shallow'
import useChatStore from '@/states/chatStore.ts'
import useSessionStore from '@states/sessionStore.ts'
import { iTermsOfUse, iTermsOfUseContent } from '@/interfaces/iTermsOfUse.ts'
import { getUserCountry } from '@/utils/authentication.ts'
import { iDocument } from '@/interfaces/iFile.ts'
import Markdown from '@components/markdown/Markdown.tsx'
// import documentService from '@/services/documentService.ts'

type LegalDialogProps = React.HTMLAttributes<HTMLDialogElement> & {
  termsOfUse?: iTermsOfUse
  resourceId: string | 'gaia'
}
const LegalDialog = ({ termsOfUse, resourceId }: LegalDialogProps) => {
  const [selectedLanguage] = useState<string>(getUserCountry() || 'en')
  const [termsOfUseContent, setTermsOfUseContent] =
    useState<iTermsOfUseContent | null>(null)
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { acceptedToUVersion, userProfile } = useUserProfileStore(
    (state) => ({
      acceptedToUVersion: state.acceptedToUVersion,
      userProfile: state.userProfile,
    }),
    shallow,
  )

  const { updateSession, getSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      getSession: state.getSession,
    }),
    shallow,
  )

  const { activeSession, activateSession } = useChatStore(
    (state) => ({
      activeSession: state.session,
      activateSession: state.activateChatSession,
    }),
    shallow,
  )

  const hasOpenToU = useMemo(() => {
    if (!termsOfUse || !userProfile) return false
    const acceptedDate =
      userProfile.acceptedToUVersion && userProfile.acceptedToUVersion[resourceId]
    if (acceptedDate) {
      return termsOfUse.version > acceptedDate
    } else {
      return true
    }
  }, [userProfile, termsOfUse, resourceId])

  const findContent = (
    termsOfUse: iTermsOfUse,
    lang: string,
  ): iTermsOfUseContent => {
    let content =
      termsOfUse.content.find(
        (content: iTermsOfUseContent) => content.language === lang,
      ) || null
    if (!content) {
      content =
        termsOfUse.content.find(
          (content: iTermsOfUseContent) => content.isFallback,
        ) || null
    }
    if (!content) {
      content = termsOfUse.content[0]
    }
    return { ...content }
  }

  const getDocumentHref = (document: iDocument): string => {
    if (!document) return ''
    if (document.id?.startsWith('gaia-')) {
      return document.source || ''
    }
    // return documentService.getDocumentUrl(document.id)
    return ''
  }

  const getDisplayName = (document: iDocument): string => {
    if (!document) return ''
    return (
      document.metadata?.attributes?.displayName ||
      document.metadata?.attributes?.fileName ||
      'Docuemnt'
    )
  }

  useEffect(() => {
    if (!termsOfUse) return
    setTermsOfUseContent({ ...findContent(termsOfUse, selectedLanguage) })
    if (hasOpenToU) {
      onOpen()
    }
  }, [hasOpenToU, termsOfUse, selectedLanguage])

  const handleAcceptLegalDialog = () => {
    setIsLoading(true)
    acceptedToUVersion(resourceId)
      .then(() => {
        if (resourceId !== 'gaia') {
          const session = activeSession!
          session._assistant!.baseAssistantId = resourceId
          return updateSession(session)
        }
      })
      .then(() => onClose())
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={isOpen}
        hideCloseButton={resourceId === 'gaia'}
        isDismissable={false}
        classNames={{
          base: '!max-w-[500px] w-fit',
        }}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            getSession(activeSession!.id!)
              .then((session) => {
                return activateSession(session!)
              })
              .then(() => onClose())
          } else {
            onOpenChange()
          }
        }}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="font-bold text-lg">Terms of Service</h3>
          </ModalHeader>
          <ModalBody>
            <Markdown value={termsOfUseContent?.text ?? ''}></Markdown>
            {termsOfUseContent?.documents.map((document) => (
              <Link
                key={document.id}
                underline={'always'}
                isExternal
                href={getDocumentHref(document)}
                showAnchorIcon
              >
                {getDisplayName(document)}
              </Link>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} onPress={() => handleAcceptLegalDialog()}>
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LegalDialog
