import React, { useEffect } from 'react'
import AssistantCard from './AssistantCard.tsx'
import { Button } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import useChatStore from '@states/chatStore.ts'
import SearchInput from '../basic/searchInput/SearchInput.tsx'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'
import useAssistantStore from '@states/assistantStore.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import { ROUTE_MARKETPLACE_ASSISTANTS } from '@/constants/routes.ts'

function AssistantsListView() {
  const navigate = useNavigate()
  const { activeSession, changeAssistant } = useChatStore(
    (state) => ({
      changeAssistant: state.changeAssistant,
      activeSession: state.session,
    }),
    shallow,
  )
  const {
    deleteAssistant,
    loadAssistants,
    managedAssistants,
    isLoadingManagedAssistants,
    setSearch,
    search,
    sharedAssistants,
    isLoadingSharedAssistants,
  } = useAssistantStore((state) => ({
    sharedAssistants: state.sharedAssistants,
    isLoadingSharedAssistants: state.isLoadingSharedAssistants,
    managedAssistants: state.managedAssistants,
    isLoadingManagedAssistants: state.isLoadingManagedAssistants,
    loadAssistants: state.loadAssistants,
    search: state.search,
    setSearch: state.setSearch,
    deleteAssistant: state.deleteAssistant,
  }))

  useEffect(() => {
    if (!!sharedAssistants.total) return
    refresh()
  }, [])

  const refresh = () => {
    loadAssistants({
      accessRight: AccessRight.Write,
      searchTerm: search.text,
    }).catch((e) => console.error(e))
    loadAssistants({
      accessRight: AccessRight.ReadOnly,
      searchTerm: search.text,
    }).catch((e) => console.error(e))
  }

  // Debounce callback
  const debounced = useDebouncedCallback(
    // function
    () => {
      refresh()
    },
    // delay in ms
    800,
  )

  const handleApply = async (assistant: iAssistant) => {
    changeAssistant(assistant)
  }

  return (
    <div className="mx-auto overflow-visible rounded-xl max-w-[1500px] w-full">
      <div className="flex flex-col w-full">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-6">
          <SearchInput
            searchTerm={search.text}
            setSearchTerm={(searchTerm) => {
              setSearch({ text: searchTerm })
              debounced()
            }}
            placeholder="Search for Assistant, Owner, Description or Instruction"
          >
            <div className="flex items-center gap-2">
              <Button isIconOnly variant="light" onPress={refresh} className="mr-2">
                <ArrowPathIcon className="h-6 w-6" />
              </Button>
            </div>
          </SearchInput>
        </div>

        {/* Loading Indicator */}

        <>
          {/* Own Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Managed Assistants</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingManagedAssistants ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : managedAssistants.data.length > 0 ? (
                managedAssistants.data.map((assistant) => (
                  <AssistantCard
                    value={assistant}
                    handleApply={handleApply}
                    onDelete={deleteAssistant}
                    onEditClick={() => {
                      navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`, {
                        state: { isEditMode: true },
                      })
                    }}
                    onClick={() => {
                      navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`)
                    }}
                    key={assistant.id}
                  />
                ))
              ) : (
                <p className="text-default-600">You have no assistants.</p>
              )}
            </div>
          </div>

          {/* Shared Assistants Section */}
          <div className="mb-10">
            <h2 className="text-xl font-semibold mb-4">Shared Assistants</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
              {isLoadingSharedAssistants ? (
                <>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                  <SkeletonCard></SkeletonCard>
                </>
              ) : sharedAssistants.data.length > 0 ? (
                sharedAssistants.data.map((assistant) => (
                  <AssistantCard
                    value={assistant}
                    handleApply={handleApply}
                    onClick={() => {
                      navigate(`${ROUTE_MARKETPLACE_ASSISTANTS}/${assistant.id}`)
                    }}
                    key={assistant.id}
                  />
                ))
              ) : (
                <p className="text-default-600">No shared assistants available.</p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default AssistantsListView
