import React, { useMemo, useState } from 'react'
import './sidenav.scss'
import { Button } from '@nextui-org/react'
import { LuPin, LuPinOff } from 'react-icons/lu'
import { twMerge } from 'tailwind-merge'

type SidenavProps = {
  open?: boolean
  absolute?: boolean
  openDetailContent?: boolean
  pinnedDetailContent?: boolean
  right?: boolean
  onUpdateOpen?: (open: boolean) => void
  onUpdatePinned?: (pinned: boolean) => void
  onOpenDetailContent?: (open: boolean) => void
  children?: React.ReactNode
  detailContent?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  sidenavWidth?: number | string
  contentWidth?: number | string
  className?: string
}

const Sidenav = ({
  open = false,
  pinnedDetailContent = false,
  openDetailContent = false,
  right = false,
  header,
  children,
  footer,
  detailContent,
  onUpdateOpen,
  onUpdatePinned,
  onOpenDetailContent,
  sidenavWidth = '60px',
  contentWidth = 'fit-content',
  className,
}: SidenavProps) => {
  const isDetailContentOpen = useMemo(() => {
    return pinnedDetailContent ? pinnedDetailContent : openDetailContent
  }, [openDetailContent, pinnedDetailContent])

  const classes = twMerge(
    `flex sidenav-wrapper z-20 ${open ? 'open' : ''}  ${right ? 'right flex-row-reverse' : ''}`,
    className,
  )

  return (
    <div className={classes}>
      {!pinnedDetailContent && (
        <div
          onClick={() => onOpenDetailContent && onOpenDetailContent(false)}
          className={`overlay ${isDetailContentOpen && 'open'}`}
        ></div>
      )}
      {/*Sidenav*/}
      <div
        style={{ width: sidenavWidth }}
        className={`shadow-medium relative bg-content1  ${isDetailContentOpen ? '' : right ? 'rounded-l-medium' : 'rounded-r-medium'} p-2 sidenav z-10`}
      >
        {/*Header*/}
        {header && <div className="header">{header}</div>}
        {/*Children*/}
        <div className="content overflow-auto">{children}</div>
        {/*Footer*/}
        {footer && <div className="footer flex flex-col items-center">{footer}</div>}
      </div>
      {/*Content*/}
      <div
        style={{
          left: pinnedDetailContent
            ? 0
            : isDetailContentOpen
              ? right
                ? 0
                : sidenavWidth
              : right
                ? sidenavWidth
                : `-${sidenavWidth}`,
          width: contentWidth,
        }}
        className={`${pinnedDetailContent && 'pinned'} absolute detail-content ${isDetailContentOpen ? 'open' : ''}`}
      >
        {detailContent}
        <div className={`absolute left-0 top-2 right-0 h-8`}>
          <Button
            className={`z-10 absolute ${open ? (right ? 'left-2' : 'right-2') : right ? '-left-11' : '-right-11'}`}
            isIconOnly
            size="sm"
            variant="light"
            onPress={() => {
              onUpdatePinned && onUpdatePinned(!pinnedDetailContent)
            }}
            startContent={
              !pinnedDetailContent ? (
                <LuPin className="w-4 h-4" />
              ) : (
                <LuPinOff className="w-4 h-4" />
              )
            }
          ></Button>
        </div>
      </div>
    </div>
  )
}

export default Sidenav
