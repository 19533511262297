import { iRole, iShared, iShareLink, Role } from './iShared'

export interface iItem {
  id?: string
  created?: Date
  modified?: Date
  shared?: iShared[]
  sharedType?: ShareType
  shareLinks?: iShareLink[]
  roles?: iRole[]
  ownerId?: string
  ownerEmail?: string
  ownerName?: string
  userRole?: Role

  _isLoading?: boolean
  _isDeleting?: boolean
  _isDisabled?: boolean
  _tmpId?: string
}

export enum ShareType {
  None,
  Public,
  Limited,
}
