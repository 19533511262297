import { iSession } from '@/interfaces/iSession.ts'
import useChatStore from '@states/chatStore.ts'
import React, { useEffect, useState } from 'react'
import { iShared } from '@/interfaces/iShared.ts'
import MembersSelection from '@components/members/MemberSelection.tsx'
import { ShareType } from '@/interfaces/iItem.ts'

type SharedSharingViewProps = {
  session: iSession
}
const SessionSharingView = ({ session }: SharedSharingViewProps) => {
  const [members, setMembers] = useState<iShared[]>(session.shared ?? [])
  const updateActiveSession = useChatStore().updateSession

  useEffect(() => {
    session.shared = members
    updateActiveSession(session)
  }, [members])

  return (
    <MembersSelection
      shared={members}
      onSelectionChange={setMembers}
      ownerId={session.ownerId}
      roles={session.roles}
      writeOnly={session?.sharedType === ShareType.Public}
    />
  )
}

export default SessionSharingView
