import React, { useEffect } from 'react'
import './sidenav.scss'
import { Badge, Button, Skeleton, Tooltip } from '@nextui-org/react'
type SidenavItemProps = {
  to?: string
  href?: string
  isActive?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  badgeContent?: string | number
  icon?: React.ReactNode
  tooltip?: string
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | undefined
  children?: React.ReactNode
  onClick?: () => void
}

const SidenavItem = ({
  isActive = false,
  isDisabled = false,
  color = 'primary',
  children,
  icon,
  tooltip,
  onClick,
  badgeContent,
  isLoading,
}: SidenavItemProps) => {
  const handleClick = () => {
    onClick && onClick()
  }
  return children ? (
    <Skeleton
      isLoaded={!isLoading}
      classNames={{
        content: 'flex align-center justify-center items-center',
      }}
      className="min-h-[42px] flex align-center justify-center my-2 rounded-large w-full"
    >
      {children}
    </Skeleton>
  ) : (
    <>
      <Badge
        shape="circle"
        placement="top-right"
        color="secondary"
        isInvisible={!badgeContent}
        content={badgeContent}
        classNames={{
          badge: 'right-3 top-4',
        }}
      >
        <Tooltip
          isDisabled={!tooltip}
          content={tooltip}
          placement={'right'}
          className="max-w-96"
        >
          <Skeleton
            isLoaded={!isLoading}
            className="min-h-5 my-2 rounded-large w-full"
          >
            <Button
              isDisabled={isDisabled}
              color={color}
              isIconOnly
              variant={isActive ? 'solid' : 'light'}
              size={'lg'}
              className={`${isActive && 'active'} sidenav-item`}
              onPress={handleClick}
            >
              {icon}
            </Button>
          </Skeleton>
        </Tooltip>
      </Badge>
    </>
  )
}

export default SidenavItem
