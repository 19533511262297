import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'

export const itemAllowedInTeam = (
  item: iAssistant | iKnowledgeContainer | iTool,
  teamId: string,
) => {
  return !!item.teamReferences?.find((teamRefId) => teamRefId === teamId)
}
