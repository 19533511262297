import React, { useCallback, useEffect, useRef } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import ChatMessage from './ChatMessage.tsx'
import useUserProfileStore from '@/states/userProfileState.ts'
import './chatWindow.css'
import useChatStore from '@states/chatStore.ts'
import { Skeleton } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'

type ChatMessageWrapperProps = {
  isLoading?: boolean
}

const ChatMessageWrapper = ({ isLoading }: ChatMessageWrapperProps) => {
  const userProfile = useUserProfileStore().userProfile
  const {
    transferLength,
    messages,
    setMessageInView,
    messageToScrollToId,
    goToMessageById,
  } = useChatStore(
    (state) => ({
      transferLength: state.assistant?.settings?.transferLength,
      messages: state.messages,
      setMessageInView: state.setMessageInView,
      messageToScrollToId: state.messageToScrollToId,
      goToMessageById: state.goToMessageById,
    }),
    shallow,
  )

  const willMessageTransfered = useCallback(
    (index: number, single: boolean = false) => {
      if (transferLength === 21) return !single
      const length = transferLength!
      return single ? index === length - 1 : index >= length
    },
    [transferLength],
  )

  const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const lastMessageInViewRef = useRef<string | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersectingMessages: string[] = []

        entries.forEach((entry) => {
          const messageId = entry.target.getAttribute('data-message-id')
          if (entry.isIntersecting && messageId) {
            intersectingMessages.push(messageId)
          }
        })

        // Filter assistant messages in the active viewport area
        const assistantMessagesInView = intersectingMessages
          .map((id) => messages.find((msg) => msg.id === id))
          .filter((message) => message?.role === 'assistant')

        if (assistantMessagesInView.length > 0) {
          // Pick the last assistant message in view
          const latestMessage =
            assistantMessagesInView[assistantMessagesInView.length - 1]
          if (latestMessage) {
            setMessageInView(latestMessage.id!)
            lastMessageInViewRef.current = latestMessage.id!
          }
        } else {
          // Keep the last known messageInView
          if (lastMessageInViewRef.current) {
            setMessageInView(lastMessageInViewRef.current)
          }
        }
      },
      {
        rootMargin: '-50% 0% -25% 0%',
        threshold: 0,
      },
    )

    // Observe each message
    Object.values(messageRefs.current).forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      observer.disconnect()
    }
  }, [messages, setMessageInView])

  useEffect(() => {
    if (messageToScrollToId) {
      const messageElement = messageRefs.current[messageToScrollToId]
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      // Reset messageToScrollToId if needed
      goToMessageById(null)
    }
  }, [messageToScrollToId, goToMessageById])

  return (
    <div className="flex-1 flex flex-col justify-start">
      {isLoading ? (
        <>
          <Skeleton className="h-5 w-full rounded" />
          <Skeleton className="h-5 w-full" />
        </>
      ) : (
        messages
          .slice()
          .reverse()
          .map((message, index) => {
            const messageKey = message._tmpId ?? message.id
            const setRef = (el: HTMLDivElement | null) => {
              messageRefs.current[message.id!] = el
            }
            return (
              <div key={messageKey} ref={setRef} data-message-id={message.id}>
                {willMessageTransfered(index, true) && (
                  <div className="divider pt-6 transition">
                    <div className="flex flex-col items-center justify-center text-gray-400">
                      <div className="flex-1">Transferred</div>
                      <ChevronDownIcon className="flex-1 w-5 h-5" />
                    </div>
                  </div>
                )}
                <ChatMessage
                  transfered={willMessageTransfered(index)}
                  message={message}
                  isLastMessage={index === messages.length - 1}
                  user={userProfile!}
                />
              </div>
            )
          })
      )}
    </div>
  )
}

export default React.memo(ChatMessageWrapper)
