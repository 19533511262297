import { useState } from 'react'
import { HttpToolCall } from './httpToolCall.tsx'
import { iToolCall } from '../../interfaces/iMessage.ts'
import { JSONDisplay } from '../basic/jsonViewer/JSONDisplay.tsx'

type ToolCallProps = {
  initialToolCall: iToolCall
}

export const ToolCall = ({ initialToolCall }: ToolCallProps) => {
  const [toolCall, setToolCall] = useState(initialToolCall)

  return (
    <div className="ml-4">
      <h3 className="mt-2 text-xl text-primary">
        {toolCall.toolCallDisplayName || toolCall.toolCallName}
      </h3>
      <details open={true} className="ml-4 mt-1 text-primary">
        <summary>Parameters</summary>
        <JSONDisplay jsonString={toolCall.toolCallArguments} />
      </details>
      {toolCall.toolCallContent && (
        <details className="ml-4 mt-1 text-primary">
          <summary>Content</summary>
          <JSONDisplay jsonString={toolCall.toolCallContent} />
        </details>
      )}
      {toolCall.toolCallHttpContext && (
        <HttpToolCall httpContext={toolCall.toolCallHttpContext} />
      )}
    </div>
  )
}
