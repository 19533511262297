import React from 'react'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
type AssistantAvatarProps = {
  assistantId: string
  image: string
  displayName: string
  description: string
}
const AssistantAvatar = ({
  assistantId,
  image,
  displayName,
  description,
}: AssistantAvatarProps) => {
  return (
    <div className="flex flex-row gap-1">
      <IconFetcher entityId={assistantId} entityType={'Assistant'} imageId={image} />
      <div className="flex flex-col items-start truncate ml-2">
        <span className="text-small bold truncate max-w-full">{displayName}</span>
        <span className="text-tiny align-start text-default-400 max-w-full">
          by {description}
        </span>
      </div>
    </div>
  )
}
export default AssistantAvatar
