import axiosClient from '../utils/axiosClient.ts'
import { iTeam } from '../interfaces/iTeam.ts'

class TeamService {
  private static instance: TeamService

  private constructor() {}

  public static getInstance(): TeamService {
    if (!TeamService.instance) {
      TeamService.instance = new TeamService()
    }
    return TeamService.instance
  }

  public async getTeams(all: boolean, includeSpent = false): Promise<iTeam[]> {
    // await new Promise((resolve) => setTimeout(resolve, 2000, false)) // just for testing
    return (await axiosClient.get(`/teams?all=${all}&includeSpent=${includeSpent}`))
      .data as iTeam[]
  }

  public async getTeam(teamId: string): Promise<iTeam> {
    return (await axiosClient.get(`/teams/${teamId}`)).data as iTeam
  }

  public async createTeam(team: iTeam): Promise<iTeam> {
    const response = await axiosClient.post(`/teams`, team)
    return response.data
  }

  public async updateTeam(team: iTeam): Promise<iTeam> {
    const updatedTeam = (await axiosClient.put(`/teams/${team.id!}`, team))
      .data as iTeam
    return updatedTeam
  }

  public async deleteTeam(teamId: string): Promise<void> {
    await axiosClient.delete(`/teams/${teamId}`)
  }

  public async createTeamIcon(teamId: string): Promise<string> {
    const response = await axiosClient.post(`/teams/${teamId}/icons`)
    if (response.status !== 200) {
      throw new Error('Could not create team icon')
    }
    return response.data as string
  }

  public async deleteTeamIcon(teamId: string, iconId: string): Promise<void> {
    const response = await axiosClient.delete(`/teams/${teamId}/icons/${iconId}`)
    if (response.status !== 200 && response.status !== 204) {
      throw new Error('Could not delete team icon')
    }
  }

  public async getTeamIconUrl(teamId: string, iconId: string): Promise<string> {
    const response = await axiosClient.get(`/teams/${teamId}/icons/${iconId}`)
    if (response.status !== 200) {
      throw new Error('Could not get team icon URL')
    }
    return response.data as string
  }
}

export default TeamService.getInstance()
