import React, { useEffect, FocusEvent, useMemo, useState } from 'react'
import {
  PencilSquareIcon,
  InformationCircleIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline'
import { Button, Textarea, Tooltip } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import useMainStore from '@/states/mainState.ts'
import useTeamStore from '@/states/teamStore.ts'
import useAssistantStore from '@states/assistantStore.ts'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import AssistantService from '@/services/assistantService.ts'
import { Role } from '@/interfaces/iShared.ts'
import AssistantModelSelection from '../assistants/AssistantModelSelection.tsx'
import { isDefaultTeam } from '@/interfaces/iTeam.ts'
import { Popup } from '@/enums/PopupTypes.ts'
import AssistantModelSettings from '@components/session/AssistantModelSettings.tsx'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import { iAssistantModel } from '@/interfaces/iAssistantModel.ts'

const toolTipModel =
  'Select the model that you would like to use for generating text.'
const toolTipSystemPrompt =
  "This prompt will change GAIA's behavior. You can use this to clarify the context of your conversation, the topic or the output format."

type AssistantSettingsProps = {
  assistant: iAssistant
  updateAssistant: (assistant: iAssistant) => void
}
function AssistantSettings({ assistant, updateAssistant }: AssistantSettingsProps) {
  const setPopup = useMainStore((state) => state.setPopup)

  const { hasRightTo, selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  const getAssistantModelsById = useAssistantModelStore().getAssistantModelsById
  const { hasRightTo: hasAssistantRightTo } = useAssistantStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )

  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()

  const handleModelChange = (modelValue: iAssistantModel) => {
    console.log(modelValue)
    if (!assistant?.settings) {
      return
    }
    if (
      ['o1-preview-sweden-central', 'o1-mini-sweden-central'].includes(modelValue.id)
    ) {
      assistant.settings.stream = false
    } else {
      assistant.settings.stream = true
    }
    assistant.settings.modelId = modelValue.id
    setMaxOutputTokens(modelValue.maxOutputTokens)

    if (assistant.settings.maxTokens === 0) {
      assistant.settings.maxTokens = modelValue.maxOutputTokens
    } else {
      assistant.settings.maxTokens =
        assistant.settings.maxTokens! > modelValue.maxOutputTokens
          ? modelValue.maxOutputTokens
          : assistant.settings.maxTokens
    }
    updateAssistant(assistant)
  }

  const handleMaxTokenChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.maxTokens = range
      updateAssistant(assistant)
    }
  }

  const handleTemperatureChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.temperature = range
      updateAssistant(assistant)
    }
  }

  const handleTransferLengthChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.transferLength = range
      updateAssistant(assistant)
    }
  }

  const handleTopPChange = async (range: number) => {
    if (assistant?.settings) {
      assistant.settings.topP = range
      updateAssistant(assistant)
    }
  }

  const handleUpdateSystemPrompt = async (
    event: FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (assistant) {
      assistant.instruction = event.target.value
      updateAssistant(assistant)
    }
  }

  const handleStreamChange = async (isSelected: boolean) => {
    if (assistant?.settings) {
      assistant.settings.stream = isSelected
      updateAssistant(assistant)
    }
  }

  useEffect(() => {
    if (assistant.baseAssistantId && !assistant._baseAssistant)
      AssistantService.getAssistant(assistant.baseAssistantId).then(
        (baseAssistant) => {
          assistant._baseAssistant = baseAssistant
        },
      )
    const model = getAssistantModelsById(assistant.settings.modelId)
    if (model) setMaxOutputTokens(model.maxOutputTokens)
  }, [])

  const updateBaseAssistant = () => {
    if (assistant?._baseAssistant) {
      const tmpAssistant: iAssistant = assistant._baseAssistant
      tmpAssistant.settings!.stream = assistant.settings?.stream
      tmpAssistant.instruction = assistant.instruction
      tmpAssistant.settings!.topP = assistant.settings?.topP
      tmpAssistant.settings!.transferLength = assistant.settings?.transferLength
      tmpAssistant.settings!.temperature = assistant.settings?.temperature
      tmpAssistant.settings!.maxTokens = assistant.settings?.maxTokens
      tmpAssistant.settings!.modelId = assistant.settings?.modelId
      tmpAssistant.knowledgeContainerIds = assistant.knowledgeContainerIds
      tmpAssistant.toolIds = assistant.toolIds
      tmpAssistant.archiveChatHistory = assistant.archiveChatHistory
      AssistantService.updateAssistant(tmpAssistant)
    }
  }

  return (
    (hasRightTo(Role.Member) || isDefaultTeam(selectedTeam!)) && (
      <div className="grid grid-cols-1 gap-4 m-3 place-content-center min-w-[222px]">
        {/* <SystemSettingsOnboardingGuide></SystemSettingsOnboardingGuide> */}

        <div className="mt-4">
          <Tooltip content={toolTipSystemPrompt} className={'max-w-96'} delay={500}>
            <div className={'flex place-content-center'}>
              Instructions
              <InformationCircleIcon className="w-4 h-4 ml-1" />
            </div>
          </Tooltip>

          <div>
            <Textarea
              className="textarea textarea-primary textarea-sm w-full"
              id="systemPrompt"
              placeholder="System Prompt"
              defaultValue={assistant?.instruction}
              onBlur={(e) =>
                handleUpdateSystemPrompt(e as FocusEvent<HTMLInputElement, Element>)
              }
              rows={5}
            />
          </div>
        </div>

        <div data-guide-id="model" className="mt-4">
          <Tooltip content={toolTipModel} className={'max-w-96'} delay={500}>
            <div className={' flex place-content-center'}>
              Model
              <InformationCircleIcon className="w-4 h-4 ml-1" />
            </div>
          </Tooltip>

          <AssistantModelSelection
            variant="bordered"
            modelId={assistant!.settings!.modelId!}
            onChange={handleModelChange}
            context={'team'}
          ></AssistantModelSelection>
          <AssistantModelSettings
            onMaxTokensChange={handleMaxTokenChange}
            onStreamChange={handleStreamChange}
            onTemperatureChange={handleTemperatureChange}
            onTopPChange={handleTopPChange}
            onTransferLengthChange={handleTransferLengthChange}
            settings={assistant!.settings!}
            maxOutputTokens={maxOutputTokens ?? 0}
          ></AssistantModelSettings>

          <div data-guide-id="load-export" className="mt-4">
            <div
              className={'grid  justify-items-center min-w-fit min-w-[220px] pb-2'}
            >
              <Button
                variant={'bordered'}
                className="mt-3 min-w-fit w-full ml-1"
                onPress={() => {
                  setPopup(Popup.AssistantEdit)
                }}
              >
                <SwatchIcon
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                />
                Save as new Assistant
              </Button>
              {!!assistant._baseAssistant &&
                hasAssistantRightTo(Role.Manager, assistant._baseAssistant) && (
                  <Button
                    variant={'bordered'}
                    className="mt-3 min-w-fit w-full ml-1"
                    onPress={updateBaseAssistant}
                  >
                    <PencilSquareIcon
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    />
                    Update Assistant
                  </Button>
                )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default AssistantSettings
