export const BLACKLISTEDEXTENSIONS = [
  'exe',
  'dll',
  'dmg',
  'iso',
  'img',
  'msi',
  'msp',
  'deb',
  'rpm',
  'rar',
  '7z',
  'tar',
  'gz',
  'bz2',
  'xz',
  'lz',
  'lzma',
  'cab',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'lz',
  'lzh',
  'iso',
  'isz',
  'bzip2',
  'z',
  'tz',
  'rz',
  'tbz2',
  'tbz',
  'lzma',
  'tlz',
  'txz',
  'taz',
  'zipx',
  'sit',
  'sitx',
  'gz',
  'tgz',
  'tpz',
  'lz',
  'lzh',
  '7z',
  'rar',
  'gzip',
  'bzip2',
  'bzip',
  'tbz',
  'tbz2',
  'xz',
  'txz',
  'lzma',
  'tlz',
  'lz',
  'lzo',
  'rz',
  'egg',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'lz',
  'lzh',
  'iso',
  'isz',
  'bzip2',
  'z',
  'tz',
  'rz',
  'tbz2',
  'tbz',
  'lzma',
  'tlz',
  'txz',
  'taz',
  'zipx',
  'sit',
  'sitx',
  'gz',
  'tgz',
  'tpz',
  'lz',
  'lzh',
  '7z',
  'rar',
  'gzip',
  'bzip2',
  'bzip',
  'tbz',
  'tbz2',
  'xz',
  'txz',
  'lzma',
  'tlz',
  'lz',
  'lzo',
  'Z',
  'rz',
  'egg',
  'jar',
  'war',
  'ear',
  'sar',
  'rar',
  'alz',
  'ace',
  'z',
  'cpio',
  'arj',
  'wim',
  'swm',
  'dwm',
  'esd',
  'sfx',
  'mp4',
  'avi',
  'wmv',
  'mov',
  'mkv',
  'flv',
  'f4v',
  'm4v',
  'rmvb',
  'rm',
  '3gp',
  'dat',
  'vob',
  'mpg',
  'mp3',
  'wav',
  'wma',
  'ogg',
  'ape',
  'flac',
  'aac',
  'ac3',
  'aiff',
  'm4a',
  'mka',
  'm3u',
  'm3u8',
  'gif',
  'ds_store',
]
