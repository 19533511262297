import React from 'react'
import {
  ChatBubbleLeftIcon,
  FolderIcon,
  WrenchIcon,
  BookOpenIcon,
  PlusCircleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { Button } from '@nextui-org/react'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { ContentItemType, iMessage } from '@/interfaces/iMessage.ts'
import { iSession } from '@/interfaces/iSession.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'

interface SearchResultItemProps {
  result: {
    type: 'assistant' | 'message' | 'tool' | 'session' | 'knowledgeContainer'
    item: iAssistant | iMessage | iTool | iSession | iKnowledgeContainer
  }
  onClose: () => void
  navigate: (path: string) => void
  addToContext: (
    item: iAssistant | iMessage | iTool | iSession | iKnowledgeContainer,
    type: string,
  ) => void
}

const iconMap = {
  assistant: ChatBubbleLeftIcon,
  message: ChatBubbleLeftIcon,
  session: FolderIcon,
  tool: WrenchIcon,
  knowledgeContainer: BookOpenIcon,
}

function SearchResultItem({
  result,
  onClose,
  navigate,
  addToContext,
}: SearchResultItemProps) {
  const Icon = iconMap[result.type] || FolderIcon

  const handleNavigate = () => {
    const path = `/${result.type}s/${result.item.id}`
    navigate(path)
    onClose()
  }

  const handleAddToContext = () => {
    addToContext(result.item, result.type)
    onClose()
  }

  const renderActions = () => {
    switch (result.type) {
      case 'message':
      case 'session':
        return (
          <Button
            size="sm"
            color="primary"
            variant="light"
            startContent={<ArrowTopRightOnSquareIcon className="h-4 w-4" />}
            onPress={handleNavigate}
          >
            Go to {result.type}
          </Button>
        )
      case 'tool':
      case 'knowledgeContainer':
        return (
          <Button
            size="sm"
            color="secondary"
            variant="light"
            startContent={<PlusCircleIcon className="h-4 w-4" />}
            onPress={handleAddToContext}
          >
            Add to context
          </Button>
        )
      default:
        return null
    }
  }

  return (
    <div className="flex items-start space-x-3 w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-150 ease-in-out">
      <Icon className="h-5 w-5 text-gray-400 flex-shrink-0 mt-1" />
      <div className="min-w-0 flex-1">
        <div className="flex justify-between items-start">
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate max-w-[70%]">
            {('title' in result.item && result.item.title) ||
              ('name' in result.item && result.item.name) ||
              ('content' in result.item && result.item.content) ||
              ('contentItems' in result.item &&
                result.item.contentItems?.find(
                  (item) => item.type === ContentItemType.Text,
                )?.content) ||
              'Untitled'}
          </h3>
          <div className="ml-2 flex-shrink-0">{renderActions()}</div>
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
          {result.type.charAt(0).toUpperCase() + result.type.slice(1)} •{' '}
          {('description' in result.item && result.item.description) ||
            ('folderId' in result.item && result.item.folderId) ||
            ('sessionId' in result.item && result.item.sessionId) ||
            'No description'}
        </p>
      </div>
    </div>
  )
}

export default SearchResultItem
