import React, { useEffect, useState } from 'react'
import { getDocumentIcon } from '@/utils/getDocumentIcon.tsx'
import PDFViewer from '@components/basic/pdfViewer/PDFViewer.tsx'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { iFile, ViewerType } from '@/interfaces/iSourceFileCitations.ts'
import { iDocumentItem } from '@/interfaces/iDocumentItem.ts'
import { DocumentType } from '@/enums/DocumentType.ts'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Link,
  Skeleton,
} from '@nextui-org/react'

type ChatDocumentsDetailViewProps = {
  navigation: any
  document: iDocumentItem
  pageNumber?: number
}

// Main Component
const ChatDocumentsDetailView = ({
  navigation,
  document,
  pageNumber,
}: ChatDocumentsDetailViewProps) => {
  const [file, setFile] = useState<iFile>()
  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    setIsLoading(true)
    KnowledgeContainerService.getKnowledgeContainerDocumentsAccessUrls(
      document.knowledgeContainerId!,
      [`${document.knowledgeContainerId}/${document.refPath}`],
    )
      .then((data) => {
        setFile(data.length ? data[0] : undefined)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const downloadCard = () => {
    return (
      <Card className="w-full">
        <CardHeader>
          <div className="mr-5">{getDocumentIcon(document.documentType)}</div>
          <div className="flex flex-col">
            <p className="text-md">{file?.documentName || ''}</p>
            <p className="text-small text-default-500">{document.mimeType}</p>
          </div>
        </CardHeader>
        <CardBody>
          Unfortunately, we cannot display the file in GAIA. You can download and
          view the file at any time
        </CardBody>
        <CardFooter>
          <Link
            isExternal
            showAnchorIcon
            href="https://github.com/nextui-org/nextui"
          >
            View/Download source in a new Tab.
          </Link>
        </CardFooter>
      </Card>
    )
  }
  const getRenderer = () => {
    switch (document.documentType) {
      case DocumentType.Pdf:
        return (
          <PDFViewer
            url={file!.accessUrl}
            documentChunks={document._documentChunks!}
            initialPage={
              pageNumber ?? document._documentChunks![0].excerpt.startOffset
            }
          />
        )
      case DocumentType.Video:
        return (
          <object data={file?.accessUrl} width="300" height="200">
            Not supported
          </object>
        )
      default:
        return downloadCard()
    }
  }
  return (
    <div className="space-y-4 mt-4">
      <div className="flex items-center gap-3 mb-1">
        {getDocumentIcon(document.documentType)}
        <h2 className="text-xl flex-1 font-semibold">{document.title}</h2>
        <Button
          isIconOnly
          variant="light"
          onPress={() => window.open(file?.accessUrl, '_blank')}
        >
          <ArrowDownOnSquareIcon className="h-5 w-5"></ArrowDownOnSquareIcon>
        </Button>
      </div>
      {isLoading ? (
        <Skeleton className="rounded-large w-full h-44" />
      ) : (
        (file?.viewerType === ViewerType.GaiaViewer && getRenderer()) ||
        (file?.viewerType === ViewerType.HttpNewTab && downloadCard())
      )}
    </div>
  )
}

export default ChatDocumentsDetailView
