import React, { ReactHTMLElement } from 'react'

import ProfileImage from '../basic/profileImage/ProfileImage.tsx'
type UserDisplayProps = React.HTMLAttributes<HTMLDivElement> & {
  email: string
  displayName: string
  description: string
}
const UserDisplay = ({
  email,
  displayName,
  description,
  onClick,
}: UserDisplayProps) => {
  return (
    <div className="flex flex-row gap-1" onClick={(e) => onClick && onClick(e)}>
      <ProfileImage contact={email}></ProfileImage>
      <div className="flex flex-col items-start truncate ml-2">
        <span className="text-small bold truncate max-w-full">{displayName}</span>
        <span className="text-tiny align-start text-default-400 max-w-full">
          {description}
        </span>
      </div>
    </div>
  )
}

export default UserDisplay
