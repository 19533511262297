import React from 'react'
import SessionListItem from './SessionListItem.tsx'
import { iSession } from '@/interfaces/iSession.ts'

type SessionFolderProps = React.HTMLAttributes<HTMLDivElement> & {
  sessions: iSession[]
}
function SessionList({ sessions }: SessionFolderProps) {
  return sessions.map((session, key) => (
    <SessionListItem session={session} key={key}></SessionListItem>
  ))
}

export default SessionList
