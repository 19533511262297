import { InformationCircleIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
} from '@nextui-org/react'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import useChatStore from '@states/chatStore.ts'
import { Role } from '@/interfaces/iShared.ts'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import { iAssistantModel } from '@/interfaces/iAssistantModel.ts'
import useTeamStore from '@states/teamStore.ts'
import { itemAllowedInTeam } from '@/utils/itemAllowedInTeam.ts'
import IconFetcher from '@components/basic/icon/IconFetcher.tsx'
import { ICON_DELETE, ICON_EDIT } from '@/constants/icons.tsx'
import CopyToClipboard from '@components/basic/copyToClipboard/CopyToClipboard.tsx'
type AssistantCardProps = {
  value: iAssistant
  handleApply?: (preSetting: iAssistant) => void
  onClick?: () => void
  onDelete?: (assistantId: string) => Promise<void>
  onEditClick?: () => void
}

const AssistantCard = ({
  value,
  onDelete,
  handleApply,
  onClick,
  onEditClick,
}: AssistantCardProps) => {
  const [applied, setApplied] = useState(false)
  const [tmpAssistant, setTmpAssistant] = useState<iAssistant>(value)

  const selectedTeam = useTeamStore().selectedTeam
  const getAssistantModelsByContext =
    useAssistantModelStore().getAssistantModelsByContext

  const activeChatAssistant = useChatStore().assistant
  const activeSession = useChatStore().session

  useEffect(() => {
    setApplied(activeChatAssistant?.baseAssistantId === value.id)
  }, [value])

  const apply = useCallback(() => {
    if (applied && activeSession?.id === value.id) return
    setApplied(true)
    handleApply && handleApply(value)
  }, [applied, activeSession, value, handleApply])

  const assistantAllowed = (assistant: iAssistant) => {
    return itemAllowedInTeam(assistant, selectedTeam!.id!)
  }

  const modelAllowed = (assistant: iAssistant): boolean => {
    return !!getAssistantModelsByContext('team', selectedTeam!.id).find(
      (model: iAssistantModel) => model.id === assistant.settings?.modelId,
    )
  }

  const disableReasonText = useMemo(() => {
    if (selectedTeam) {
      // check if assistant model is allowed in current team session
      if (!modelAllowed(value)) {
        return 'The Assistant uses a model that has not been approved by your team.'
      }

      if (!assistantAllowed(value) && value.id) {
        return 'The Assistant is not allowed in your team.'
      }
    }
    return undefined
  }, [value, selectedTeam])

  const handleDelete = async () => {
    if (!onDelete) return
    value._isDeleting = true
    setTmpAssistant({ ...value })
    onDelete(value.id!)
      .then()
      .finally(() => {
        value._isDeleting = false
      })
  }
  return (
    <Card
      isPressable={!!onClick && !value._isDeleting}
      isHoverable={!!onClick && !value._isDeleting}
      isDisabled={value._isDeleting}
      className="min-w-[240px] h-[170px] max-h-[170px]"
      onPress={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <CardHeader className="justify-between">
        <div className="flex gap-5">
          <IconFetcher
            entityId={value.id!}
            entityType={'Assistant'}
            imageId={value.image}
          />
          <div className="flex flex-col gap-1 items-start justify-center truncate">
            <h4 className="text-small text-start font-semibold leading-none text-default-600 truncate">
              {value.title}
            </h4>
            <h5 className="text-small tracking-tight text-default-400">
              {value.ownerName}
            </h5>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <CopyToClipboard
            tooltip={'Copy assistant id'}
            textToCopy={value.id!}
          ></CopyToClipboard>
          {value.userRole! >= Role.Manager && onEditClick && (
            <Tooltip content={'Edit assistant '} className="max-w-96">
              <Button
                isIconOnly
                variant="light"
                isDisabled={value._isDeleting}
                onPress={() => {
                  if (onEditClick) {
                    onEditClick()
                    new Date().toLocaleDateString()
                  }
                }}
              >
                <ICON_EDIT></ICON_EDIT>
              </Button>
            </Tooltip>
          )}
          {value.userRole! >= Role.Owner && onDelete && (
            <Tooltip content={'Delete assistant '} className="max-w-96">
              <Button
                isDisabled={value._isDeleting}
                isLoading={value._isDeleting}
                isIconOnly
                variant="light"
                color="danger"
                onPress={handleDelete}
              >
                <ICON_DELETE></ICON_DELETE>
              </Button>
            </Tooltip>
          )}
        </div>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small text-default-400">
        <span className="pt-2 text-default-400 text-small whitespace-pre-wrap line-clamp-2">
          {value.description}
        </span>
      </CardBody>
      <CardFooter className="flex justify-between items-center gap-3">
        <div className="flex gap-1 items-center">
          <p className="text-small text-default-400">{value.requestCount}</p>
          <RocketLaunchIcon className="w-5 h-5 text-default-400" />
        </div>
        <div className="flex items-center gap-2">
          {disableReasonText && (
            <Tooltip content={disableReasonText} className="max-w-96">
              <InformationCircleIcon
                fill="none"
                stroke="currentColor"
                className="w-6 h-6"
              />
            </Tooltip>
          )}
          {handleApply && (
            <Button
              variant={applied ? undefined : 'bordered'}
              color={applied ? 'default' : undefined}
              isDisabled={!!disableReasonText || value._isDeleting}
              onPress={apply}
            >
              {applied ? 'Applied' : 'New Session'}
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  )
}

export default AssistantCard
