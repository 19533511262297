import { createWithEqualityFn } from 'zustand/traditional'
import { iAssistantModel } from '../interfaces/iAssistantModel.ts'
import assistantService from '../services/WebSocketService.ts'

interface iAssistantModelState {
  isLoading: boolean
  assistantModels: iAssistantModel[]
  setIsLoading: (isLoading: boolean) => void
  setAssistantModels: (models: iAssistantModel[]) => void
  getDefaultModel: () => iAssistantModel
  getAssistantModelsById: (id: string) => iAssistantModel | undefined
  getAssistantModelsByContext: (
    context: 'all' | 'team',
    teamId?: string,
  ) => iAssistantModel[]
  loadAssistantModels: (
    scopes: string[],
    teamId?: string,
  ) => Promise<iAssistantModel[]>
}

const useAssistantModelStore = createWithEqualityFn<iAssistantModelState>(
  (set, getState) => ({
    isLoading: false,
    assistantModels: [],
    getDefaultModel: () => {
      console.log(getState().assistantModels)
      return getState().assistantModels.find(
        (model) => model.id === 'gpt-4o-sweden-central',
      )!
    },
    getAssistantModelsByContext: (context: 'all' | 'team', teamId?: string) => {
      const models = getState().assistantModels
      if (context === 'team') {
        if (!teamId) throw new Error('teamId param is necessary for team context')
        return models.filter((m) =>
          m.teamReferences?.find((teamRefId) => teamRefId === teamId),
        )
      }
      return models
    },
    getAssistantModelsById: (id: string) => {
      return getState().assistantModels.find((model) => model.id === id)
    },
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    setAssistantModels: (assistantModels: iAssistantModel[]) =>
      set(() => ({ assistantModels })),
    loadAssistantModels: async (scopes) => {
      const models = await assistantService.getAssistantModels(scopes)
      getState().setAssistantModels(models)
      return models
    },
  }),
)

export default useAssistantModelStore
