import legalJSON from '../../public/legal/legal.json'

export const GAIA_LEGAL_JSON = {
  version: legalJSON.date,
  content: [
    {
      language: 'en',
      isFallback: true,
      text: `In order to use GAIA, the terms of use and privacy policy must be
              accepted. If you agree to the terms and conditions, click on the
              "Accept" button`,
      documents: [
        {
          id: 'gaia-en-tou',
          metadata: {
            attributes: {
              displayName: 'Terms of Use',
            },
          },
          source: `./legal/${
            legalJSON.termsOfUse.find(
              (tou: { lang: string; fileName: string }) => tou.lang === 'en',
            )?.fileName
          }`,
        },
      ],
    },
  ],
}
