import { createWithEqualityFn } from 'zustand/traditional'
import { AccessRight, Role } from '../interfaces/iShared.ts'
import AssistantService from '../services/assistantService.ts'
import { iAssistant } from '../interfaces/iAssistantTypes.ts'
import { getUserKey } from '../utils/authentication.ts'
import { iDefaultStore } from '@/interfaces/stores/iDefaultStore.ts'

interface iAssistantState {
  storeIsInitialized: boolean
  isLoading: boolean
  assistants: iAssistant[]
  search: { text: string }
  isLoadingSharedAssistants: boolean
  isLoadingManagedAssistants: boolean
  sharedAssistants: { data: iAssistant[]; total: number }
  managedAssistants: { data: iAssistant[]; total: number }
}

interface iAssistantStore extends iAssistantState, Omit<iDefaultStore, 'init'> {
  getAssistantRole: (assistant: iAssistant) => Role | undefined
  getAssistantById: (assistantId: string) => iAssistant | undefined
  hasRightTo: (minRole: Role, assistant: iAssistant) => boolean
  deleteAssistant: (id: string) => Promise<void>
  createAssistant: (assistant: iAssistant) => Promise<iAssistant>
  setIsLoading: (isLoading: boolean) => void
  setAssistants: (assistants: iAssistant[]) => void
  loadAssistants: (options?: {
    accessRight: AccessRight
    searchTerm: string
  }) => Promise<iAssistant[]>
  setSearch: (search: { text: string }) => void
}

const initialState: iAssistantState = {
  storeIsInitialized: false,
  isLoading: true,
  search: { text: '' },
  assistants: [],
  sharedAssistants: {
    data: [],
    total: 0,
  },
  managedAssistants: {
    data: [],
    total: 0,
  },
  isLoadingSharedAssistants: true,
  isLoadingManagedAssistants: true,
}
const useAssistantStore = createWithEqualityFn<iAssistantStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async () => {
    getState().reset()
    set({ storeIsInitialized: true })
  },
  setAssistants: (assistants: iAssistant[]) => {
    set(() => ({ assistants }))
  },
  setSearch: (search: { text: string }) => {
    set(() => ({ search }))
  },
  deleteAssistant: async (id) => {
    await AssistantService.deleteAssistant(id)
    const sharedAssistants = getState().sharedAssistants.data.filter(
      (assistant) => assistant.id !== id,
    )
    const managedAssistants = getState().managedAssistants.data.filter(
      (assistant) => assistant.id !== id,
    )
    set((store) => ({
      sharedAssistants: { ...store.sharedAssistants, data: sharedAssistants },
    }))
    set((store) => ({
      managedAssistants: { ...store.managedAssistants, data: managedAssistants },
    }))
  },
  createAssistant: async (assistant: iAssistant) => {
    const newAssistant = await AssistantService.createAssistant(assistant)
    const tmpAssistants = [...getState().assistants, newAssistant]
    getState().setAssistants(tmpAssistants)
    return newAssistant
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  getAssistantRole: (assistant) => {
    const shared = assistant?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  getAssistantById: (assistantId) => {
    return getState().assistants.find((a: iAssistant) => a.id === assistantId)
  },

  hasRightTo: (minRole: Role, assistant) => {
    const isOwner = assistant?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getAssistantRole(assistant)
    if (role === undefined) return false
    return role <= minRole
  },
  loadAssistants: async (options?: {
    accessRight?: AccessRight
    searchTerm: string
  }) => {
    if (options?.accessRight === AccessRight.Write) {
      set({ isLoadingManagedAssistants: true })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      set({ isLoadingSharedAssistants: true })
    }

    const assistants = await AssistantService.getAssistants({
      accessRight: options?.accessRight,
      searchValue: options?.searchTerm,
    })
    if (options?.accessRight === AccessRight.Write) {
      set({ managedAssistants: assistants })
      set({ isLoadingManagedAssistants: false })
    }
    if (options?.accessRight === AccessRight.ReadOnly) {
      set({ sharedAssistants: assistants })
      set({ isLoadingSharedAssistants: false })
    }
    return assistants.data
  },
}))

export default useAssistantStore
