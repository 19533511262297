// MyComponent.jsx
import React from 'react'
import useMainStore from '../../states/mainState.ts'
import FileSettings from '../files/FileSetting.tsx'
import { Popup } from '@/enums/PopupTypes.ts'
import UserManagementList from '../user/UserManagementList.tsx'
import SecretManager from './SecretManager.tsx'
import PatManager from './PatManager.tsx'
import Changelog from './Changelog.tsx'
import ChatFeedback from '../feedback/ChatFeedback.tsx'
import SpotlightSearch from '@components/spotlight/SpotlightSearch.tsx'

const ContextPopup = () => {
  const { setPopup, popup, popupArgs } = useMainStore((state) => ({
    setPopup: state.setPopup,
    popup: state.popup,
    popupArgs: state.popupArgs,
  }))

  const showPopupContent = () => {
    switch (popup) {
      case Popup.Files:
        return (
          <FileSettings
            onSelect={(documents) => {
              if (popupArgs?.onSelect) popupArgs.onSelect(documents)
            }}
            closeFunction={() => {
              setPopup(Popup.None)
            }}
          />
        )
      case Popup.UserManagement:
        return <UserManagementList closeFunction={() => setPopup(Popup.None)} />
      case Popup.Spotlight:
        return <SpotlightSearch onClose={() => setPopup(Popup.None)} />
      case Popup.Secrets:
        return <SecretManager onClose={() => setPopup(Popup.None)} />
      case Popup.PatKeys:
        return <PatManager onClose={() => setPopup(Popup.None)} />
      case Popup.Changelog:
        return <Changelog onClose={() => setPopup(Popup.None)} />
      case Popup.SessionFeedback:
        return (
          <ChatFeedback
            onClose={() => {
              setPopup(Popup.None)
              if (popupArgs?.onClose) popupArgs?.onClose()
            }}
            onClear={async () => {
              await popupArgs?.onClear()
              setPopup(Popup.None)
            }}
          />
        )
      case Popup.None:
        return <></>
      default:
        return <></>
    }
  }
  return <>{showPopupContent()}</>
}

export default ContextPopup
