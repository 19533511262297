import React, { useEffect, useState } from 'react'
import HttpConstantSetEditor from './HttpConstantSetEditor.tsx'
import HttpParameterSetEditor from './HttpParameterSetEditor.tsx'
import { InputField, SelectField, TextField } from '../../basic/inputfields/model.ts'
import { FieldsEditor } from '../../basic/inputfields/FieldEditors.tsx'
import {
  HttpConstantSet,
  HttpMethod,
  httpMethods,
  HttpParameterSet,
  iHttpTemplate,
} from '../../../interfaces/iHttpParameter.ts'

const tooltipMethod =
  'Auto: GAIA will determine the method based on the request. GET: Retrieve information from an endpoint. POST: Send information to an endpoint. PUT: Update information on an endpoint. DELETE: Remove information from an endpoint.'
const tooltipUrl =
  'The URL of the request. Can contain path parameters in the form of {parameterName}.'

type HttpTemplateEditorProps = {
  currentHttpTemplate: iHttpTemplate
  onChange: (httpTemplate: iHttpTemplate) => void
  isDisabled?: boolean
}

const HttpTemplateEditor = ({
  currentHttpTemplate,
  onChange,
  isDisabled,
}: HttpTemplateEditorProps) => {
  const [httpTemplate, setHttpTemplate] =
    useState<iHttpTemplate>(currentHttpTemplate)
  const [parameters, setParameters] = useState<HttpParameterSet>(
    currentHttpTemplate.parameters,
  )
  const [constants, setConstants] = useState<HttpConstantSet>(
    currentHttpTemplate.constants,
  )
  const [fields, setFields] = useState<InputField[]>([
    new SelectField({
      key: 'method',
      name: 'Method',
      value: { key: httpTemplate.method },
      options: (httpMethods as unknown as string[]).map((method) => ({
        key: method,
      })),
      info: tooltipMethod,
    }),
    new TextField({
      key: 'url',
      name: 'URL',
      value: httpTemplate.url,
      info: tooltipUrl,
    }),
  ])

  useEffect(() => {
    setHttpTemplate({ ...httpTemplate, parameters })
  }, [parameters])

  useEffect(() => {
    setHttpTemplate({ ...httpTemplate, constants })
  }, [constants])

  useEffect(() => {
    onChange(httpTemplate)
  }, [httpTemplate])

  useEffect(() => {
    setHttpTemplate({
      ...httpTemplate,
      method: (fields[0] as SelectField).value.key as HttpMethod,
      url: (fields[1] as TextField).value,
    })
  }, [fields])

  return (
    <>
      <FieldsEditor
        isDisabled={isDisabled}
        fields={fields}
        onChange={setFields}
      ></FieldsEditor>
      <div className="mt-4">
        <HttpConstantSetEditor
          isDisabled={isDisabled}
          constantSet={constants}
          onChange={setConstants}
        />
        <HttpParameterSetEditor
          isDisabled={isDisabled}
          parameterSet={parameters}
          onChange={setParameters}
        />
      </div>
    </>
  )
}
export default HttpTemplateEditor
