import React from 'react'

import { TEST_PAGE_MANAGE_YOUR_KNOWLEDGE_CONTAINERS } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import KnowledgeContainersListView from '@components/knowledgeContainers/KnowledgeContainersListView.tsx'
import { ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_NEW } from '@/constants/routes.ts'
import { useNavigate } from 'react-router-dom'

function KnowledgeContainersListPage() {
  const navigate = useNavigate()

  return (
    <DefaultPageLayout
      createAble
      title="Knowledge-Containers"
      data-testid={TEST_PAGE_MANAGE_YOUR_KNOWLEDGE_CONTAINERS}
      onCreateClick={async () => {
        navigate(ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS_NEW)
      }}
    >
      <KnowledgeContainersListView></KnowledgeContainersListView>
    </DefaultPageLayout>
  )
}

export default KnowledgeContainersListPage
