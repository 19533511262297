export function mergeArrays<T>(
  arr1: Array<T>,
  arr2: Array<T>,
  key: keyof T,
): Array<T> {
  // Create a map to hold objects by their id
  const map = new Map<T[keyof T], T>()

  // Add all objects from the first array to the map
  arr1.forEach((item) => map.set(item[key], item))

  // Update or add objects from the second array
  arr2.forEach((item) => {
    if (map.has(item[key])) {
      // If the item exists, update it
      map.set(item[key], { ...map.get(item[key]), ...item })
    } else {
      // If the item does not exist, add it
      map.set(item[key], item)
    }
  })

  // Convert the map back to an array
  return Array.from(map.values())
}
