interface iCitationProps {
  cid: string
  backHRefs: string[]
  text: string
  onClick: () => void
}

const Citation = (props: iCitationProps) => {
  return (
    <div data-id={`cid-${props.cid}`} key={props.cid}>
      <sup className="mr-2">{props.cid}</sup>
      <span
        className={'text-sm font-semibold hover:underline cursor-pointer'}
        onClick={props.onClick}
      >
        {props.text}
      </span>
      {props.backHRefs.map((href: string, index) => (
        <a
          href={href}
          key={props.cid + index}
          data-footnote-backref=""
          className="data-footnote-backref"
        >
          ↩
        </a>
      ))}
    </div>
  )
}

export default Citation
