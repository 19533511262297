import React, { useEffect, useState } from 'react'

import {
  DocumentTextIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import { Chip, Select, SelectItem, SelectSection, Tooltip } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import useAssistantModelStore from '@states/assistantModelStore.ts'
import {
  AssistantModelAttribute,
  iAssistantModel,
} from '@/interfaces/iAssistantModel.ts'
import { groupByKey } from '@/utils/groupByKey.ts'

type AIModelSelectionProps = {
  modelId: string
  onChange?: (value: iAssistantModel) => void
  isDisabled?: boolean
  variant?: 'bordered' | 'flat' | undefined
  context?: 'all' | 'team'
}

const AssistantModelSelection = ({
  modelId,
  onChange,
  isDisabled,
  variant,
  context = 'all',
}: AIModelSelectionProps) => {
  const { assistantModels } = useAssistantModelStore(
    (state) => ({
      assistantModels: state.assistantModels,
    }),
    shallow,
  )
  const [selectedModel, setSelectedModel] = useState<iAssistantModel>()
  useEffect(() => {
    const model = assistantModels.find((model) => model.id === modelId)
    setSelectedModel(model)
  }, [modelId])

  const previewModelTooltip = `This model is for preview purposes only and are not production-ready. Use at your own risk.`

  const handleModelChange = (model: iAssistantModel) => {
    if (onChange) onChange(model)
    setSelectedModel(model)
  }

  const attributeDisplay = (attr: string) => {
    let icon = <QuestionMarkCircleIcon className={'w-5 h-5'} />
    let tooltip = attr
    switch (attr) {
      case AssistantModelAttribute.toolCalling:
        icon = <WrenchIcon className={'w-5 h-5'} />
        tooltip = 'Tool Calling'
        break
      case AssistantModelAttribute.parallelToolCalling:
        icon = <WrenchScrewdriverIcon className={'w-5 h-5'} />
        tooltip = 'Parallel Tool Calling'
        break
      case AssistantModelAttribute.textProcessing:
        icon = <DocumentTextIcon className={'w-5 h-5'} />
        tooltip = 'Text Processing'
        break
      case AssistantModelAttribute.imageProcessing:
        icon = <PhotoIcon className={'w-5 h-5'} />
        tooltip = 'Image Processing'
        break
    }
    return {
      tooltip,
      icon,
    }
  }

  const renderSection = () => {
    const t = []
    for (const [key, value] of Object.entries(
      groupByKey(assistantModels, 'region'),
    )) {
      const r = (
        <SelectSection title={key}>
          {value.map((model) => (
            <SelectItem key={model.id} value={model.deploymentName}>
              <div className={'flex items-center'}>
                <span className={model?.preview ? 'text-orange-400' : ''}>
                  {model?.name}
                </span>
                {model?.preview && (
                  <Tooltip content={previewModelTooltip} className={'max-w-96'}>
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </Tooltip>
                )}
              </div>
            </SelectItem>
          ))}
        </SelectSection>
      )
      t.push(r)
    }
    return t
  }
  return (
    !!assistantModels.length && (
      <div>
        <div className="flex gap-2 w-full">
          <Select
            variant={variant}
            aria-label="model selection"
            selectedKeys={[modelId]}
            items={assistantModels}
            disallowEmptySelection
            className="w-full"
            isDisabled={isDisabled}
            onSelectionChange={(e) => {
              const model = assistantModels.find(
                (model) => model.id === e.currentKey,
              )
              if (model) {
                handleModelChange(model)
              }
            }}
            renderValue={(items) => {
              return items.map((item) => (
                <span
                  key={item.data?.id}
                  className={selectedModel?.preview ? 'text-orange-400' : ''}
                >
                  {selectedModel?.name} ({selectedModel?.region})
                </span>
              ))
            }}
          >
            {renderSection()}
          </Select>
        </div>
        <div>
          {selectedModel?.attributes.map((attr, key) => {
            const attribute = attributeDisplay(attr)
            return (
              <Tooltip
                content={attribute.tooltip}
                key={key + '-attr'}
                className={'max-w-96'}
              >
                <Chip key={key + '-attr1'} color={'primary'} className=" my-2 mr-1">
                  {attribute.icon}
                </Chip>
              </Tooltip>
            )
          })}
        </div>
      </div>
    )
  )
}

export default React.memo(AssistantModelSelection)
