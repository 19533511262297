import React, { useMemo, useState } from 'react'

import { TEST_PAGE_MANAGE_YOUR_ASSISTANTS } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import AssistantsListView from '@components/assistants/AssistantsListView.tsx'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ROUTE_MARKETPLACE_ASSISTANTS_NEW } from '@/constants/routes.ts'

function AssistantsListPage() {
  const navigate = useNavigate()
  return (
    <DefaultPageLayout
      createAble
      title="Assistants"
      data-testid={TEST_PAGE_MANAGE_YOUR_ASSISTANTS}
      onCreateClick={async () => {
        navigate(ROUTE_MARKETPLACE_ASSISTANTS_NEW)
      }}
    >
      <AssistantsListView></AssistantsListView>
    </DefaultPageLayout>
  )
}

export default AssistantsListPage
