import React, { useEffect, useRef, useState } from 'react'
import { shallow } from 'zustand/shallow'
import useUserProfileStore from '@states/userProfileState.ts'
import ChatMessageWrapper from './ChatMessageWrapper.tsx'
import ChatWindowMessageBar from './ChatWindowMessageBar.tsx'
import SessionChatControl from '../session/ChatControl.tsx'
import useSignalR from '@/hooks/useSignalR.ts'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { iMessage } from '@/interfaces/iMessage.ts'
import { Button, Skeleton } from '@nextui-org/react'
import useChatStore from '@states/chatStore.ts'
import SkeletonChatMessage from '@components/skeletons/SkeletonChatMessage.tsx'

function ChatWindow() {
  const { ref, inView } = useInView()
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState<number>()
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  const chatWindowRef = useRef<HTMLDivElement>(null)

  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const fetchMessages = useChatStore((state) => state.fetchMessages)
  const sessionId = useChatStore((state) => state.session?.id)!
  const isGenerating = useChatStore((state) => state.isAssistantGenerating)!
  const messages = useChatStore().messages
  const isLoadingMessages = useChatStore().isLoadingMessages
  const messageLength = messages.length

  const lastMessage = useChatStore((state) => {
    const messages = state.messages ?? []
    return messages[0] ?? undefined
  }, shallow)
  const { autoScrolling, setAutoScrolling } = useSignalR({
    userId: userProfile!.id!,
  })
  const hasNextPage = total === undefined ? true : total > offset
  const fetchNextPage = async () => {
    if (!hasNextPage) return
    if (!chatWindowRef.current) return

    const scrollTopBefore = chatWindowRef.current.scrollTop
    const scrollHeightBefore = chatWindowRef.current.scrollHeight

    setIsFetchingNextPage(true)
    const response = await fetchMessages(sessionId, {
      offset,
      disableLoading: offset > 0,
    })

    setTotal(response.total)
    setOffset(offset + 10)
    setIsFetchingNextPage(false)

    if (offset > 0) {
      // Maintain scroll position when loading more messages
      requestAnimationFrame(() => {
        if (chatWindowRef.current) {
          const scrollHeightAfter = chatWindowRef.current.scrollHeight
          chatWindowRef.current.scrollTop =
            scrollTopBefore + (scrollHeightAfter - scrollHeightBefore)
        }
      })
    }
  }

  useEffect(() => {
    fetchNextPage()
  }, [])

  useEffect(() => {
    if (isGenerating && autoScrolling) scrollToBottomOfChatWindow()
  }, [isGenerating, lastMessage?.content, autoScrolling])

  useEffect(() => {
    if (!isLoadingMessages && offset === 10) {
      // On initial load, scroll to bottom
      scrollToBottomOfChatWindow()
    }
  }, [isLoadingMessages, offset])

  const scrollToBottomOfChatWindow = () => {
    if (chatWindowRef.current) {
      setTimeout(() => {
        chatWindowRef.current!.scrollTop = chatWindowRef.current!.scrollHeight
      }, 0)
    }
  }

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!isGenerating) return
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLElement
    const isNearBottom = Math.round(scrollTop + clientHeight) >= scrollHeight
    if (isNearBottom) {
      setAutoScrolling(true)
    } else {
      setAutoScrolling(false)
    }
  }

  return (
    <div className="chat-window-wrapper overflow-x-hidden flex w-full">
      <div
        className={`chat-window items-center flex pt-2 flex-col transition-all w-full mx-auto px-14 h-full overflow-y-auto overflow-x-hidden`}
        onScrollCapture={onScroll}
        ref={chatWindowRef}
      >
        {!isLoadingMessages && messageLength > 0 && (
          <div>
            <Button
              className="z-10"
              ref={ref}
              onPress={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
              isDisabled={!hasNextPage || isFetchingNextPage}
            >
              {hasNextPage ? 'Load more' : 'Start of the current session'}
            </Button>
          </div>
        )}
        <div className={`w-full flex flex-1 flex-col min-h-full max-w-6xl`}>
          {isLoadingMessages ? (
            <>
              <div className={'flex-1 flex-col flex gap-12 mt-12'}>
                <SkeletonChatMessage></SkeletonChatMessage>
                <SkeletonChatMessage messageHeight="h-36" left></SkeletonChatMessage>
                <SkeletonChatMessage></SkeletonChatMessage>
                <SkeletonChatMessage messageHeight="h-56" left></SkeletonChatMessage>
              </div>
              <Skeleton className="h-44 rounded-t-medium w-full" />
            </>
          ) : (
            <>
              <ChatMessageWrapper></ChatMessageWrapper>
              <ChatWindowMessageBar></ChatWindowMessageBar>
            </>
          )}
        </div>
      </div>
      <SessionChatControl />
    </div>
  )
}

export default React.memo(ChatWindow)
