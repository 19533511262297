export function groupByKey<T extends Record<string, any>>(
  array: T[],
  key: keyof T,
): Record<string, T[]> {
  const groupedResult: Record<string, T[]> = {}
  for (const item of array) {
    const groupKey = item[key]
    if (!groupedResult[groupKey]) {
      groupedResult[groupKey] = []
    }
    groupedResult[groupKey].push(item)
  }
  return groupedResult
}
