import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { deepCopy } from '@/utils/deepCopy.ts'
import HttpTemplateEditor from './httpTemplates/HttpTemplateEditor.tsx'
import { Checkbox, Input, Tab, Tabs, Textarea } from '@nextui-org/react'
import OverridableEditor from './OverridableSetEditor.tsx'
import { ExecutionEnvironment, iTool } from '@/interfaces/iTool.ts'
import { ShareType } from '@/interfaces/iItem.ts'
import { iShared } from '@/interfaces/iShared.ts'
import { OverridableParameterSet } from '@/interfaces/iOverridableParameter.ts'
import ToolService from '../../services/toolService.ts'
import MembersSelection from '../members/MemberSelection.tsx'
import { iHttpTemplate } from '@/interfaces/iHttpParameter.ts'
import ShareWithSelection from '@components/basic/shareWithSelection/ShareWithSelection.tsx'
import { toast } from 'react-toastify'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

export type ToolDetailViewHandler = {
  saveTool: () => Promise<iTool | undefined>
}

type ToolDetailViewProps = {
  value: iTool
  isDisabled?: boolean
}

const ToolDetailView: ForwardRefRenderFunction<
  ToolDetailViewHandler,
  ToolDetailViewProps
> = ({ value, isDisabled }: ToolDetailViewProps, ref) => {
  const [tool, setTool] = useState<iTool>({ ...value })
  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])
  const [isHttpTool, setIsHttpTool] = useState<boolean>(false)
  const [httpTemplate, setHttpTemplate] = useState<iHttpTemplate>(
    value?.httpTemplate || {
      url: '',
      method: 'auto',
      constants: {},
      parameters: {},
    },
  )
  const [shared, setShared] = useState<iShared[]>([])
  const [definitionStyle, setDefinitionStyle] = useState<
    null | 'default' | 'templateString'
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [parameterOverrides, setParameterOverrides] = useState<string[]>(
    tool.parameterOverrides || [],
  )
  const [overridables, setOverridables] = useState<OverridableParameterSet>(
    tool.overridableParameters || {},
  )

  useImperativeHandle(ref, () => ({
    saveTool,
  }))

  useEffect(() => {
    if (isHttpTool) {
      setTool({
        ...tool,
        httpTemplate,
        executionEnvironment: ExecutionEnvironment.ExternalHttp,
      })
    } else {
      setTool({
        ...tool,
        httpTemplate: undefined,
        executionEnvironment: ExecutionEnvironment.Local,
      })
    }
  }, [isHttpTool])

  const onSelectChange = (value: ShareType) => {
    setTool({ ...tool, sharedType: value })
    switch (value) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  const saveTool = async () => {
    try {
      const id = toast('Please wait...', { isLoading: true })
      let newTool: iTool
      setIsLoading(true)
      if (!value.id) {
        newTool = await ToolService.createTool(tool)
      } else {
        newTool = await ToolService.updateTool(tool)
      }
      setIsLoading(false)
      toast.update(id, {
        render: 'All is good',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      })
      return newTool
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (value) {
      setTool(value)
      setIsHttpTool(!!value.httpTemplate)
      if (!value.shared) {
        setShared([])
        setTool({ ...tool, shared: [] })
      } else {
        setShared(value.shared!)
        setTool({ ...tool, shared: value.shared! })
      }
    }
  }, [])

  useEffect(() => {
    setTool({ ...tool, shared: shared })
  }, [shared])

  useEffect(() => {
    if (definitionStyle === 'default') {
      setTool({ ...tool, httpTemplate: httpTemplate })
    }
    if (definitionStyle === 'templateString') {
      setTool({ ...tool, httpTemplateString: '<HTTP request template>' })
    }
  }, [definitionStyle])

  const handleDeleteParameterOverride = (index: number) => {
    const updatedOverrides = [...parameterOverrides]
    updatedOverrides.splice(index, 1)
    setParameterOverrides(updatedOverrides)
    setTool({ ...tool, parameterOverrides: updatedOverrides })
  }
  return (
    <>
      <Tabs>
        <Tab key="general" title="General">
          <SectionCard title="Tool setup">
            <div className="mb-4">
              <Input
                label="Name"
                readOnly={isDisabled}
                type="text"
                name="name"
                defaultValue={tool.name}
                onChange={(e) => setTool({ ...tool, name: e.target.value })}
                isRequired
              />
            </div>
            <div className="mb-4">
              <Input
                label="Function name"
                readOnly={isDisabled}
                type="text"
                name="functionName"
                defaultValue={tool.functionName}
                isRequired
              />
            </div>
            <div className="mb-4">
              <Input
                label="Image url"
                readOnly={isDisabled}
                type="text"
                defaultValue={tool.image}
                name="image"
                isRequired
              />
            </div>
            <div className="mb-4">
              <Textarea
                label="Description"
                readOnly={isDisabled}
                defaultValue={tool.description}
                name="description"
                rows={4}
                isRequired
              />
            </div>
            <div className="mb-4">
              <Checkbox
                isDisabled={isDisabled}
                isSelected={tool.citationRequired}
                onValueChange={(checked) =>
                  setTool({ ...tool, citationRequired: checked })
                }
              >
                Citation required
              </Checkbox>
            </div>
          </SectionCard>
        </Tab>
        <Tab key="api" title="API">
          <SectionCard title="Parameter" description="">
            <div>
              <div className="text-base mb-2 mt-2 flex">
                <Checkbox
                  isDisabled={isDisabled}
                  className=" mr-2 mt-2"
                  isSelected={isHttpTool}
                  onValueChange={(checked) => setIsHttpTool(checked)}
                >
                  API Tool
                </Checkbox>
              </div>
              {isHttpTool && (
                <HttpTemplateEditor
                  isDisabled={isDisabled}
                  currentHttpTemplate={httpTemplate}
                  onChange={(template) =>
                    setTool({ ...tool, httpTemplate: template })
                  }
                />
              )}
            </div>
            <div className="mb-4">
              <OverridableEditor
                isDisabled={isDisabled}
                overridableSet={overridables}
                onChange={(o) => setTool({ ...tool, overridableParameters: o })}
              ></OverridableEditor>
            </div>
          </SectionCard>
        </Tab>
        <Tab key="publicity" title="Public">
          <div className="flex flex-col gap-4">
            <SectionCard
              className="min-w-96"
              title="Privacy Settings"
              description=""
            >
              <div className="flex flex-col gap-4">
                <ShareWithSelection
                  isDisabled={isDisabled}
                  selectedKey={tool.sharedType ?? ShareType.None}
                  onChange={onSelectChange}
                ></ShareWithSelection>
              </div>
            </SectionCard>
            <MembersSelection
              isDisabled={isDisabled || tool.sharedType === ShareType.None}
              shared={shared}
              ownerId={tool.ownerId}
              onSelectionChange={setShared}
              writeOnly={tool.sharedType === ShareType.Public}
              roles={tool!.roles}
            />
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default React.forwardRef(ToolDetailView)
