import React, { useEffect, useRef, useState } from 'react'

import { TEST_PAGE_DETAIL_KNOWLEDGE_CONTAINER } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import KnowledgeContainerDetailView, {
  KnowledgeContainerDetailViewHandler,
} from '@components/knowledgeContainers/KnowledgeContainerDetailView.tsx'
import { Role } from '@/interfaces/iShared.ts'
import { ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS } from '@/constants/routes.ts'
import { getUser, getUserEmail } from '@/utils/authentication.ts'

const KnowledgeContainerDetailPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [knowledgeContainer, setKnowledgeContainer] = useState<
    iKnowledgeContainer | undefined
  >()
  const { id } = useParams()
  const viewRef = useRef<KnowledgeContainerDetailViewHandler>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return
    KnowledgeContainerService.getKnowledgeContainer(id)
      .then((data) => {
        setKnowledgeContainer(data)
      })
      .finally(() => setIsLoading(false))
  }, [id])

  useEffect(() => {
    // create an empty default assistant
    if (!id || id === 'new') {
      setIsEditMode(true)
      const defaultKnowledgeContainer: iKnowledgeContainer = {
        name: 'Default Knowledge Container',
        ownerEmail: getUserEmail() as string,
        ownerName: getUser().displayName as string,
      }
      setKnowledgeContainer(defaultKnowledgeContainer)
      setIsLoading(false)
      return
    }
    KnowledgeContainerService.getKnowledgeContainer(id)
      .then((data) => {
        setKnowledgeContainer(data)
      })
      .finally(() => setIsLoading(false))
  }, [id])
  return (
    <DefaultPageLayout
      notFound={!knowledgeContainer && !isLoading}
      editMode={isEditMode}
      title={knowledgeContainer?.name ?? 'Knowledge-Container'}
      data-testid={TEST_PAGE_DETAIL_KNOWLEDGE_CONTAINER}
      editAble={knowledgeContainer?.userRole! >= Role.Manager}
      onSaveClick={async () => {
        return viewRef.current?.saveContainer().then((knowledgeContainer) => {
          if (knowledgeContainer)
            navigate(
              `${ROUTE_MARKETPLACE_KNOWLEDGE_CONTAINERS}/${knowledgeContainer.id}`,
              {
                replace: true,
              },
            )
        })
      }}
    >
      {(selectedTab, isEditMode) =>
        isLoading ? (
          <SkeletonDetailPage></SkeletonDetailPage>
        ) : (
          <KnowledgeContainerDetailView
            isDisabled={!isEditMode}
            value={knowledgeContainer!}
            ref={viewRef}
          ></KnowledgeContainerDetailView>
        )
      }
    </DefaultPageLayout>
  )
}

export default KnowledgeContainerDetailPage
