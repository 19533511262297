import React, { useEffect, useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import {
  Badge,
  Button,
  Checkbox,
  Form,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
  Textarea,
  Tooltip,
} from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { iButtonHandles, UserManagementState } from '@/interfaces/iUserManagement.ts'
import { iShared, iShareLink, SharableEntityType } from '@/interfaces/iShared.ts'
import { iUser } from '@/interfaces/iUser.ts'
import useTeamStore from '@/states/teamStore.ts'
import userService from '@/services/UserService.ts'
import { iTeam } from '@/interfaces/iTeam.ts'
import { ShareType } from '@/interfaces/iItem.ts'
import TeamService from '@/services/teamService.ts'
import AssistantSelection from '../assistants/AssistantSelection.tsx'
import { hasFeatureFlag } from '@/utils/featureFlags.ts'
import MembersSelection from '../members/MemberSelection.tsx'
import InvitationLinkSettings from '../invitation/InvitationLinkSettings.tsx'

function UserManagementCreateEdit({
  closeFunction,
  submitFunction,
  setLoading,
  mode,
  team,
}: iButtonHandles) {
  const [assistantIds, setAssistantIds] = useState<string[]>([])
  const [toolIds, setToolIds] = useState<string[]>([])
  const [knowledgeContainerIds, setKnowledgeContainerIds] = useState<string[]>([])
  const [members, setMembers] = useState<iShared[]>([])
  const [shareLinks, setShareLinks] = useState<iShareLink[]>([])
  const [allUsers, setAllUsers] = useState<iUser[]>([])
  const [alert, setAlert] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLimited, setIsLimited] = useState<boolean>(
    team?.isAssistantAccessLimited ?? false,
  )
  const { createTeam } = useTeamStore(
    (state) => ({ createTeam: state.createTeam }),
    shallow,
  )

  // constructor, load all users
  useEffect(() => {
    // if the edit mode is active, set the values of the team
    if (mode === UserManagementState.Edit && team) {
      setMembers(team.shared)
      setAssistantIds(team.resourceRestrictions.assistantIds ?? [])
      setToolIds(team.resourceRestrictions.toolIds ?? [])
      setKnowledgeContainerIds(team.resourceRestrictions.knowledgeContainerIds ?? [])
    }

    userService.searchUsers().then((response) => {
      setAllUsers(response.data)
    })
  }, [])

  // if the edit mode is active, set the members to the shared members of the team
  // as soon as the allUsers are loaded
  useEffect(() => {
    if (mode === UserManagementState.Edit && team) {
      setMembers(team.shared || [])
      setShareLinks(team.shareLinks || [])
    }
  }, [allUsers])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // important to avoid page refresh
    e.preventDefault()
    const data: iTeam = Object.fromEntries(
      new FormData(e.currentTarget),
    ) as unknown as iTeam
    // check if limited access is set but no settings are selected
    if (data.isAssistantAccessLimited && assistantIds.length === 0) {
      setAlert(true)
      return
    }

    setLoading()
    setIsLoading(true)
    // convert members to shared
    const tmpTeam: iTeam = {
      ...(team ?? {}),
      name: data.name,
      costCenter: data.costCenter,
      spent: team?.spent ?? 0,
      budget: data.budget,
      description: data.description,
      shared: members,
      isAssistantAccessLimited: data.isAssistantAccessLimited,
      shareLinks: shareLinks,
      resourceRestrictions: {
        modelIds: null,
        assistantIds: assistantIds.length ? assistantIds : null,
        toolIds: toolIds.length ? toolIds : null,
        knowledgeContainerIds: knowledgeContainerIds.length
          ? knowledgeContainerIds
          : null,
      },
      apiAccess: data.apiAccess,
      sharedType: ShareType.Limited,
    }
    if (mode === UserManagementState.Edit && team) {
      TeamService.updateTeam(tmpTeam).then(() => {
        submitFunction()
      })
      return
    } else {
      createTeam(tmpTeam).then(() => {
        submitFunction()
      })
    }
  }

  const handleCancel = () => {
    closeFunction()
  }

  const validateMonthlyBudget = (budget: number) => {
    if (!budget) return 'Monthly Budget is required'
    if (budget < 1) return 'Minimum of 1 € is required'
    return null
  }
  const validateCostCenter = (costCenter: string) => {
    if (!costCenter) return 'Cost Center is required'
    if (!/^[0-9]*$/.test(costCenter)) return 'Cost centre may only contain figures'
    return null
  }

  return (
    <Form onSubmit={onSubmit}>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-4'}>
          {mode === UserManagementState.Create ? 'Create' : 'Edit'} Team
        </h1>
      </ModalHeader>
      <ModalBody>
        <div className={'min-w-fit w-[32rem]'}>
          <div className="flex flex-col ">
            <Input
              type="text"
              label="Team Name"
              variant="flat"
              validate={(name) => (name ? null : 'Team name is required')}
              isRequired
              defaultValue={team?.name}
              name="name"
              className="mb-4"
            />

            <Textarea
              label="Description"
              variant="flat"
              name="description"
              defaultValue={team?.description}
              rows={2}
              className="mb-4"
            />

            <Input
              type="text"
              label="Cost Center"
              name="costCenter"
              isDisabled={!!team?.id}
              variant="flat"
              defaultValue={team?.costCenter}
              validate={validateCostCenter}
              isRequired
              className="mb-4"
            />

            <Input
              type="number"
              label="Monthly Budget"
              variant="flat"
              name="budget"
              defaultValue={(team?.budget ?? 0).toString()}
              isRequired
              validate={(value) => validateMonthlyBudget(value as unknown as number)}
              className="mb-4"
            />
            <Checkbox
              checked={team?.apiAccess}
              onValueChange={(v) => setIsLimited(v)}
              className="mb-2 flex"
            >
              <Tooltip
                content={
                  'Team members can access the GAIA API via their own PAT (Personal Access Token).'
                }
                placement={'right'}
                className={'max-w-96'}
              >
                <div className="flex place-content-center cursor-pointer">
                  <span className="label-text">Enable API Access</span>
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </div>
              </Tooltip>
            </Checkbox>

            <Checkbox
              checked={team?.isAssistantAccessLimited ?? false}
              name="isAssistantAccessLimited"
              className="mb-2 flex"
            >
              <Tooltip
                content={
                  'Control all resources that are to be released for your team. If no special resources, e.g. assistants, are selected, your team can access all assistants.'
                }
                className={'max-w-96'}
                placement={'right'}
              >
                <div className="flex place-content-center cursor-pointer">
                  <span className="label-text">Limit access to fixed resources</span>
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </div>
              </Tooltip>
            </Checkbox>
            {isLimited && (
              <>
                <Tabs variant="underlined" aria-label="limited resources">
                  <Tab key="photos" title="Assistant">
                    <AssistantSelection
                      selectedItemId={assistantIds}
                      setSelectedItemId={setAssistantIds}
                    />
                  </Tab>
                  <Tab key="tools" title="Tools" isDisabled={hasFeatureFlag()}></Tab>
                  <Tab
                    key="knowledgeContainer"
                    title="KnowledgeContainer"
                    isDisabled={!hasFeatureFlag()}
                  ></Tab>
                </Tabs>
              </>
            )}
            <Tabs variant="underlined" aria-label="members">
              <Tab key="members" title="Current Members">
                <MembersSelection
                  shared={members}
                  onSelectionChange={setMembers}
                  ownerId={team?.ownerId}
                  roles={team?.roles}
                  writeOnly={team?.sharedType === ShareType.Public}
                />
              </Tab>
              <Tab
                key="links"
                title={
                  <Badge
                    isInvisible={!shareLinks.length}
                    isOneChar
                    content={shareLinks.length}
                    shape="rectangle"
                    classNames={{
                      badge: '-right-2',
                    }}
                  >
                    Invitation Links
                  </Badge>
                }
              >
                <InvitationLinkSettings
                  links={shareLinks}
                  resourceType={SharableEntityType.Team}
                  resourceId={team!.id!}
                  roles={team?.roles}
                  writeOnly={team?.sharedType === ShareType.Public}
                  onChange={(links) => setShareLinks(links)}
                ></InvitationLinkSettings>
              </Tab>
              <Tab key="requests" title="Requests" isDisabled={true}></Tab>
            </Tabs>
          </div>

          {alert && isLimited && assistantIds.length === 0 && (
            <div className="alert alert-warning mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>
                If limited access is selected, at least one setting must also be
                selected.
              </span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button isDisabled={isLoading} variant={'light'} onPress={handleCancel}>
          Cancel
        </Button>
        <Button isLoading={isLoading} isDisabled={isLoading} type="submit">
          Save
        </Button>
      </ModalFooter>
    </Form>
  )
}

export default UserManagementCreateEdit
