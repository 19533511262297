import React, { useState, useCallback } from 'react'
import { Autocomplete, AutocompleteItem, Spinner } from '@nextui-org/react'
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll'
import { useDebouncedCallback } from 'use-debounce'
import ItemSelectionItem from '@components/tools/ItemSelectionItem.tsx'
import IconFetcher, { EntityType } from '@components/basic/icon/IconFetcher.tsx'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'

type SingleItemSelectionProps<T extends iAssistant | iKnowledgeContainer | iTool> = {
  selectedItem: T | null
  label: string
  placeholder: string
  onChange: (selectedItem: T) => Promise<void>
  isDisabled?: boolean
  loadFunc: ({
    limit,
    offset,
    searchValue,
  }: {
    limit: number
    offset: number
    searchValue?: string
  }) => Promise<{ data: T[]; total: number }>
}

const SingleItemSelection = <T extends iAssistant | iKnowledgeContainer | iTool>({
  selectedItem,
  onChange,
  loadFunc,
  label,
  placeholder,
  isDisabled,
}: SingleItemSelectionProps<T>) => {
  const [search, setSearch] = useState('')
  const [allItems, setAllItems] = useState<T[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false)
  const [showAutocomplete, setShowAutocomplete] = useState(false)

  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: isAutocompleteActive,
    shouldUseLoader: false,
    onLoadMore: () => {
      loadItems()
    },
  })

  const loadItems = async (options?: { clear?: boolean; searchValue?: string }) => {
    setLoading(true)
    let tmpOffset = offset
    let tmpAllItems = allItems
    if (options?.clear) {
      tmpOffset = 0
      tmpAllItems = []
    }
    return loadFunc({
      limit: 5,
      offset: tmpOffset,
      searchValue: options?.searchValue,
    })
      .then((response) => {
        const newItems = [...tmpAllItems, ...response.data]
        setAllItems(newItems)
        setOffset(tmpOffset + response.data.length)
        setHasMore(newItems.length < response.total)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    if (isAutocompleteActive && !allItems.length && !loading)
      loadItems({ clear: true })
  }, [isAutocompleteActive])

  const onSelectionChange = useCallback(
    async (index: number) => {
      if (index < 0) return
      const selectedItem = allItems[index]
      onChange(selectedItem)
      setShowAutocomplete(false)
    },
    [allItems, onChange],
  )

  function getEntityType(item: T): EntityType {
    if ('title' in item) return 'Assistant'
    if ('functionName' in item) return 'Tool'
    return 'KnowledgeContainer'
  }

  function getDisplayName(item: iAssistant | iKnowledgeContainer | iTool): string {
    if ('title' in item) return item.title
    return item.name!
  }

  const debounced = useDebouncedCallback((value) => {
    loadItems({ clear: true, searchValue: value })
  }, 800)

  return (
    <div className="flex flex-col w-full min-w-fit mb-4">
      {selectedItem && !showAutocomplete ? (
        <div
          className="flex items-center space-x-2 p-2 rounded cursor-pointer"
          onClick={() => !isDisabled && setShowAutocomplete(true)}
        >
          <IconFetcher
            entityId={selectedItem.id!}
            entityType={getEntityType(selectedItem)}
            imageId={selectedItem.image}
          />
          <div className="flex-grow">
            <div className="font-semibold">{getDisplayName(selectedItem)}</div>
            <div className="text-sm text-gray-500">
              {selectedItem.ownerName || selectedItem.ownerEmail}
            </div>
          </div>
        </div>
      ) : (
        <Autocomplete
          isDisabled={isDisabled}
          variant="flat"
          isLoading={loading}
          items={allItems}
          label={label}
          placeholder={placeholder}
          scrollRef={scrollerRef}
          onOpenChange={(isOpen) => {
            setIsAutocompleteActive(isOpen)
            setShowAutocomplete(isOpen)
          }}
          selectedKey={null}
          inputValue={search}
          listboxProps={{
            emptyContent: <>{!loading && 'No results found'}</>,
            bottomContent: (
              <div className="flex justify-center min-h-9">
                {loading && <Spinner />}
              </div>
            ),
          }}
          onSelectionChange={(t) => {
            setSearch('')
            const itemIndex = allItems.findIndex((item) => item.id! === t)
            onSelectionChange(itemIndex)
          }}
          onInputChange={(value) => {
            debounced(value)
            setSearch(value)
          }}
        >
          {allItems.map((item) => (
            <AutocompleteItem key={item.id!} textValue={getDisplayName(item)}>
              <ItemSelectionItem
                entityId={item.id!}
                entityType={getEntityType(item)}
                name={getDisplayName(item)}
                isDisabled={isDisabled}
                owner={item.ownerName || item.ownerEmail}
                description={item.description}
                image={item.image}
              />
            </AutocompleteItem>
          ))}
        </Autocomplete>
      )}
    </div>
  )
}

export default React.memo(SingleItemSelection)
