import React, {
  forwardRef,
  Key,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ArrowUturnLeftIcon, FolderIcon } from '@heroicons/react/24/outline'

import './knowledgeContainerStyle.scss'

import { FileIcon } from 'react-file-icon'
import { shallow } from 'zustand/shallow'
import useSignalRStore from '../../states/signalRState.ts'
import {
  DocumentUploadEvents,
  ContainerType,
  iDocumentDictionary,
  iDocumentTree,
  NewStatus,
  SavingStatus,
} from '@/interfaces/iBlobUpload.ts'
import { iProgressMessage } from '@/interfaces/iProgressMessage.ts'
import KnowledgeContainerService from '../../services/knowledgeContainerService.ts'
import {
  CustomFile,
  CustomFileSystemEntry,
  processDirectory,
} from '../files/ProcessDirectory.tsx'
import FilePicker from '../files/FilePicker.tsx'
import PromiseQueue from '../../utils/promiseQueue.ts'
import { BLACKLISTEDEXTENSIONS } from '@/constants/blacklistedFileExtensions.ts'
import { Button, Skeleton, Alert } from '@nextui-org/react'
import { ICON_DELETE } from '@/constants/icons.tsx'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'
import { PiUpload } from 'react-icons/pi'
import DropZone from '@components/basic/dropZone/DropZone.tsx'

// Liste der unterstützten Dateien

export type KnowledgeContainerFileManagerHandle = {
  saveAndUpload: () => Promise<void>
}

type KnowledgeContainerFileManagerProps = {
  knowledgeContainerId: string
  isDisabled: boolean
}

const KnowledgeContainerFileManager: React.ForwardRefRenderFunction<
  KnowledgeContainerFileManagerHandle,
  KnowledgeContainerFileManagerProps
> = ({ knowledgeContainerId, isDisabled }, ref) => {
  const connection = useSignalRStore((state) => state.connection, shallow)
  const [structure, setStructure] = useState<iDocumentTree>()
  const [fileDictionary, setFileDictionary] = useState<iDocumentDictionary>({})
  const [selectToBeDeletedFiles, setSelectToBeDeletedFiles] = useState<
    iDocumentTree[]
  >([])
  const [isDragging, setIsDragging] = useState(false)
  const [isDraggingTree, setIsDraggingTree] = useState('')
  const [lineHovered, setLineHovered] = useState('')
  const [saving, setSaving] = useState(false)
  const deletingListRef = useRef<iDocumentTree[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [uploadingStatus, setUploadingStatus] = useState(SavingStatus.None)
  const [uploadingProgress, setUploadingProgress] = useState(0)
  const [uploadingMax, setUploadingMax] = useState(0)
  const [uploadingProcessedFile, setUploadingProcessedFile] = useState('')

  const [analyzingStatus, setAnalyzingStatus] = useState(SavingStatus.None)
  const [analyzingProgress, setAnalyzingProgress] = useState(0)
  const [analyzingMax, setAnalyzingMax] = useState(0)
  const [analyzingProcessedFile, setAnalyzingProcessedFile] = useState('')

  const [indexingStatus, setIndexingStatus] = useState(SavingStatus.None)
  const [indexingProgress, setIndexingProgress] = useState(0)
  const [indexingMax, setIndexingMax] = useState(0)
  const [indexingProcessedFile, setIndexingProcessedFile] = useState('')

  const [deletingStatus, setDeletingStatus] = useState(SavingStatus.None)
  const [deletingProgress, setDeletingProgress] = useState(0)
  const [deletingMax, setDeletingMax] = useState(0)

  useEffect(() => {
    getBlobTree()
  }, [])

  useEffect(() => {
    if (connection) {
      connection.on(
        DocumentUploadEvents.uploading,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setUploadingProgress(progressMessage.progress)
              setUploadingMax(progressMessage.max)
              setUploadingStatus(SavingStatus.Uploading)
              break
            case 'uploading':
              setUploadingProgress(progressMessage.progress)
              setUploadingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setUploadingStatus(SavingStatus.Completed)
              break
          }
        },
      )
      connection.on(
        DocumentUploadEvents.indexing,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setIndexingProgress(progressMessage.progress)
              setIndexingMax(progressMessage.max)
              setIndexingStatus(SavingStatus.Indexing)
              break
            case 'indexing':
              setIndexingProgress(progressMessage.progress)
              setIndexingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setIndexingStatus(SavingStatus.Completed)
              break
          }
        },
      )
      connection.on(
        DocumentUploadEvents.analyzing,
        (progressMessage: iProgressMessage) => {
          switch (progressMessage.status) {
            case 'start':
              setAnalyzingProgress(progressMessage.progress)
              setAnalyzingMax(progressMessage.max)
              setAnalyzingStatus(SavingStatus.Analyzing)
              break
            case 'analyzing':
              setAnalyzingProgress(progressMessage.progress)
              setAnalyzingProcessedFile(progressMessage.message)
              break
            case 'completed':
              setAnalyzingStatus(SavingStatus.Completed)
              break
          }
        },
      )
    }
  }, [connection])

  const getBlobTree = () => {
    setLoading(true)
    KnowledgeContainerService.getKnowledgeContainerDocuments(knowledgeContainerId, {
      format: 'tree',
    }).then((response) => {
      setStructure({ ...response })
      const files: iDocumentDictionary = {}
      flatStructure(response.documents, files)
      setFileDictionary(files)
      setLoading(false)
    })
  }

  const flatStructure = (structure: iDocumentTree[], files: iDocumentDictionary) => {
    for (const item of structure) {
      if (item.nodeType === 1) {
        files[item.path] = item
      } else if (item.documents.length > 0) {
        flatStructure(item.documents, files)
      }
    }
  }

  const hasChanges = () => {
    return (
      Object.values(fileDictionary).some((value) => {
        return (
          value._newStatus === NewStatus.New ||
          value._newStatus === NewStatus.Updated ||
          value._newStatus === NewStatus.Deleted
        )
      }) || selectToBeDeletedFiles.length > 0
    )
  }

  const cleanStructure = (structure: iDocumentTree) => {
    if (structure.path.endsWith('/') && structure.path.length > 1) {
      structure.path = structure.path.substring(0, structure.path.length - 1)
    }
    structure.documents.forEach((child) => cleanStructure(child))
  }

  useImperativeHandle(ref, () => ({
    saveAndUpload,
  }))

  const saveAndUpload = async () => {
    setSaving(true)

    PromiseQueue.getInstance().onCompletion(() => {
      finishSaveProcess()
    })

    const filesToUpload = Object.values(fileDictionary)
      .filter((value) => {
        return (
          value._newStatus === NewStatus.New ||
          value._newStatus === NewStatus.Updated
        )
      })
      .map((blobList) => blobList._file!)
      .filter(Boolean) as File[]

    const selectToBeDeletedFilesFiltered = selectToBeDeletedFiles.filter(
      (file) => file.nodeType === ContainerType.File,
    )

    if (filesToUpload.length > 0) {
      setUploadingMax(Object.keys(filesToUpload).length)
      setUploadingProgress(0)
      setUploadingStatus(SavingStatus.Uploading)

      setIndexingMax(Object.keys(filesToUpload).length)
      setIndexingProgress(0)
      setIndexingStatus(SavingStatus.Indexing)

      console.debug('Uploading files: ', filesToUpload)

      PromiseQueue.getInstance().enqueueList(
        KnowledgeContainerService.uploadKnowledgeContainerDocuments(
          knowledgeContainerId,
          filesToUpload,
        ),
      )
    }

    if (selectToBeDeletedFilesFiltered.length > 0) {
      setDeletingMax(selectToBeDeletedFilesFiltered.length)
      setDeletingProgress(0)
      setDeletingStatus(SavingStatus.Deleting)
      selectToBeDeletedFilesFiltered.forEach((file) => {
        PromiseQueue.getInstance().enqueue(() =>
          deleteProcess(knowledgeContainerId, file.path),
        )
      })
    }
  }

  const finishSaveProcess = () => {
    setUploadingStatus(SavingStatus.None)
    setIndexingStatus(SavingStatus.None)
    setAnalyzingStatus(SavingStatus.None)
    setDeletingStatus(SavingStatus.None)

    setDeletingMax(0)
    setDeletingProgress(0)
    setUploadingMax(0)
    setUploadingProgress(0)
    setIndexingMax(0)
    setIndexingProgress(0)
    setAnalyzingMax(0)
    setAnalyzingProgress(0)

    setSaving(false)
    getBlobTree()
    setSelectToBeDeletedFiles([])
  }

  useEffect(() => {
    if (deletingMax !== 0 && deletingProgress === deletingMax) {
      setDeletingStatus(SavingStatus.Completed)
    }
  }, [deletingProgress])

  useEffect(() => {
    if (uploadingMax !== 0 && uploadingProgress === uploadingMax) {
      setUploadingStatus(SavingStatus.Completed)
    }
  }, [uploadingProgress])

  useEffect(() => {
    if (analyzingMax !== 0 && analyzingProgress === analyzingMax) {
      setAnalyzingStatus(SavingStatus.Completed)
    }
  }, [analyzingProgress])

  useEffect(() => {
    if (indexingMax !== 0 && indexingProgress === indexingMax) {
      setIndexingStatus(SavingStatus.Completed)
    }
  }, [indexingProgress])

  const deleteProcess = async (knowledgeContainerId: string, node: string) => {
    await KnowledgeContainerService.deleteKnowledgeContainerDocument(
      knowledgeContainerId,
      node,
    )
    setDeletingProgress((prevProgress) => prevProgress + 1)
  }

  const markAsDeleted = (blobList: iDocumentTree) => {
    blobList._newStatus = NewStatus.Deleted
    if (blobList.documents)
      blobList.documents.forEach((child) => markAsDeleted(child))

    if (!selectToBeDeletedFiles.includes(blobList)) {
      deletingListRef.current.push(blobList)
      setSelectToBeDeletedFiles(deletingListRef.current)
    }

    setStructure({ ...structure! })
  }

  const onDragOver = (
    event: React.DragEvent<HTMLElement>,
    blobList: iDocumentTree | undefined = undefined,
  ) => {
    if (isDisabled) return
    event.preventDefault()
    if (blobList) {
      setIsDraggingTree(blobList.path)
      return
    }
    if (!isDragging) {
      setIsDragging(true)
    }
  }

  const onDragLeave = (
    event: React.DragEvent<HTMLElement>,
    blobList: iDocumentTree | undefined = undefined,
  ) => {
    if (isDisabled) return
    if (blobList) {
      setIsDraggingTree('')
      return
    }
    setIsDragging(false)
  }

  const onDrop = async (items: DataTransferItemList, blobList: iDocumentTree) => {
    if (isDisabled) return
    setIsDragging(false)
    setIsDraggingTree('')

    const processedFiles: File[] = []

    const queue: Promise<File | void>[] = []
    for (const item of items) {
      const entry = item.webkitGetAsEntry()! as CustomFileSystemEntry
      const path = blobList.path
      if (entry.isDirectory) {
        queue.push(
          processDirectory(entry, path).then((files) => {
            processedFiles.push(...files)
          }),
        )
      } else if (entry.isFile) {
        const extension = entry.name.split('.').pop()
        if (extension && BLACKLISTEDEXTENSIONS.includes(extension!.toLowerCase())) {
          console.warn('Datei ist nicht erlaubt: ', entry.name)
          continue
        }
        queue.push(
          new Promise<File>((resolve) => entry.file(resolve)).then((file) => {
            const cfile = new CustomFile(
              [file],
              entry.name,
              { type: file.type },
              concatPaths(path ?? '', entry.name),
            )
            processedFiles.push(cfile as File)
          }),
        )
      }
    }
    await Promise.all(queue)

    const fileList = Object.setPrototypeOf(processedFiles, FileList.prototype)
    handleFilesSelect(fileList)
  }

  const removeLeadingSlash = (path: string) => {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    return path
  }

  const concatPaths = (string1: string, string2: string) => {
    if (string1 === '' || string1 === '/') {
      if (string2.startsWith('/')) {
        string2 = string2.substring(1)
      }
      return string2
    }

    if (string1.endsWith('/')) {
      string1 = string1.slice(0, -1)
    }

    if (string2.startsWith('/')) {
      string2 = string2.slice(1)
    }

    return string1 + '/' + string2
  }

  const handleFilesSelect = (files: FileList) => {
    if (!structure) {
      return
    }
    const filesArray = Array.from(files)

    const newStructure = { ...structure }
    filesArray.forEach((file) => {
      const existingFile =
        fileDictionary[
          file.webkitRelativePath.length > 0
            ? removeLeadingSlash(file.webkitRelativePath)
            : file.name
        ]
      if (existingFile) {
        existingFile._file = file
        existingFile._newStatus = NewStatus.Updated
        existingFile.lastModified = new Date().toISOString()
        return
      }

      const path =
        file.webkitRelativePath.length > 0 ? file.webkitRelativePath : file.name
      const pathParts = path.split('/')
      pathParts.pop()
      const fileName = file.name
      let currentFolderChildren = newStructure.documents
      let currentFolder = newStructure
      pathParts.forEach((part) => {
        if (part === '') return
        let existingNode = currentFolderChildren.find(
          (node) => concatPaths(currentFolder.path ?? '', part) === node.path,
        )
        if (!existingNode) {
          existingNode = {
            path: concatPaths(currentFolder.path ?? '', part),
            nodeType: 0,
            lastModified: '',
            documents: [],
            _showChildren: true,
            _file: null,
            _newStatus: NewStatus.New,
          }
          currentFolderChildren.push(existingNode)
        }
        currentFolderChildren = existingNode?.documents
        currentFolder = existingNode
      })

      const newObject: iDocumentTree = {
        path: path,
        name: fileName!,
        nodeType: 1,
        lastModified: '',
        documents: [],
        _showChildren: false,
        _file: file,
        _newStatus: NewStatus.New,
      }
      currentFolderChildren.push(newObject)
      fileDictionary[path] = newObject
    })
    setFileDictionary(fileDictionary)
    setStructure({ ...newStructure })
  }

  const getNewStatus = (node: iDocumentTree) => {
    switch (node._newStatus) {
      case NewStatus.New:
        return 'neu'
      case NewStatus.Deleted:
        return 'gelöscht'
      case NewStatus.Updated:
        return 'aktualisiert'
      default:
        return ''
    }
  }

  const getFileName = (nodePath: string) => {
    const parts = nodePath.split('/')
    return parts[parts.length - 1]
  }

  const getFolderName = (nodePath: string) => {
    if (!nodePath) return 'Stammverzeichnis'
    if (nodePath.startsWith('/')) {
      nodePath = nodePath.substring(1)
    }
    if (nodePath.endsWith('/')) {
      nodePath = nodePath.substring(0, nodePath.length - 1)
    }
    const parts = nodePath.split('/')

    return parts[parts.length - 1]
  }

  const handleLineMouseEnter = (node: iDocumentTree) => {
    setLineHovered(node.path)
  }
  const handleLineMouseLeave = (node: iDocumentTree) => {
    if (lineHovered === node.path) setLineHovered('')
  }

  const reverseAction = (node: iDocumentTree) => {
    if (node.documents) node.documents.forEach((child) => reverseAction(child))
    if (node._newStatus === NewStatus.New) {
      delete fileDictionary[node.path]
      setFileDictionary(fileDictionary)
      node._newStatus = NewStatus.None
      node._file = null
    }
    if (node._newStatus === NewStatus.Deleted) {
      node._newStatus = NewStatus.Existing
      setFileDictionary(fileDictionary)
      selectToBeDeletedFiles.splice(selectToBeDeletedFiles.indexOf(node), 1)
      setSelectToBeDeletedFiles([...selectToBeDeletedFiles])
    }
    if (node._newStatus === NewStatus.Updated) {
      node._newStatus = NewStatus.Existing
      node._file = null
      setFileDictionary(fileDictionary)
    }

    setStructure({ ...structure! })
  }

  const renderNode = (node: iDocumentTree) => {
    const isFolder = node.nodeType === ContainerType.Folder
    const key: Key = node.path
    if (node._newStatus === NewStatus.None) return null

    return (
      <React.Fragment key={key}>
        {isFolder ? (
          <li>
            <details open>
              <summary
                onMouseEnter={() => handleLineMouseEnter(node)}
                onMouseLeave={() => handleLineMouseLeave(node)}
                className={`${
                  isDraggingTree === node.path ? 'text-secondary bg-white' : ''
                }`}
                onDragOver={(event) => onDragOver(event, node)}
                onDragLeave={(event) => onDragLeave(event, node)}
                onDrop={(event) => onDrop(event.dataTransfer.items, node)}
              >
                <span className={'flex justify-between col-start-1 col-end-6'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FolderIcon className={'h-4 w-4'} />
                    <span
                      className={`ml-2 ${
                        node._newStatus === NewStatus.Deleted
                          ? 'line-through text-rose-600'
                          : ''
                      } 
                        ${
                          node._newStatus === NewStatus.New
                            ? 'text-lime-600 font-semibold'
                            : ''
                        }
                        ${
                          node._newStatus === NewStatus.Updated
                            ? 'text-amber-500 font-semibold'
                            : ''
                        }
                        `}
                    >
                      {getFolderName(node.path)}
                    </span>
                    <span className={'text-xs italic ml-2'}>
                      {getNewStatus(node)}
                    </span>
                  </div>
                  {lineHovered === node.path && node.path !== '/' && (
                    <div className={'height16'}>
                      {node._newStatus >= NewStatus.New && (
                        <Button
                          variant="light"
                          isIconOnly
                          size="sm"
                          className="marginMinus mr-2 height16"
                          onPress={() => reverseAction(node)}
                        >
                          <ArrowUturnLeftIcon className="h-4 w-4" />
                        </Button>
                      )}
                      {!isDisabled && node._newStatus !== NewStatus.New && (
                        <Button
                          variant="light"
                          isIconOnly
                          size="sm"
                          className="mr-2 height16"
                          onPress={() => markAsDeleted(node)}
                        >
                          <ICON_DELETE />
                        </Button>
                      )}
                    </div>
                  )}
                </span>
              </summary>
              <ul>{node.documents.map((child) => renderNode(child))}</ul>
            </details>
          </li>
        ) : (
          <div>
            <li
              onMouseEnter={() => handleLineMouseEnter(node)}
              onMouseLeave={() => handleLineMouseLeave(node)}
            >
              <span className={'flex justify-between'}>
                <a style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={'w-4 h-4'}>
                    <FileIcon extension={getFileName(node.path).split('.').pop()} />
                  </div>
                  <span
                    className={`ml-2 ${
                      node._newStatus === NewStatus.Deleted
                        ? 'line-through text-rose-600'
                        : ''
                    } 
                      ${
                        node._newStatus === NewStatus.New
                          ? 'text-lime-600 font-semibold'
                          : ''
                      }
                      ${
                        node._newStatus === NewStatus.Updated
                          ? 'text-primary font-semibold'
                          : ''
                      }
                      `}
                  >
                    {getFileName(node.path)}
                  </span>
                  <span className={'text-xs italic ml-2'}>{getNewStatus(node)}</span>
                </a>
                {lineHovered === node.path && (
                  <div className={'mr-4 height16'}>
                    {node._newStatus >= NewStatus.New && (
                      <Button
                        variant="light"
                        isIconOnly
                        size="sm"
                        className="marginMinus mr-2 height16"
                        onPress={() => reverseAction(node)}
                      >
                        <ArrowUturnLeftIcon className="h-4 w-4" />
                      </Button>
                    )}
                    {!isDisabled &&
                      node._newStatus !== NewStatus.New &&
                      node._newStatus !== NewStatus.Deleted && (
                        <Button
                          variant="light"
                          isIconOnly
                          size="sm"
                          className="marginMinus mr-2 height16"
                          onPress={() => markAsDeleted(node)}
                        >
                          <ICON_DELETE />
                        </Button>
                      )}
                  </div>
                )}
              </span>
            </li>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      <SectionCard title="File Manager" description="">
        <div>
          Use your files and upload them to your BLOB storage. They will be indexed
          and can be used as context.
        </div>
        <div className="mb-4">
          You can drag and drop them into the folder structure.
        </div>
        <div className={'mb-4'}>
          <Alert
            color="information"
            variant="bordered"
            title="XLSX files"
            description="Please refrain from using XLSX files, as they lead to poor results.
"
          />
        </div>

        <DropZone
          onDrop={(files) => onDrop(files, structure!)}
          handleFilesSelect={handleFilesSelect}
        ></DropZone>

        <Skeleton isLoaded={!loading} className="mt-4 rounded-medium h-80 w-full">
          {structure && structure.documents.length > 0 && (
            <>
              <div className="mt-3">
                <div className="overflow-auto no-scrollbar">
                  <ul className="menu menu-xs bg-base-200 rounded-lg w-full max-h-80 overflow-auto flow-root">
                    {renderNode(structure)}
                  </ul>
                </div>
              </div>
            </>
          )}
        </Skeleton>
        <div className={'text-center mt-2'}>
          {uploadingStatus === SavingStatus.Uploading && (
            <>
              <p>Dateien werden hochgeladen</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={uploadingProgress}
                max={uploadingMax}
              />
            </>
          )}

          {uploadingStatus === SavingStatus.Completed && (
            <p>Hochladen der Dateien abgeschlossen</p>
          )}

          {analyzingStatus === SavingStatus.Analyzing && (
            <>
              <p>Dateien werden indexiert</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={analyzingProgress}
                max={analyzingMax}
              />
            </>
          )}

          {indexingStatus === SavingStatus.Completed && (
            <p>Indizierung der Dateien abgeschlossen</p>
          )}

          {deletingStatus === SavingStatus.Deleting && (
            <>
              <p>Dateien werden gelöscht</p>
              <progress
                className={`progress w-full progress-primary'}`}
                value={deletingProgress}
                max={deletingMax}
              />
            </>
          )}

          {deletingStatus === SavingStatus.Completed && (
            <p>Löschen der Dateien abgeschlossen</p>
          )}

          {(uploadingStatus === SavingStatus.Uploading ||
            indexingStatus === SavingStatus.Indexing ||
            deletingStatus === SavingStatus.Deleting) && (
            <p>Sie können dieses Fenster schließen, aber nicht den Tab.</p>
          )}
        </div>
      </SectionCard>
    </>
  )
}

export default forwardRef(KnowledgeContainerFileManager)
