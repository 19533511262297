import React from 'react'
import GaiaLogo from '../components/basic/logo/GaiaLogo.tsx'
import { Button } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { TEST_PAGE_NOT_FOUND } from '../constants/testingIds.ts'

function NotFoundPage() {
  return (
    <div
      data-testid={TEST_PAGE_NOT_FOUND}
      className={'fix flex-col flex justify-center items-center w-[100vw] h-[100vh]'}
    >
      <GaiaLogo className={'w-44 h-44'} />
      <h1 className={'gaia-fade-in font-bold text-7xl'}>GAIA</h1>
      <div className={'mt-5 text-5xl'}>404</div>
      <Link to="/" className={'mt-5'}>
        <Button>Go Home</Button>
      </Link>
    </div>
  )
}

export default NotFoundPage
