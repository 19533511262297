import ChatStore from '@states/chatStore.ts'
import { iMessage } from '@/interfaces/iMessage.ts'

export const upsertChatMessage = (message: iMessage): iMessage[] => {
  let messages: iMessage[] = ChatStore.getState().messages
  const messageToUpdate = messages.find(
    (m) => m.id === message.id && m.role === 'assistant',
  )
  if (!messageToUpdate) {
    //check if empty assistant message exists
    const emptyAssistantMessageIndex = messages.findIndex(
      (m) => !m.id && m.role === 'assistant',
    )
    if (emptyAssistantMessageIndex > -1) {
      messages[emptyAssistantMessageIndex] = message
      return messages
    }
    // append message
    messages.unshift(message)
  } else {
    // update message
    messages = messages!.map((m) => (m.id === message.id ? message : m))
  }

  return messages
}
