import React, { useMemo } from 'react'
import { CardHeader } from '@nextui-org/react'
import GaiaLogo from '../basic/logo/GaiaLogo.tsx'
import { getMarkdownTag } from '../../utils/markdownUtils.ts'

type FaqTitleProps = {
  markdown: string
}

function FaqTitle({ markdown }: FaqTitleProps) {
  const title = useMemo(() => {
    return getMarkdownTag('G-Title', markdown) as string
  }, [])

  const description = useMemo(() => {
    return getMarkdownTag('G-Description', markdown) as string
  }, [])

  return (
    <CardHeader className="flex gap-3">
      <GaiaLogo className="w-8 h-8" />
      <div className="flex flex-col">
        <p className="text-md">{title}</p>
        <p className="text-small text-default-500">{description}</p>
      </div>
    </CardHeader>
  )
}

export default FaqTitle
