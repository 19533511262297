import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import AssistantService from '@/services/assistantService.ts'

import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Tab,
  Tabs,
  Textarea,
} from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { iAssistant } from '@/interfaces/iAssistantTypes.ts'
import useAssistantStore from '@states/assistantStore.ts'
import { iShared, iShareLink, SharableEntityType } from '@/interfaces/iShared.ts'
import { ShareType } from '@/interfaces/iItem.ts'
import { deepCopy } from '@/utils/deepCopy.ts'
import InvitationLinkSettings from '../invitation/InvitationLinkSettings.tsx'
import AssistantModelSelection from './AssistantModelSelection.tsx'
import MembersSelection from '../members/MemberSelection.tsx'
import ItemSelection from '../tools/ItemSelection.tsx'
import ToolService from '@/services/toolService.ts'
import KnowledgeContainerService from '@/services/knowledgeContainerService.ts'
import AssistantModelSettings from '@components/session/AssistantModelSettings.tsx'
import IconManager from '@components/basic/icon/IconManager.tsx'
import AssistantAvatar from '@components/assistants/AssistantAvatar.tsx'
import { ICON_KNOWLEDGE_CONTAINER, ICON_TOOL } from '@/constants/icons.tsx'
import ShareWithSelection from '@components/basic/shareWithSelection/ShareWithSelection.tsx'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iTool } from '@/interfaces/iTool.ts'
import { toast } from 'react-toastify'
import FaqItem from '@components/faq/FaqItem.tsx'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import {
  FAQ_ID_KNOWLEDGE_CONTAINERS,
  FAQ_ID_LLMCAPS,
  FAQ_ID_SECRETS,
  FAQ_ID_TOOLS,
} from '@/constants/faqIDs.ts'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

const llmcapsFAQ = getFAQById(FAQ_ID_LLMCAPS)
const kcFAQ = getFAQById(FAQ_ID_KNOWLEDGE_CONTAINERS)
const toolsFAQ = getFAQById(FAQ_ID_TOOLS)

export type AssistantDetailViewHandle = {
  deletePreSetting: () => void
  savePreSetting: () => Promise<iAssistant | undefined>
}

type AssistantDetailViewProps = {
  value: iAssistant
  isDisabled?: boolean
}
const AssistantDetailView: ForwardRefRenderFunction<
  AssistantDetailViewHandle,
  AssistantDetailViewProps
> = ({ value, isDisabled }, ref) => {
  const { createAssistant } = useAssistantStore(
    (state) => ({
      createAssistant: state.createAssistant,
    }),
    shallow,
  )

  const [assistant, setAssistant] = useState<iAssistant>({ ...value })
  const [tools, setTools] = useState<iTool[]>([])
  const [knowledgeContainers, setKnowledgeContainers] = useState<
    iKnowledgeContainer[]
  >([])
  const [maxOutputTokens, setMaxOutputTokens] = useState<number>()
  const [shared, setShared] = useState<iShared[]>(value?.shared || [])
  const [shareLinks, setShareLinks] = useState<iShareLink[]>(value?.shareLinks || [])

  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])

  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    switch (value.sharedType) {
      case ShareType.Limited:
        setSharedLimited(shared)
        break
      case ShareType.Public:
        setSharedPublic(shared)
        break
    }
    AssistantService.getAssistantTools(assistant.id!).then((toolResponse) => {
      setTools(toolResponse)
    })

    AssistantService.getAssistantKnowledgeContainer(assistant.id!).then(
      (kcResponse) => {
        setKnowledgeContainers(kcResponse)
      },
    )
  }, [])

  const onSelectChange = (sharedType: ShareType) => {
    setAssistant({ ...assistant, sharedType })
    switch (sharedType) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }
  useImperativeHandle(ref, () => ({
    deletePreSetting,
    savePreSetting,
  }))

  const deletePreSetting = () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this assistant? This action cannot be undone.',
    )
    if (!confirmed) return
    AssistantService.deleteAssistant(assistant.id!).catch((error) => {
      console.error(error)
    })
  }

  const validateTitle = (title: string) => {
    if (!title) {
      return 'Please enter a name'
    }
    // //This name may only contain lowercase letters, numbers, and hyphens, and must begin with a letter or a number. Each hyphen must be preceded and followed by a non-hyphen character. The name must also be between 3 and 63 characters long.
    if (title.length < 3 || title.length > 63) {
      return 'Name must be between 3 and 63 characters long'
      return true
    }
    // Name may only contain lowercase letters, numbers, and hyphens, and empty spaces, and must begin with a letter or a number.
    if (!/^[a-zA-Z0-9][a-zA-Z0-9- ]*$/.test(title)) {
      return 'Name may only contain lowercase letters, numbers, and hyphens, and empty spaces, and must begin with a letter or a number.'
    }
    return null
  }

  const validateDescription = (description: string) => {
    if (!description) return 'Please enter a description'
  }

  const savePreSetting = async () => {
    const id = toast('Please wait...', { isLoading: true })
    try {
      setIsSaving(true)
      const preSettingCopy = deepCopy(assistant)

      if (!assistant.blueprint) preSettingCopy.id = undefined
      preSettingCopy.blueprint = true
      preSettingCopy.shared = shared
      preSettingCopy.shareLinks = shareLinks
      let newAssistant: iAssistant
      if (!assistant.id) {
        newAssistant = await createAssistant(preSettingCopy)
      } else {
        newAssistant = await AssistantService.updateAssistant(preSettingCopy)
      }
      setIsSaving(false)
      toast.update(id, {
        render: 'All is good',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      })
      return newAssistant
    } catch (error) {
      //TODO: error toast handling
      toast.update(id, {
        render: 'Something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      })
      console.error(error)
    }
  }

  return (
    <div>
      <div className="my-8 flex items-center justify-between">
        <AssistantAvatar
          assistantId={assistant.id!}
          displayName={assistant.title}
          description={assistant.ownerName ?? ''}
          image={assistant.image ?? ''}
        />
      </div>

      <Tabs variant="underlined">
        <Tab key={'general'} title="General">
          <div className={'flex flex-col gap-4'}>
            <div className="flex flex-row gap-4 flex-wrap">
              <SectionCard
                className="min-w-96 "
                title="Name & Description"
                description=""
              >
                <div className="flex flex-col gap-4">
                  <Input
                    isRequired
                    type="text"
                    label="Name"
                    className="input input-bordered w-full"
                    readOnly={isDisabled}
                    defaultValue={assistant.title}
                    onChange={(e) =>
                      setAssistant({ ...assistant, title: e.target.value })
                    }
                    validate={validateTitle}
                  />
                  <Textarea
                    isRequired
                    label="Description"
                    readOnly={isDisabled}
                    className="input input-bordered w-full"
                    defaultValue={assistant.description ?? ''}
                    validate={validateDescription}
                    onChange={(e) =>
                      setAssistant({ ...assistant, description: e.target.value })
                    }
                    rows={3}
                  />
                </div>
              </SectionCard>

              <IconManager
                entity={assistant}
                isDisabled={isDisabled}
                setEntity={setAssistant}
                entityType="Assistant"
              />
            </div>
            <div className="flex flex-row gap-4 flex-wrap">
              <SectionCard
                className=" min-w-72"
                icon={<ICON_TOOL></ICON_TOOL>}
                title="Active Tools"
                description=""
              >
                <ItemSelection
                  label="Add tools"
                  placeholder="Seek for tools"
                  loadFunc={ToolService.getTools}
                  isDisabled={isDisabled}
                  selectedItems={tools ?? []}
                  onChange={async (items) => {
                    console.log(items)
                    setAssistant({
                      ...assistant,
                      toolIds: items.map((t) => t.id!),
                      _tools: items as iTool[],
                    })
                    setTools(items as iTool[])
                  }}
                ></ItemSelection>
                {toolsFAQ && <FaqItem markdown={toolsFAQ?.markdown} />}
              </SectionCard>

              <SectionCard
                className=" min-w-72"
                icon={<ICON_KNOWLEDGE_CONTAINER></ICON_KNOWLEDGE_CONTAINER>}
                title="Active Knowledge-Containers"
                description=""
              >
                <ItemSelection
                  label="Add knowledge containers"
                  isDisabled={isDisabled}
                  placeholder="Seek for knowledge containers"
                  loadFunc={KnowledgeContainerService.getKnowledgeContainers}
                  selectedItems={knowledgeContainers ?? []}
                  onChange={async (items) => {
                    setAssistant({
                      ...assistant,
                      knowledgeContainerIds: items.map((t) => t.id!),
                      _knowledgeContainers: items as iKnowledgeContainer[],
                    })
                    setKnowledgeContainers(items as iKnowledgeContainer[])
                  }}
                ></ItemSelection>
                {kcFAQ && <FaqItem markdown={kcFAQ?.markdown} />}
              </SectionCard>
            </div>
          </div>
        </Tab>
        <Tab key={'model-settings'} title="Model Settings">
          <div className="flex flex-col gap-4">
            <SectionCard className=" min-w-72" title="Model Settings" description="">
              <div className="flex flex-col gap-4">
                <div>
                  <label className="label">
                    <span className="label-text">Model</span>
                  </label>
                  <AssistantModelSelection
                    isDisabled={isDisabled}
                    modelId={assistant.settings!.modelId!}
                    onChange={(e) => {
                      setMaxOutputTokens(e.maxOutputTokens)
                      assistant!.settings!.maxTokens =
                        assistant.settings!.maxTokens! > e.maxOutputTokens
                          ? e.maxOutputTokens
                          : assistant.settings?.maxTokens
                      setAssistant({
                        ...assistant,
                        settings: {
                          ...assistant.settings,
                          modelId: e.id,
                        },
                      })
                    }}
                  />
                </div>
                <div>
                  <label className="label">
                    <span className="label-text">Instructions</span>
                  </label>
                  <Textarea
                    readOnly={isDisabled}
                    className="textarea textarea-primary textarea-sm w-full"
                    id="systemPrompt"
                    placeholder="System Prompt"
                    value={assistant.instruction}
                    onChange={(e) =>
                      setAssistant({ ...assistant, instruction: e.target.value })
                    }
                  />
                </div>
                <AssistantModelSettings
                  isDisabled={isDisabled}
                  onMaxTokensChange={(maxTokens) =>
                    setAssistant({
                      ...assistant,
                      settings: { ...assistant.settings, maxTokens },
                    })
                  }
                  onStreamChange={(stream) =>
                    setAssistant({
                      ...assistant,
                      settings: { ...assistant.settings, stream },
                    })
                  }
                  onTemperatureChange={(temperature) =>
                    setAssistant({
                      ...assistant,
                      settings: { ...assistant.settings, temperature },
                    })
                  }
                  onTopPChange={(topP) =>
                    setAssistant({
                      ...assistant,
                      settings: { ...assistant.settings, topP },
                    })
                  }
                  onTransferLengthChange={(transferLength) =>
                    setAssistant({
                      ...assistant,
                      settings: { ...assistant.settings, transferLength },
                    })
                  }
                  settings={assistant.settings!}
                  maxOutputTokens={maxOutputTokens ?? 0}
                />
              </div>
            </SectionCard>

            {llmcapsFAQ && <FaqItem markdown={llmcapsFAQ?.markdown} />}
          </div>
        </Tab>

        <Tab key="publicity" title="Public">
          <div className="flex flex-col gap-4">
            <SectionCard
              className="min-w-96"
              title="Privacy Settings"
              description=""
            >
              <div className="flex flex-col gap-4">
                <ShareWithSelection
                  isDisabled={isDisabled}
                  selectedKey={assistant.sharedType!}
                  onChange={onSelectChange}
                />
              </div>
            </SectionCard>
            <>
              <MembersSelection
                isDisabled={isDisabled || assistant.sharedType === ShareType.None}
                shared={shared}
                ownerId={assistant.ownerId}
                onSelectionChange={setShared}
                roles={assistant.roles}
                writeOnly={assistant.sharedType === ShareType.Public}
              />
            </>
            <InvitationLinkSettings
              isDisabled={assistant.sharedType === ShareType.None}
              links={shareLinks}
              resourceType={SharableEntityType.Assistant}
              resourceId={assistant!.id!}
              roles={assistant.roles}
              writeOnly={false}
              readOnly={isDisabled || assistant.sharedType === ShareType.None}
              onChange={(links) => setShareLinks(links)}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
export default React.forwardRef(AssistantDetailView)
