import React, { useState } from 'react'
import { Button, Input, Tooltip } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import DialogSessionFolderModal from './DialogSessionFolderModal.tsx'
import { twMerge } from 'tailwind-merge'

type SessionsSearchProps = React.HTMLAttributes<HTMLDivElement> & {
  onValueChange?: (value: string) => void
  isLoading?: boolean
}
const SessionsSearch = ({
  onValueChange,
  isLoading,
  className,
}: SessionsSearchProps) => {
  const { t } = useTranslation()
  const [dialogSessionFolderModalDialog, setDialogSessionFolderModalDialog] =
    useState<boolean>(false)
  const classes = twMerge('flex items-center z-20', className)
  return (
    <>
      <DialogSessionFolderModal
        open={dialogSessionFolderModalDialog}
        onClose={() => setDialogSessionFolderModalDialog(false)}
      />
      <div className={classes}>
        <Input
          isClearable
          variant="bordered"
          placeholder="Type to search ..."
          className="flex-1 mr-2 "
          readOnly={isLoading}
          classNames={{
            inputWrapper: 'h-12 shadow-none',
          }}
          onValueChange={(value) => onValueChange && onValueChange(value)}
        />
        <Tooltip content={t('translation:addFolder')} className={'max-w-96'}>
          <Button
            fullWidth
            isLoading={isLoading}
            isIconOnly
            variant={'light'}
            onPress={() => setDialogSessionFolderModalDialog(true)}
          >
            <FolderPlusIcon className={'w-5 h-5'} />
          </Button>
        </Tooltip>
      </div>
    </>
  )
}

export default SessionsSearch
