import axiosClient from '../utils/axiosClient.ts'
import { saveAs } from 'file-saver'
import { iUploadResponse } from '../interfaces/iUploadResponse.ts'
import { iDocument } from '../interfaces/iFile.ts'
import { iKnowledgeContainer } from '../interfaces/iKnowledgeContainer.ts'

import { iFile } from '@/interfaces/iSourceFileCitations.ts'
import { iDocumentTree } from '@/interfaces/iBlobUpload.ts'
import { AccessRight } from '@/interfaces/iShared.ts'

class KnowledgeContainerService {
  private static instance: KnowledgeContainerService

  private constructor() {}

  public static getInstance(): KnowledgeContainerService {
    if (!KnowledgeContainerService.instance) {
      KnowledgeContainerService.instance = new KnowledgeContainerService()
    }

    return KnowledgeContainerService.instance
  }

  public async createKnowledgeContainer(
    knowledgeContainer: iKnowledgeContainer,
  ): Promise<iKnowledgeContainer> {
    const response = await axiosClient.post(
      '/knowledge-containers',
      knowledgeContainer,
    )
    if (response.status !== 200) {
      throw new Error('Could not create knowledgeContainer')
    }
    return response.data as iKnowledgeContainer
  }

  public async getKnowledgeContainer(
    knowledgeContainerId: string,
  ): Promise<iKnowledgeContainer> {
    const response = await axiosClient.get(
      `/knowledge-containers/${knowledgeContainerId}`,
    )
    return response.data
  }

  public async getKnowledgeContainers(options?: {
    baseSettingsId?: string
    searchValue?: string
    limit?: number
    offset?: number
    accessRight?: AccessRight
  }): Promise<{ data: iKnowledgeContainer[]; total: number }> {
    const baseSettingsId =
      options?.baseSettingsId && options.baseSettingsId !== ''
        ? options.baseSettingsId
        : 'null'
    const params = {
      searchValue: options?.searchValue,
      limit: options?.limit,
      offset: options?.offset,
      accessRight: options?.accessRight,
    }

    const response = await axiosClient.get('/knowledge-containers', {
      params: {
        baseSettingsId,
        ...params,
      },
    })
    return response.data as {
      data: iKnowledgeContainer[]
      total: number
    }
  }

  public async updateKnowledgeContainer(
    knowledgeContainer: iKnowledgeContainer,
  ): Promise<iKnowledgeContainer> {
    const response = await axiosClient.put(
      `/knowledge-containers/${knowledgeContainer.id}`,
      knowledgeContainer,
    )
    if (response.status !== 200) {
      throw new Error('Could not update knowledgeContainer')
    }
    return response.data
  }

  public async deleteKnowledgeContainer(
    knowledgeContainerId: string,
  ): Promise<void> {
    const response = await axiosClient.delete(
      `/knowledge-containers/${knowledgeContainerId}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not delete knowledgeContainer')
    }
  }

  public async downloadImageBlob(blobName: string): Promise<Blob> {
    const response = await axiosClient.get(`/documents/${blobName}/download`, {
      responseType: 'blob',
    })
    if (response.status !== 200) {
      throw new Error('Could not download blob')
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    return blob
  }

  public async downloadBlob(
    knowledgeContainerId: string,
    documentName: string,
  ): Promise<void> {
    const response = await axiosClient.get(
      `/knowledge-containers/${knowledgeContainerId}/blobs/${documentName}`,
      { responseType: 'blob' },
    )
    if (response.status !== 200) {
      throw new Error('Could not download document')
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    saveAs(blob, documentName)
  }

  public uploadDocument(file: File): Promise<iUploadResponse> {
    const formData = new FormData()
    formData.append('file', file)
    return axiosClient
      .post('/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
  }

  public uploadKnowledgeContainerDocuments(
    containerId: string,
    files: File[],
  ): (() => Promise<void>)[] {
    const MAX_BATCH_SIZE = 10 * 1024 * 1024
    let currentBatchSize = 0
    let currentBatch: File[] = []
    const batches: File[][] = []

    // Split files into batches based on MAX_BATCH_SIZE
    for (const file of files) {
      if (currentBatchSize + file.size > MAX_BATCH_SIZE) {
        batches.push([...currentBatch]) // copy currentBatch
        currentBatch = [file]
        currentBatchSize = file.size
      } else {
        currentBatch.push(file)
        currentBatchSize += file.size
      }
    }
    if (currentBatch.length > 0) {
      batches.push([...currentBatch]) // copy currentBatch
    }
    // Upload each batch
    return batches.map((batch) => () => {
      if (batch.length === 0) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        batch.forEach((file) => {
          // Append each file to the FormData object
          formData.append('files', file, file.webkitRelativePath || file.name)
        })
        // Send a POST request to the server with the FormData object
        return axiosClient
          .post(`/knowledge-containers/${containerId}/documents`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .catch(reject) // Make sure to reject the Promise if the request fails
          .then(() => resolve())
      })
    })
  }

  public async deleteKnowledgeContainerDocument(
    knowledgeContainerId: string,
    documentName: string,
  ): Promise<void> {
    const response = await axiosClient.delete(
      `/knowledge-containers/${knowledgeContainerId}/documents?path=${decodeURI(documentName)}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not delete blob')
    }
  }

  public async getKnowledgeContainerDocuments(
    knowledgeContainerId: string,
    options?: { format: 'list' | 'tree' },
  ): Promise<iDocumentTree> {
    const response = await axiosClient.get(
      `/knowledge-containers/${knowledgeContainerId}/documents`,
      {
        params: {
          format: options?.format,
        },
      },
    )
    if (response.status !== 200) {
      throw new Error('Could not get documents')
    }
    return response.data
  }

  public async getKnowledgeContainerDocumentsAccessUrls(
    knowledgeContainerId: string,
    documents: string[],
  ): Promise<iFile[]> {
    const response = await axiosClient.post(
      `/knowledge-containers/${knowledgeContainerId}/documents/access-url`,
      documents,
    )
    if (response.status !== 200) {
      throw new Error('Could not get documents')
    }
    return response.data
  }

  public async createKnowledgeContainerIcon(containerId: string): Promise<string> {
    const response = await axiosClient.post(
      `/knowledge-containers/${containerId}/icons`,
    )
    if (response.status !== 200) {
      throw new Error('Could not create knowledge container icon')
    }

    return response.data as string
  }

  public async deleteKnowledgeContainerIcon(
    containerId: string,
    iconId: string,
  ): Promise<void> {
    const response = await axiosClient.delete(
      `/knowledge-containers/${containerId}/icons/${iconId}`,
    )
    if (response.status !== 200 && response.status !== 204) {
      throw new Error('Could not delete knowledge container icon')
    }
  }

  public async getKnowledgeContainerIconUrl(
    containerId: string,
    iconId: string,
  ): Promise<string> {
    const response = await axiosClient.get(
      `/knowledge-containers/${containerId}/icons/${iconId}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not get knowledge container icon URL')
    }
    return response.data as string
  }
}

export default KnowledgeContainerService.getInstance()
