import React from 'react'
import { UserPlusIcon } from '@heroicons/react/24/outline'

import { Button } from '@nextui-org/react'
import useMainStore from '../../states/mainState.ts'
import { Popup } from '../../enums/PopupTypes.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import { AuthRole } from '@/utils/authentication.ts'

const NoRelatedTeam = () => {
  const setPopup = useMainStore((state) => state.setPopup)
  const userProfile = useUserProfileStore().userProfile
  // TODO: clean
  return (
    <div className={'flex flex-col max-w-full  w-full mx-auto px-2 sm:px-4'}>
      <div className={'grid place-content-center h-full place-items-stretch'}>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Welcome to GAIA!</h2>
            <p>GAIA is here to assist you with any queries you may have.</p>
            <p>You are not assigned to team yet.</p>
            {userProfile!.role === AuthRole.Admin && (
              <p className={'flex'}>
                <UserPlusIcon
                  className="h-5 w-5 mr-2 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={() => setPopup(Popup.UserManagement)}
                />
                As a team manager you can create a team in the user management
                section.
              </p>
            )}
            {userProfile!.role === AuthRole.Admin && (
              <p className={'flex justify-center items-center'}>
                <Button
                  isIconOnly
                  onPress={() => setPopup(Popup.UserManagement)}
                  className={'mr-3'}
                >
                  <UserPlusIcon
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  />
                </Button>
                As an admin you can create a team in the user management section.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoRelatedTeam
