import { iItem } from './iItem.ts'

export interface iFolder extends iItem {
  title: string
  teamId: string
  settingsId?: string
  _count?: number
}

export enum SessionModalMode {
  None,
  Create,
  Edit,
}
