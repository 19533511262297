import React, { useEffect, useMemo, useState } from 'react'
import { Spinner } from '@nextui-org/react'
import useSessionStore from '@states/sessionStore.ts'
import useFolderStore from '@states/folderStore.ts'
import { shallow } from 'zustand/shallow'
import useTeamStore from '@/states/teamStore.ts'
import NoRelatedTeam from '../components/emptyStates/NoRelatedTeam.tsx'
import EmptyTeam from '../components/emptyStates/EmptyTeam.tsx'
import EmptySessionFolder from '../components/emptyStates/EmptySessionFolder.tsx'
import LegalDialog from '../components/legal/LegalDialog.tsx'
import useUserProfileStore from '@/states/userProfileState.ts'
import SessionService from '@/services/sessionService.ts'
import { iSession } from '@/interfaces/iSession.ts'
import { iFolder } from '@/interfaces/iFolder.ts'
import ChatWindow from '@components/chat/ChatWindow.tsx'
import useChatStore from '@states/chatStore.ts'

const ChatPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const userProfile = useUserProfileStore((state) => state.userProfile)
  const addFolder = useFolderStore((state) => state.addFolder)

  const sessions = useSessionStore((state) => state.sessions)
  const { activeChatSession, assistant, session, isActivatingSession } =
    useChatStore(
      (state) => ({
        activeChatSession: state.activateChatSession,
        assistant: state.assistant,
        isActivatingSession: state.isActivatingSession,
        session: state.session,
      }),
      shallow,
    )
  const storeIsInitialized = useSessionStore((state) => state.storeIsInitialized)

  const { folders } = useFolderStore(
    (state) => ({ folders: state.folders }),
    shallow,
  )
  const { teams, selectedTeam } = useTeamStore(
    (state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  useEffect(() => {
    if (session) return setIsLoading(false)
    setIsLoading(true)
    // wait till session store is init
    if (!storeIsInitialized) return
    if (!selectedTeam) return

    // load users last session
    if (sessions.some((s) => s.id === userProfile?.lastSessionId)) {
      SessionService.getSession(userProfile!.lastSessionId!)
        .then((session) => activeChatSession(session))
        .finally(() => setIsLoading(false))
    }
    // activate users first session
    else if (sessions.length) {
      console.log('load first session')
      const session = sessions[0]
      activeChatSession(session).finally(() => setIsLoading(false))
    }
    // if there is no session, create an default folder and session
    else {
      console.log('load new session from default folder')
      const defaultFolder: iFolder = {
        title: 'Default Folder',
        teamId: selectedTeam!.id!,
      }
      addFolder(defaultFolder).finally(() => setIsLoading(false))
    }
  }, [storeIsInitialized])

  const termsOfUseAssistant = useMemo(() => {
    return assistant?._termsOfUse
  }, [assistant])

  return (
    <>
      {isLoading || isActivatingSession ? (
        <div className={'flex flex-1 justify-center align-center'}>
          <Spinner size={'lg'} />
        </div>
      ) : selectedTeam ? (
        <ChatWindow />
      ) : teams?.length === 0 ? (
        <NoRelatedTeam />
      ) : teams?.length && folders.length === 0 ? (
        <EmptyTeam />
      ) : folders.length > 0 && sessions.length === 0 ? (
        <EmptySessionFolder />
      ) : (
        <></>
      )}
      {termsOfUseAssistant && (
        <LegalDialog
          termsOfUse={termsOfUseAssistant}
          resourceId={assistant!.baseAssistantId!}
        ></LegalDialog>
      )}
    </>
  )
}

export default React.memo(ChatPage)
