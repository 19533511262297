import { getToken } from '@/utils/authentication.ts'
import { iAssistantPayload } from '@states/signalRState.ts'

export const signalRStartAssistant = (
  sessionId: string,
  userId: string,
  connection: signalR.HubConnection,
) => {
  const payload: iAssistantPayload = {
    sessionId: sessionId,
    userId: userId,
    token: getToken(),
    connectionId: connection.connectionId!,
  }
  connection.send('StartAssistant', payload)
  // await AssistantService.startAssistant(sessionId, message);
}

export const signalRLeaveSession = (
  sessionId: string,
  userId: string,
  connection: signalR.HubConnection,
) => {
  const payload: iAssistantPayload = {
    sessionId: sessionId,
    userId: userId,
    connectionId: connection.connectionId!,
  }
  connection.send('leave', payload)
}
export const signalRJoinSession = (
  sessionId: string,
  userId: string,
  connection: signalR.HubConnection,
) => {
  const payload: iAssistantPayload = {
    sessionId: sessionId,
    userId: userId,
    connectionId: connection.connectionId!,
  }
  connection.send('join', payload)
}
