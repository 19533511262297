import { Select, SelectItem, Tooltip } from '@nextui-org/react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { ShareType } from '@/interfaces/iItem.ts'
import { useTranslation } from 'react-i18next'

type ShareWithSelectionProps = {
  selectedKey: ShareType
  onChange: (type: ShareType) => void
  isDisabled?: boolean
}

const ShareWithSelection = ({
  selectedKey,
  onChange,
  isDisabled,
}: ShareWithSelectionProps) => {
  const { t } = useTranslation()

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = +event.target.value as unknown as ShareType
    onChange(newValue)
  }

  const shareTypeItems = [
    { value: ShareType.None, text: 'None' },
    { value: ShareType.Public, text: 'Public' },
    { value: ShareType.Limited, text: 'Limited' },
  ]

  return (
    <label className="label ">
      <span className="label-text flex">
        {t('translation:popup.share_with')}
        <Tooltip
          content="Limited: Only selected members can access this container. Public: Everyone can access this container. People enlisted can also modify this container"
          className={'max-w-96'}
        >
          <InformationCircleIcon
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.0}
            stroke="currentColor"
            className="w-4 h-4 ml-1"
          />
        </Tooltip>
      </span>
      <Select
        isDisabled={isDisabled}
        aria-label="Share with"
        selectedKeys={[String(selectedKey)]}
        items={shareTypeItems}
        className="w-1/2 max-w-xs"
        onChange={(e) => onSelectChange(e)}
      >
        {(shareType) => (
          <SelectItem
            key={String(shareType.value)}
            textValue={shareType.text}
            value={shareType.value}
          >
            <div className="flex gap-2 items-center">
              <div className="flex flex-col truncate">
                <span className="text-small truncate">{shareType.text}</span>
              </div>
            </div>
          </SelectItem>
        )}
      </Select>
    </label>
  )
}

export default ShareWithSelection
