import React, { useState, useEffect } from 'react'
import {
  Button,
  DatePicker,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
} from '@nextui-org/react'
import { fromDate, getLocalTimeZone, ZonedDateTime } from '@internationalized/date'
import { iButtonHandles } from '@/interfaces/iUserManagement.ts'
import { iModelInfo } from '@/interfaces/iModelInfo.ts'
import TokenService from '../../services/tokenService.ts'

function UserManagementUsage({ closeFunction, team }: iButtonHandles) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [previousModelInfo, setPreviousModelInfo] = useState<iModelInfo[]>([])
  const [modelInfo, setModelInfo] = useState<iModelInfo[]>([])
  // default start date is the first day of the current month
  // const [dateRange, setDateRange] = useState([new Date((new Date().getFullYear(), new Date().getMonth(), 1)), new Date()]);
  const [dateRange, setDateRange] = useState({
    // from: date as yyyy-mm-dd first day of this month + 1 hour
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 2),
    to: new Date(),
  })

  useEffect(() => {
    setIsLoading(true)
    setModelInfo([])
    const from = dateRange.from
    const to = dateRange.to

    // Calculate the previous date range
    const previousFrom = new Date(from)
    const previousTo = new Date(to)
    const diffTime = Math.abs(to.getTime() - from.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    previousFrom.setDate(from.getDate() - diffDays)
    previousTo.setDate(to.getDate() - diffDays)

    if (team?.id === 'supergeheimeradmin') {
      Promise.all([
        TokenService.getAllTokensAsAdmin(from, to),
        TokenService.getAllTokensAsAdmin(previousFrom, previousTo),
      ]).then((arrayReturnValue) => {
        setModelInfo(arrayReturnValue[0])
        setPreviousModelInfo(arrayReturnValue[1])
        setIsLoading(false)
      })
      return
    }
    Promise.all([
      TokenService.getTokens(team!.id!, from, to),
      TokenService.getTokens(team!.id!, previousFrom, previousTo),
    ]).then((arrayReturnValue) => {
      setModelInfo(arrayReturnValue[0])
      setPreviousModelInfo(arrayReturnValue[1])
      setIsLoading(false)
    })
  }, [dateRange])

  const setDateRangeToLastMonth = () => {
    const date = new Date()
    date.setDate(1)
    date.setHours(2)
    date.setMonth(date.getMonth() - 1)
    setDateRange({
      from: date,
      // to date is the last day of the previous month
      to: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    })
  }

  const setDataRangeToThisMonth = () => {
    const date = new Date()
    date.setDate(1)
    date.setHours(2)
    setDateRange({
      from: date,
      // to date is the last day of the previous month
      to: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    })
  }

  const handleCancel = () => {
    closeFunction()
  }

  if (!team) {
    return <></>
  }

  return (
    <>
      <ModalHeader>
        <div className="flex items-center">
          <Button variant="light" onPress={setDateRangeToLastMonth}>
            Letzten Monat anzeigen
          </Button>

          <Button variant="light" onPress={setDataRangeToThisMonth} className="ml-2">
            Diesen Monat anzeigen
          </Button>

          <div className="m-4">
            <DatePicker
              label="Von"
              hideTimeZone
              value={fromDate(dateRange.from, getLocalTimeZone())}
              onChange={(dateValue: ZonedDateTime | null) =>
                setDateRange({
                  ...dateRange,
                  from: dateValue!.toDate(),
                })
              }
            />
          </div>

          <span>bis</span>

          <div className="m-4">
            <DatePicker
              label="Bis"
              hideTimeZone
              value={fromDate(dateRange.to, getLocalTimeZone())}
              onChange={(dateValue: ZonedDateTime | null) =>
                setDateRange({
                  ...dateRange,
                  to: dateValue!.toDate(),
                })
              }
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table>
          <TableHeader>
            <TableColumn>Modell</TableColumn>
            <TableColumn>Region</TableColumn>
            <TableColumn>Tokens</TableColumn>
          </TableHeader>
          <TableBody
            isLoading={isLoading}
            loadingContent={<Spinner label="Lade Daten..." />}
          >
            {modelInfo.map((mI, index) => {
              const modelName =
                mI.service === 'azure-document-intelligence'
                  ? 'Azure Document Intelligence'
                  : mI.service
              const totalQuantity = mI.totalQuantity
              const totalPrice = mI.totalPrice

              const previousMi = previousModelInfo.find(
                (prevMI) => prevMI.service === mI.service,
              )

              let tokenChange = 0
              if (previousMi?.totalQuantity) {
                tokenChange =
                  ((mI.totalQuantity - previousMi.totalQuantity) /
                    previousMi.totalQuantity) *
                  100
              }

              return (
                <TableRow key={index}>
                  <TableCell>
                    <div className="font-bold text-primary">{modelName}</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold text-primary">{mI.region}</div>
                  </TableCell>
                  <TableCell>
                    <div className="text-xl">
                      {totalQuantity} - {totalPrice.toFixed(2)} €
                    </div>
                    <div className="text-sm opacity-50">
                      Änderung zum vorherigen Zeitraum: {tokenChange.toFixed(2)}%
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button onPress={handleCancel}>Schließen</Button>
      </ModalFooter>
    </>
  )
}

export default UserManagementUsage
