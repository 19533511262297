import React, { useMemo, useState } from 'react'
import { Skeleton, User } from '@nextui-org/react'
import { getUser } from '@/utils/authentication.ts'
import ProfileImageLoader from '@/hooks/useProfileImage.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import UserDisplay from '@components/user/UserDisplay.tsx'
import { useTranslation } from 'react-i18next'
import SkeletonDetailPage from '@components/skeletons/SkeletonDetailPage.tsx'
import SkeletonUser from '@components/skeletons/SkeletonUser.tsx'

type UserProfileProps = {
  email?: string
  displayName?: string
  gaiaRole?: string
}
const UserProfile = ({ email, displayName, gaiaRole }: UserProfileProps) => {
  const userProfile = useUserProfileStore().userProfile
  const { t } = useTranslation()

  const { email: usersMail, displayName: usersDisplayName } = getUser()
  const tmpEmail = useMemo(() => email || usersMail, [email])
  const tmpDisplayName = useMemo(() => displayName || usersDisplayName, [email])
  const tmpGaiaRole = useMemo(() => gaiaRole || userProfile!.role, [email])

  return (
    <>
      {!userProfile ? (
        <SkeletonUser></SkeletonUser>
      ) : (
        <UserDisplay
          description={t('user:authRoles.' + tmpGaiaRole)}
          displayName={tmpDisplayName}
          email={tmpEmail}
        ></UserDisplay>
      )}
    </>
  )
}
export default UserProfile
