import React, { useState, useEffect } from 'react'
import TeamService from '../../services/teamService.ts'
import patService from '../../services/patService.ts'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Input,
  DatePicker,
  Skeleton,
  Spinner,
} from '@nextui-org/react'
import { iPatKeys } from '@/interfaces/iPat.ts'
import CopyToClipboard from '../basic/copyToClipboard/CopyToClipboard.tsx'
import { iTeam } from '@/interfaces/iTeam.ts'
import FaqItem from '@components/faq/FaqItem.tsx'
import { getFAQById } from '@/utils/getLocalFaqs.ts'
import { FAQ_ID_PAT } from '@/constants/faqIDs.ts'
import SkeletonCard from '@components/skeletons/SkeletonCard.tsx'
import { ICON_DELETE, ICON_REFRESH } from '@/constants/icons.tsx'
import { toast } from 'react-toastify'
import { fromDate, getLocalTimeZone } from '@internationalized/date'

type PatManagerProps = {
  onClose: () => void
}

function PatManager({ onClose }: PatManagerProps) {
  const [patKeys, setPatKeys] = useState<iPatKeys>({})
  const [teams, setTeams] = useState<iTeam[]>([])
  const [patLoading, setPatLoading] = useState(false)
  const [isSpinning, setIsSpinning] = useState<{ [key: string]: boolean }>({})
  const [expiryDates, setExpiryDates] = useState<{ [key: string]: Date }>({})
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({})

  const minExpiryDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
  const maxExpiryDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)

  useEffect(() => {
    setPatLoading(true)
    TeamService.getTeams(false)
      .then((teams) => {
        setTeams(teams.filter((team) => team.apiAccess))
      })
      .catch((error) => {
        console.error('Error fetching teams:', error)
        toast.error('Failed to fetch teams')
      })
  }, [])

  useEffect(() => {
    if (teams.length > 0) {
      setPatLoading(true)
      patService
        .getPats()
        .then((pats) => {
          const patMap: iPatKeys = {}
          const defaultExpiryDates: { [key: string]: Date } = {}
          pats.forEach((pat) => {
            patMap[pat.teamId] = pat
            defaultExpiryDates[pat.teamId] = new Date(pat.expiryDate)
          })
          setPatKeys(patMap)
          setExpiryDates(defaultExpiryDates)
          setPatLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching PATs:', error)
          toast.error('Failed to fetch PATs')
        })
    }
  }, [teams])

  const handleAddKey = (teamId: string) => {
    setIsSpinning((prev) => ({ ...prev, [teamId]: true }))

    const expiryDateValue = expiryDates[teamId] || minExpiryDate
    const newKeyLifetime = expiryDateValue

    patService
      .createPat(teamId, newKeyLifetime)
      .then((response) => {
        setPatKeys((prevPatKeys) => {
          const newPatKeys = { ...prevPatKeys }
          newPatKeys[teamId] = response
          return newPatKeys
        })
        setExpiryDates((prev) => ({
          ...prev,
          [teamId]: newKeyLifetime,
        }))
        toast.info("Personal Access Token created. Don't forget to copy it.")
      })
      .catch((error) => {
        console.error('Error creating PAT:', error)
        toast.error('Failed to create PAT')
      })
      .finally(() => {
        setIsSpinning((prev) => ({ ...prev, [teamId]: false }))
      })
  }

  const handleDeleteKey = (teamId: string) => {
    const tokenId = patKeys[teamId]?.id as string
    setIsDeleting((prev) => ({ ...prev, [teamId]: true }))

    patService
      .deletePat(tokenId)
      .then((response) => {
        if (response.status === 200) {
          setPatKeys((prevPatKeys) => {
            const newPatKeys = { ...prevPatKeys }
            delete newPatKeys[teamId]
            return newPatKeys
          })
          setExpiryDates((prev) => {
            const newExpiryDates = { ...prev }
            delete newExpiryDates[teamId]
            return newExpiryDates
          })
          toast.success('PAT deleted successfully')
        } else {
          console.error('PAT could not be deleted:', response)
          toast.error('PAT could not be deleted: ' + response.statusText)
        }
      })
      .catch((error) => {
        console.error('Error deleting PAT:', error)
        toast.error('Failed to delete PAT')
      })
      .finally(() => {
        setIsDeleting((prev) => ({ ...prev, [teamId]: false }))
      })
  }

  const patFAQ = getFAQById(FAQ_ID_PAT)

  const patRows = teams.map((team) => {
    const teamId = team.id!
    const hasPat = !!patKeys[teamId]
    const patExpiryDate = new Date(patKeys[teamId]?.expiryDate || '')
    const currentExpiryDate = expiryDates[teamId] || minExpiryDate
    const isModified =
      expiryDates[teamId] &&
      patKeys[teamId]?.expiryDate &&
      currentExpiryDate.toISOString() !== patExpiryDate.toISOString()
    return (
      <TableRow key={teamId}>
        <TableCell>{team.name}</TableCell>
        <TableCell>
          <DatePicker
            color={isModified ? 'warning' : 'default'}
            defaultValue={fromDate(currentExpiryDate, getLocalTimeZone())}
            minValue={fromDate(minExpiryDate, getLocalTimeZone())}
            maxValue={fromDate(maxExpiryDate, getLocalTimeZone())}
            onChange={(dateValue) => {
              setExpiryDates((prev) => ({
                ...prev,
                [teamId]: dateValue ? dateValue.toDate() : minExpiryDate,
              }))
            }}
            hideTimeZone
            showMonthAndYearPickers
          />
        </TableCell>
        <TableCell>
          {hasPat ? (
            <Input readOnly value={patKeys[teamId].token} type="password" />
          ) : (
            'No PAT'
          )}
        </TableCell>
        <TableCell>
          <div className="flex space-x-2">
            {hasPat && (
              <>
                <Button isIconOnly variant={'light'} title="Copy PAT to clipboard">
                  <CopyToClipboard textToCopy={patKeys[teamId]?.token ?? ''} />
                </Button>
                <Button
                  isIconOnly
                  variant={'light'}
                  onPress={() => handleAddKey(teamId)}
                  title="Refresh PAT with new expiry date"
                >
                  <ICON_REFRESH
                    className={`w-5 h-5 ${
                      isSpinning[teamId]
                        ? 'animate-spinner-ease-spin'
                        : isModified
                          ? 'text-success'
                          : ''
                    }`}
                  />
                </Button>
                <Button
                  isIconOnly
                  isLoading={isDeleting[teamId]}
                  variant={'light'}
                  onPress={() => handleDeleteKey(teamId)}
                  title="Delete PAT"
                >
                  <ICON_DELETE className="w-5 h-5 hover:text-danger" />
                </Button>
              </>
            )}
            {!hasPat && (
              <Button
                isIconOnly
                variant={'light'}
                onPress={() => handleAddKey(teamId)}
                title="Create PAT with expiry date"
              >
                <ICON_REFRESH
                  className={`w-5 h-5 ${
                    isSpinning[teamId]
                      ? 'animate-spinner-ease-spin'
                      : isModified
                        ? 'text-warning'
                        : ''
                  }`}
                />
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={onClose}
      classNames={{
        base: '!max-w-[750px] !w-full',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center w-full justify-between">
            <h3 className="text-xl font-semibold">Personal Access Tokens</h3>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex items-center justify-between mb-2">
            <h4 className="text-lg font-semibold ml-4">
              Manage Personal Access Tokens
            </h4>
          </div>
          <Table>
            <TableHeader>
              <TableColumn>Team Name</TableColumn>
              <TableColumn>Expiry Date</TableColumn>
              <TableColumn>Personal Access Token</TableColumn>
              <TableColumn>Actions</TableColumn>
            </TableHeader>
            <TableBody
              isLoading={patLoading}
              loadingContent={<Spinner label="Loading..." />}
              items={patLoading ? [] : patRows}
            >
              {(row) => row}
            </TableBody>
          </Table>

          {patFAQ && <FaqItem markdown={patFAQ?.markdown} />}
        </ModalBody>
        <ModalFooter>
          <Button onPress={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PatManager
