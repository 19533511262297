import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { deepCopy } from '@/utils/deepCopy.ts'
import { useTranslation } from 'react-i18next'

import { Input, Tab, Tabs, Textarea, Tooltip } from '@nextui-org/react'
import DocumentAnalysisSelection from './DocumentAnalysisSelection.tsx'
import { ShareType } from '@/interfaces/iItem.ts'
import {
  iKnowledgeContainer,
  iRagStrategy,
} from '@/interfaces/iKnowledgeContainer.ts'
import {
  FlagField,
  SelectField,
  SelectOption,
  TextField,
} from '../basic/inputfields/model.ts'
import { AuthRole } from '@/utils/authentication.ts'
import ToolService from '../../services/toolService.ts'
import KnowledgeContainerService from '../../services/knowledgeContainerService.ts'
import TeamSelect from '../team/TeamSelect.tsx'
import MembersSelection from '../members/MemberSelection.tsx'
import {
  FlagFieldEditor,
  SelectFieldEditor,
  TextFieldEditor,
} from '../basic/inputfields/FieldEditors.tsx'
import { iTool } from '@/interfaces/iTool.ts'
import useUserProfileStore from '@/states/userProfileState.ts'
import KnowledgeContainerFileManager, {
  KnowledgeContainerFileManagerHandle,
} from '@components/knowledgeContainers/KnowledgeContainerManageFiles.tsx'
import ShareWithSelection from '@components/basic/shareWithSelection/ShareWithSelection.tsx'
import { toast } from 'react-toastify'
import SectionCard from '@components/basic/sectionCard/SectionCard.tsx'

export type KnowledgeContainerDetailViewHandler = {
  saveContainer: () => Promise<iKnowledgeContainer | undefined>
}

type KnowledgeContainerDetailViewProps = {
  value: iKnowledgeContainer
  isDisabled: boolean
}
const KnowledgeContainerDetailView: ForwardRefRenderFunction<
  KnowledgeContainerDetailViewHandler,
  KnowledgeContainerDetailViewProps
> = ({ value, isDisabled }: KnowledgeContainerDetailViewProps, ref) => {
  const emptyRagStrategy = () => ({
    indexingUrl: '',
    retrievalToolId: '',
  })
  const userProfile = useUserProfileStore().userProfile

  const [knowledgeContainer, setKnowledgeContainer] = useState<iKnowledgeContainer>({
    ...value,
  })

  useImperativeHandle(ref, () => ({
    saveContainer,
  }))

  const knowledgeContainerRef = useRef<KnowledgeContainerFileManagerHandle>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = React.useState<Iterable<number | string>>(
    [],
  )
  const { t } = useTranslation()
  const [isCustomRagAllowed, setIsCustomRagAllowed] = useState<boolean>(false)
  const [ragStrategy, setRagStrategy] = useState<iRagStrategy | undefined>(
    value.ragStrategy,
  )
  const [retrievalTools, setRetrievalTools] = useState<SelectOption[]>([
    { key: '', display: '' },
  ])
  const [selectedRetrievalTool, setSelectedRetrievalTool] = useState<SelectOption>({
    key: '',
    display: '',
  })

  useEffect(() => {
    switch (knowledgeContainer.sharedType) {
      case ShareType.Limited:
        break
      case ShareType.Public:
        break
      case undefined:
        // set to None
        setKnowledgeContainer({ ...knowledgeContainer, sharedType: ShareType.None })
    }
    if (userProfile!.role! >= AuthRole.Developer) {
      setIsCustomRagAllowed(true)
    }
    ToolService.getTools().then((toolsResult: { data: iTool[]; total: number }) => {
      const toolOptions = [
        { key: '', display: '' },
        ...toolsResult.data.map((p) => ({ key: p.id!, display: p.name! })),
      ]
      setRetrievalTools(toolOptions)
      if (ragStrategy?.retrievalToolId) {
        const toolOption = toolOptions.find(
          (p) => p.key === value.ragStrategy?.retrievalToolId,
        )
        if (toolOption) {
          setSelectedRetrievalTool(toolOption)
        }
      }
    })
    setSelectedTeam([knowledgeContainer.teamId] as never)
  }, [])

  useEffect(() => {
    const toolId = selectedRetrievalTool?.key || ''
    if (!toolId) {
      return
    }
    setRetrievalTools(retrievalTools.filter((p) => p.key !== ''))
    const s = {
      ...ragStrategy,
      retrievalToolId: toolId,
    } as iRagStrategy
    setRagStrategy(s)
  }, [selectedRetrievalTool])

  const onSelectChange = (value: ShareType) => {
    setKnowledgeContainer({ ...knowledgeContainer, sharedType: value })
  }

  const validateDescription = (description: string) => {
    if (!description) return 'Description is required'
  }
  const validateTeam = (teamId: string | string[]) => {
    if (!teamId) return 'Team is required'
    return null
  }
  const validateName = (name: string) => {
    if (!name) return 'Name is required'
    // name cannot have umlauts
    if (/[äöüÄÖÜß]/.test(name)) {
      return 'Knowledge Container name cannot contain umlauts'
    }

    return null
  }

  const saveContainer = async () => {
    const id = toast('Please wait...', { isLoading: true })
    try {
      setIsLoading(true)
      let newKnowledgeContainer: iKnowledgeContainer = deepCopy(knowledgeContainer)

      //newKnowledgeContainer.shared = shared
      //newKnowledgeContainer.teamId = selectedTeam
      newKnowledgeContainer.ragStrategy = ragStrategy

      // new container
      if (!value.id) {
        newKnowledgeContainer =
          await KnowledgeContainerService.createKnowledgeContainer(
            newKnowledgeContainer,
          )
      } else {
        newKnowledgeContainer =
          await KnowledgeContainerService.updateKnowledgeContainer(
            newKnowledgeContainer,
          )
      }

      // save files
      if (knowledgeContainerRef.current) {
        await knowledgeContainerRef.current.saveAndUpload()
      }

      setIsLoading(false)
      toast.update(id, {
        render: 'All is good',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
      })
      return newKnowledgeContainer
    } catch (error) {
      toast.update(id, {
        render: 'Something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      })
      throw new Error('')
    }
  }

  return (
    <Tabs variant="underlined">
      <Tab key={'general'} title="General">
        <div className="flex flex-col gap-4">
          <SectionCard title={'Name & Description'} description={''}>
            <Input
              readOnly={isDisabled}
              isRequired
              validate={validateName}
              defaultValue={knowledgeContainer.name}
              onChange={(e) =>
                setKnowledgeContainer({
                  ...knowledgeContainer,
                  name: e.target.value,
                })
              }
              type="text"
              label="Name"
              variant="flat"
              className="mb-4"
            />

            <Textarea
              isRequired
              readOnly={isDisabled}
              validate={validateDescription}
              onChange={(e) =>
                setKnowledgeContainer({
                  ...knowledgeContainer,
                  description: e.target.value,
                })
              }
              defaultValue={knowledgeContainer.description}
              label="Description"
              variant="flat"
              rows={2}
              placeholder={t('translation:container.description.placeholder')}
              className="mb-4"
            />
          </SectionCard>

          <SectionCard title={'Costs'} description={''}>
            <div className={'w-full '}>
              <label className="label ">
                <span className="label-text flex">
                  Team cost center
                  <Tooltip
                    content={
                      'Indexing a knowledge container is associated with costs. For this reason, please select the team to which the costs are to be allocated.'
                    }
                    className={'max-w-96'}
                  >
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </Tooltip>
                </span>
                <TeamSelect
                  isRequired
                  validate={validateTeam}
                  selectedKeys={selectedTeam}
                  className="w-1/2 max-w-xs"
                  withoutDefaultTeam
                  withoutTeamIcon
                  withoutCostcenter
                  isDisabled={isDisabled}
                  onSelectionChange={(team) => {
                    setSelectedTeam([team.id] as Iterable<string>)
                    setKnowledgeContainer({
                      ...knowledgeContainer,
                      teamId: team.id!,
                    })
                  }}
                ></TeamSelect>
              </label>
            </div>
            <DocumentAnalysisSelection
              isDisabled={isDisabled}
              onSelectionChange={(documentAnalysisModelId) => {
                setKnowledgeContainer({
                  ...knowledgeContainer,
                  documentAnalysisModelId,
                })
              }}
              defaultValue={knowledgeContainer.documentAnalysisModelId}
            ></DocumentAnalysisSelection>
          </SectionCard>

          {isCustomRagAllowed && (
            <SectionCard title="Custom Rag" description={''}>
              <FlagFieldEditor
                isDisabled={isDisabled}
                field={{
                  key: 'ragStrategy',
                  name: 'Custom RAG',
                  info: 'Enable custom RAG for this container',
                  value: !!ragStrategy,
                }}
                onChange={(field: FlagField) => {
                  field.value
                    ? setRagStrategy(
                        knowledgeContainer.ragStrategy || emptyRagStrategy(),
                      )
                    : setRagStrategy(undefined)
                }}
              ></FlagFieldEditor>
              {!!ragStrategy && (
                <>
                  <TextFieldEditor
                    isDisabled={isDisabled}
                    field={{
                      key: 'indexingUrl',
                      name: 'Indexing URL',
                      info: 'URL to the indexing service',
                      value: ragStrategy.indexingUrl,
                    }}
                    onChange={(field: TextField) =>
                      setRagStrategy({ ...ragStrategy, indexingUrl: field.value })
                    }
                  ></TextFieldEditor>
                  <SelectFieldEditor
                    isDisabled={isDisabled}
                    field={{
                      key: 'retrievalToolId',
                      name: 'Retrieval Tool',
                      info: 'Select the retrieval tool for this container',
                      value: selectedRetrievalTool,
                      options: retrievalTools,
                    }}
                    onChange={(field: SelectField) =>
                      setSelectedRetrievalTool(field.value)
                    }
                  ></SelectFieldEditor>
                </>
              )}
            </SectionCard>
          )}
        </div>
      </Tab>
      <Tab key={'files'} title="Files">
        <KnowledgeContainerFileManager
          ref={knowledgeContainerRef}
          knowledgeContainerId={value.id!}
          isDisabled={isDisabled}
        ></KnowledgeContainerFileManager>
      </Tab>
      <Tab key="publicity" title="Public">
        <div className="flex flex-col gap-4">
          <SectionCard className="min-w-96" title="Privacy Settings" description="">
            <ShareWithSelection
              isDisabled={isDisabled}
              selectedKey={knowledgeContainer.sharedType ?? ShareType.None}
              onChange={onSelectChange}
            ></ShareWithSelection>
          </SectionCard>

          <MembersSelection
            isDisabled={knowledgeContainer.sharedType === ShareType.None}
            shared={knowledgeContainer.shared ?? []}
            ownerId={knowledgeContainer.ownerId}
            onSelectionChange={(members) => {
              setKnowledgeContainer({ ...knowledgeContainer, shared: members })
            }}
            roles={knowledgeContainer.roles}
            writeOnly={knowledgeContainer.sharedType === ShareType.Public}
          />
        </div>
      </Tab>
    </Tabs>
  )
}

export default React.forwardRef(KnowledgeContainerDetailView)
