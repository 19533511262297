import React from 'react'

import { TEST_PAGE_MANAGE_YOUR_TOOLS } from '@/constants/testingIds.ts'
import DefaultPageLayout from '@/pages/DefaultPageLayout.tsx'
import ToolView from '@components/tools/ToolListView.tsx'
import { ROUTE_MARKETPLACE_TOOLS_NEW } from '@/constants/routes.ts'
import { useNavigate } from 'react-router-dom'

function ToolsListPage() {
  const navigate = useNavigate()

  return (
    <DefaultPageLayout
      createAble
      title="Tools"
      data-testid={TEST_PAGE_MANAGE_YOUR_TOOLS}
      onCreateClick={async () => {
        navigate(ROUTE_MARKETPLACE_TOOLS_NEW)
      }}
    >
      <ToolView></ToolView>
    </DefaultPageLayout>
  )
}

export default ToolsListPage
