import { BLACKLISTEDEXTENSIONS } from '@/constants/blacklistedFileExtensions.ts'

type DirectoryEntry = {
  isFile: boolean
  isDirectory: boolean
  name: string
  file: (callback: (file: File) => void) => void
  createReader: () => DirectoryReader
}

type DirectoryReader = {
  readEntries: (callback: (entries: DirectoryEntry[]) => void) => void
}

export type CustomFileSystemEntry = FileSystemEntry & {
  file: (callback: (file: File) => void) => void
  createReader: () => CustomFileSystemDirectoryReader
}

type CustomFileSystemDirectoryReader = FileSystemDirectoryReader & {
  readEntries: (callback: (entries: CustomFileSystemEntry[]) => void) => void
}

export class CustomFile extends File {
  webkitRelativePath: string
  name: string

  constructor(files: File[], s: string, p: { type: string }, fullpath: string) {
    super(files, s, p)
    // remove only one leading '/' from fullpath
    if (fullpath.startsWith('/')) {
      fullpath = fullpath.substring(1)
    }
    this.webkitRelativePath = fullpath
    this.name = s
  }
}

/**
 * Used to process a directory and return a list of files. Works for dropped directories.
 *       const entry = item.webkitGetAsEntry()! as CustomFileSystemEntry;
 *       if (entry.isDirectory)
 *         const files = await processDirectory(entry);
 */
export const processDirectory = async (
  directoryEntry: CustomFileSystemEntry,
  path = '',
): Promise<File[]> => {
  const files: File[] = []

  const processEntry = async (entry: CustomFileSystemEntry) => {
    // get the file extension
    const extension = entry.name.split('.').pop()
    // check if the file is blacklisted
    if (extension && BLACKLISTEDEXTENSIONS.includes(extension!.toLowerCase())) {
      console.warn('File is blacklisted: ', entry.name)
      return
    }

    if (entry.isFile) {
      const fullpath = concatPaths(path, entry.fullPath)
      const file = await new Promise<File>((resolve) => entry.file(resolve))
      const newfile = new CustomFile(
        [file],
        entry.name,
        { type: file.type },
        fullpath,
      )
      files.push(newfile)
    } else if (entry.isDirectory) {
      // remove ".git" directories
      if (entry.name === '.git' || entry.name === '.idea') {
        console.warn('Folder is blacklisted: ', entry.name)
        return
      }
      const dirContent = await processDirectory(entry, path) // + entry.name + '/');
      files.push(...dirContent)
    }
  }

  const directoryReader =
    directoryEntry.createReader() as CustomFileSystemDirectoryReader
  const entries: CustomFileSystemEntry[] = await new Promise((resolve) =>
    directoryReader.readEntries(resolve),
  )

  await Promise.all(entries.map((entry) => processEntry(entry)))

  return files
}

function concatPaths(path1: string, path2: string) {
  // if path1 ends with a slash and path2 starts with a slash, remove one of them
  if (path1.endsWith('/') && path2.startsWith('/')) {
    return path1 + path2.substring(1)
  }
  // if path1 ends with a slash or path2 starts with a slash, concat them
  if (path1.endsWith('/') || path2.startsWith('/')) {
    return path1 + path2
  }
  // if neither of them has a slash, add one
  return path1 + '/' + path2
}
