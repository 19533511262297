import axiosClient from '../utils/axiosClient'
import { iAssistantModel } from '../interfaces/iAssistantModel.ts'

class WebSocketService {
  private static instance: WebSocketService

  private constructor() {}

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService()
    }
    return WebSocketService.instance
  }

  public async connectSignalR(
    userId: string,
  ): Promise<{ url: string; accessToken: string }> {
    // add user id to the request header
    const response = await axiosClient.post('/ws/negotiate?userid=' + userId)
    return response.data
  }

  public async getAssistantModels(scopes: string[]): Promise<iAssistantModel[]> {
    const scopesQuery = scopes.join(',')
    const response = await axiosClient.get(`/models?scopes=${scopesQuery}`)
    return response.data
  }
}

export default WebSocketService.getInstance()
