import { createWithEqualityFn } from 'zustand/traditional'
import SessionService from '@/services/sessionService.ts'
import AssistantService from '@/services/assistantService.ts'
import FolderState from './folderStore.ts'
import { iSession } from '@/interfaces/iSession.ts'
import { iSourceFileCitation } from '@/interfaces/iSourceFileCitations.ts'
import { iTool } from '@/interfaces/iTool.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { iFolder } from '@/interfaces/iFolder.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { mergeArrays } from '@/utils/mergeArrays.ts'
import { iSessionState, iSessionStore } from '@/interfaces/stores/iSessionStore.ts'
import { SessionDisplayType } from '@/enums/SessionDisplayType.ts'
import ChatStore from '@states/chatStore.ts'

const initialState: iSessionState = {
  storeIsInitialized: false,
  isLoading: true,
  sessions: [],
}
const useSessionStore = createWithEqualityFn<iSessionStore>(
  (set, getState): iSessionStore => ({
    ...initialState,
    // set store to default values
    reset: () => {
      set(initialState)
    },
    // contains all important steps to init state
    init: ({ teamId }) => {
      // 1. reset state
      getState().reset()
      // 2. load sessions
      getState()
        .getSessions({ teamId })
        .then(() => {
          set({ storeIsInitialized: true })
        })
    },
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    setSessions: (sessions: iSession[]) => {
      set(() => ({ sessions }))
    },

    fetchSessionByFolder: async (
      folderId: string,
      options?: { limit: number; offset: number },
    ) => {
      const sessions = await SessionService.getSessions({
        folderId,
        limit: options?.limit,
        offset: options?.offset,
      })
      const currentSessions = getState().sessions
      const newArray = mergeArrays<iSession>(sessions.data, currentSessions, 'id')
      set(() => ({ sessions: newArray }))
      return sessions
    },
    getSessionsByFolder: (folder: iFolder) => {
      return getState().sessions.filter((session) => session.folderId === folder.id)
    },
    updateSessionInternal: (session: iSession) => {
      const sessionIndex = (getState().sessions || []).findIndex(
        (s) => s.id === session.id,
      )
      if (sessionIndex > -1) {
        getState().sessions[sessionIndex] = session
        getState().setSessions([...getState().sessions])
      }
    },
    addSession: async (session: iSession) => {
      getState().setIsLoading(true)
      try {
        const s = await SessionService.createSession(session)
        getState().setSessions([...(getState().sessions || []), s])
        await ChatStore.getState().activateChatSession(s)
        const folder = (FolderState.getState().folders || []).find(
          (folder) => folder.id === session.folderId,
        )
        if (folder) {
          const ssId = folder.settingsId
          if (ssId) {
            const assistantSettings = await AssistantService.getAssistant(ssId!)
            assistantSettings.id = s?.assistantId
            assistantSettings.blueprint = false
            s._assistant = assistantSettings

            session!._assistant = assistantSettings
            await getState().updateSession(session!)
          }
        }
        getState().setIsLoading(false)
        return s
      } catch (error) {
        console.error('Error: ', error)
      } finally {
        getState().setIsLoading(false)
      }
    },
    updateSession: async (session: iSession) => {
      getState().updateSessionInternal(session)
      try {
        await SessionService.updateSession(session)
        if (session._assistant) {
          await AssistantService.updateAssistant(session._assistant)
        }
      } catch (error) {
        console.error('Error: ', error)
      }
    },
    removeSessionsByFolder: (folder: iFolder) => {
      const newSessions = getState().sessions.filter(
        (session) => session.folderId !== folder.id,
      )
      getState().setSessions(newSessions)
      return newSessions
    },
    deleteSession: async (session: iSession) => {
      //TODO: handle delete active session
      if (ChatStore.getState().session?.id === session.id)
        ChatStore.getState().setIsLoadingSession(true)
      try {
        await SessionService.deleteSession(session.id!)
        const sessions = getState().sessions
        const newSessions = sessions.filter(
          (prevSession) => prevSession.id !== session.id,
        )
        getState().setSessions(newSessions)
        const activeSession = ChatStore.getState().session
        if (!sessions) return console.error('no sessions found')
        if (session.id === activeSession?.id) {
          // best fit (last modified) in folder or first session
          const lastModifiedSession = getLastModifiedSession(newSessions)
          if (lastModifiedSession) {
            await ChatStore.getState().activateChatSession(lastModifiedSession)
          } else {
            // nothing found to set as new active chat session -> reset chat store
            ChatStore.getState().reset()
          }
        }
      } catch (error) {
        console.error('Error: ', error)
        getState().setIsLoading(false)
      } finally {
        getState().setIsLoading(false)
      }
    },

    getSession: async (sessionId) => {
      return await SessionService.getSession(sessionId)
    },
    getSessions: async ({ teamId, sessionId }) => {
      getState().setIsLoading(true)
      try {
        const loadedSessions = await SessionService.getSessions({ teamId })
        getState().setSessions(loadedSessions.data)
        return loadedSessions.data
      } catch (error) {
        console.error(error)
      } finally {
        getState().setIsLoading(false)
      }
    },

    // getTools: async (assistantId?: string) => {
    //   return ToolService.getTools({ baseSettingsId: assistantId }).then((tools) => {
    //     getState().setAllTools(tools.data)
    //     return tools.data
    //   })
    // },
    // getKnowledgeContainers: async (assistantId?: string) => {
    //   return knowledgeContainerService
    //     .getKnowledgeContainerContainerList(assistantId)
    //     .then((response) => {
    //       getState().setAllKnowledgeContainers(response.data)
    //       return response.data
    //     })
    // },
  }),
)

export default useSessionStore

export const getLastModifiedSession = (sessions: iSession[]) => {
  // if no session is active, activate the last modified session

  const lastModified = sessions.sort((a, b) => {
    const dateA = a.modified instanceof Date ? a.modified : new Date(a.modified!)
    const dateB = b.modified instanceof Date ? b.modified : new Date(b.modified!)
    return dateB.getTime() - dateA.getTime()
  })[0]
  return lastModified
}
