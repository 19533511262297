import React, { Key, useEffect, useState } from 'react'
import { ArrowUturnLeftIcon, TrashIcon } from '@heroicons/react/24/outline'
import useChatStore from '@states/chatStore.ts'

import { FileIcon } from 'react-file-icon'
import { Button } from '@nextui-org/react'
import { shallow } from 'zustand/shallow'
import { deepCopy } from '../../utils/deepCopy.ts'
import {
  iFileData,
  iFileExtended,
  iFileViewer,
  iNode,
} from '../../interfaces/iFile.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'

function FileViewer({ iMessage, closeFunction }: iFileViewer) {
  const [lineHovered, setLineHovered] = useState<iNode | string>('')
  const [fileNodes, setFileNodes] = useState<iNode[]>([])
  const { session, updateMessage } = useChatStore(
    (state) => ({
      session: state.session,
      updateMessage: state.updateMessage,
    }),
    shallow,
  )
  const files = deepCopy(iMessage.filesData! as iFileExtended[])

  // constructor
  useEffect(() => {
    const fN = createNodeStructure(files)
    setFileNodes(fN)
  }, [])

  const createNodeStructure = (files: iFileExtended[]) => {
    const nodes: iNode[] = []

    files.forEach((file) => {
      const path = file.name
      const pathParts = path.split('/')

      const fileName = pathParts.pop()

      // Add all path parts as folders
      let currentFolder = nodes
      pathParts.forEach((part) => {
        let existingNode = currentFolder.find((node) => node.name === part)

        if (!existingNode) {
          existingNode = {
            name: part,
            path:
              currentFolder.length > 0 ? `${currentFolder[0].path}/${part}` : part,
            children: [],
            isFolder: true,
            deleted: false,
          }
          currentFolder.push(existingNode)
        }

        currentFolder = existingNode.children
      })

      // Add file to the deepest folder
      currentFolder.push({
        name: fileName!,
        path: path,
        children: [],
        isFolder: false,
        deleted: false,
      })
    })

    return nodes
  }

  const markAsDeleted = (node: iNode) => {
    // if folder, mark all children as deleted
    if (node.isFolder) {
      node.children.forEach((child) => markAsDeleted(child))
    }
    node.deleted = true
    setFileNodes([...fileNodes])
  }

  const resetDeleted = (node: iNode) => {
    // if folder, reset all children as deleted
    if (node.isFolder) {
      node.children.forEach((child) => resetDeleted(child))
    }
    node.deleted = false
    setFileNodes([...fileNodes])
  }

  const handleLineMouseEnter = (node: iNode) => {
    setLineHovered(node)
  }
  const handleLineMouseLeave = (node: iNode) => {
    if (lineHovered === node) setLineHovered('')
  }

  const handleSave = () => {
    // Filter out the deleted files from the fileNodes
    const updatedFilesData: iFileData[] = []
    const collectFiles = (nodes: iNode[], path: string = '') => {
      nodes.forEach((node) => {
        const fullPath = path ? `${path}/${node.name}` : node.name
        if (!node.deleted) {
          if (node.isFolder) {
            // Recursively collect files from children if it's a folder
            collectFiles(node.children, fullPath)
          } else {
            // Find the corresponding file in the original files array
            const fileToKeep = files.find(
              (file: { name: string }) => file.name === fullPath,
            )
            if (fileToKeep) {
              // Add the file to the updatedFilesData list
              updatedFilesData.push(fileToKeep)
            }
          }
        }
      })
    }

    // Start collecting files from the root nodes
    collectFiles(fileNodes)

    // Update the iMessage object's filesData property
    iMessage.filesData = updatedFilesData

    // save
    updateMessage(iMessage).then(() => {
      closeFunction()
    })
  }

  const renderFileNode = (node: iNode) => {
    const isFolder = node.children.length > 0
    const key: Key = node.path

    return (
      <React.Fragment key={key}>
        {isFolder ? (
          <li
            onMouseEnter={() => handleLineMouseEnter(node)}
            onMouseLeave={() => handleLineMouseLeave(node)}
          >
            <details open>
              <summary
                onMouseEnter={() => handleLineMouseEnter(node)}
                onMouseLeave={() => handleLineMouseLeave(node)}
              >
                <span className={'flex justify-between col-start-1 col-end-6'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                      />
                    </svg>
                    <span
                      className={`ml-2 ${node.deleted ? 'line-through text-rose-600' : ''} 
                                    `}
                    >
                      {node.name}
                    </span>
                    {node.deleted && (
                      <span className={'text-xs italic ml-2'}>deleted</span>
                    )}
                  </div>
                  {lineHovered === node && (
                    <div className={'height16'}>
                      {node.deleted && (
                        <Button
                          onPress={() => resetDeleted(node)}
                          isIconOnly
                          variant="light"
                          className="mr-2"
                        >
                          <ArrowUturnLeftIcon className="h-4 w-4" />
                        </Button>
                      )}
                      {!node.deleted && (
                        <Button
                          isIconOnly
                          variant="light"
                          className="mr-2"
                          onPress={() => markAsDeleted(node)}
                        >
                          <ICON_DELETE />
                        </Button>
                      )}
                    </div>
                  )}
                </span>
              </summary>
              <ul>{node.children.map((child) => renderFileNode(child))}</ul>
            </details>
          </li>
        ) : (
          <li
            onMouseEnter={() => handleLineMouseEnter(node)}
            onMouseLeave={() => handleLineMouseLeave(node)}
          >
            <span className={'flex justify-between h-[26px]'}>
              <a style={{ display: 'flex', alignItems: 'center' }}>
                <div className={'h-4 w-4'}>
                  <FileIcon
                    extension={
                      node.name.split('.').pop()!
                      // {...defaultStyles[file.extension]}
                    }
                  />
                </div>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
              
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg> */}
                <span
                  className={`ml-2 ${node.deleted ? 'line-through text-rose-600' : ''} `}
                >
                  {node.name}
                </span>
                {node.deleted && (
                  <span className={'text-xs italic ml-2'}>deleted</span>
                )}
              </a>
              {lineHovered === node && (
                <div className={'mr-4'}>
                  {!node.deleted && (
                    <Button
                      isIconOnly
                      variant="light"
                      className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 h-[26px]"
                      onPress={() => markAsDeleted(node)}
                    >
                      <ICON_DELETE />
                    </Button>
                  )}
                  {node.deleted && (
                    <Button
                      isIconOnly
                      variant="light"
                      className="btn btn-ghost btn-xs btn-circle marginMinus mr-2 h-[26px]"
                      onPress={() => resetDeleted(node)}
                    >
                      <ArrowUturnLeftIcon className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              )}
            </span>
          </li>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      <div>
        <h1 className={'text-xl font-semibold mb-2'}>Manage Files</h1>
        <p className={''}>Inspect and delete files, which are added as message. </p>
        <p>
          Hover over the files to manage them. Deleted files must be added manually
          again
        </p>
      </div>

      {
        <>
          <div className="mt-3">
            <div className="overflow-auto no-scrollbar">
              <ul className="menu menu-xs bg-base-200 rounded-lg w-full max-h-80 overflow-auto flow-root">
                {fileNodes &&
                  fileNodes.length > 0 &&
                  fileNodes.map((node) => renderFileNode(node))}
              </ul>
            </div>
          </div>
        </>
      }
      <div className={'text-right mt-2'}>
        <Button variant={'light'} onPress={closeFunction}>
          Cancel
        </Button>
        {
          <Button className="ml-3" onPress={handleSave}>
            Save changes
          </Button>
        }
      </div>
    </>
  )
}

export default FileViewer
