import React, { useMemo, useState } from 'react'
import { Avatar, Button, Card, CardBody, Input, Spinner } from '@nextui-org/react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import useSessionStore from '@states/sessionStore.ts'
import useChatStore from '@states/chatStore.ts'
import { shallow } from 'zustand/shallow'
import { useLocation, useNavigate } from 'react-router-dom'
import SidenavItem from '../basic/sidenav/SidenavItem.tsx'
import { iSession } from '../../interfaces/iSession.ts'
import OptionsMenu from '../basic/optionsMenu/OptionsMenu.tsx'
import useMainStore from '@states/mainState.ts'
import { ICON_DELETE } from '@/constants/icons.tsx'

type SessionItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  session: iSession
}
const SessionListItem = ({ session }: SessionItemProps) => {
  const navigate = useNavigate()
  const { deleteSession, updateSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      deleteSession: state.deleteSession,
    }),
    shallow,
  )

  const setIsSidebarContentOpen = useMainStore().setIsSidebarContentOpen
  const { activateChatSession, activeSession } = useChatStore(
    (state) => ({
      activateChatSession: state.activateChatSession,
      activeSession: state.session,
    }),
    shallow,
  )
  const location = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editingSession, setEditingSession] = useState<boolean>()

  const isSessionActive = useMemo(() => {
    const isActive = activeSession?.id === session.id && location.pathname === '/'
    return isActive
  }, [activeSession, session, location])

  const handleSessionDelete = async (session: iSession) => {
    if (!confirm('Are you sure you want to delete this session?')) {
      return
    }
    setIsLoading(true)
    await deleteSession(session)
    setIsLoading(false)
  }

  const handleSessionEdit = async (sessionId: string, newName: string) => {
    if (!session) return
    setIsLoading(true)
    setEditingSession(false)

    session.title = newName
    session.automaticTitle = false

    if (
      session.title === '' ||
      session.title === ' ' ||
      session.title.length === 0
    ) {
      session.title = 'Untitled'
      session.automaticTitle = true
    }

    setTimeout(
      () =>
        updateSession(session)
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false)),
      100,
    )
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      const temp = document.activeElement as HTMLElement
      temp && temp.blur()
    }
  }

  const selectSession = () => {
    if (!isSessionActive && !isLoading) {
      setIsLoading(true)
      navigate('/')
      setIsSidebarContentOpen(false)
      activateChatSession(session).then(() => {
        setIsLoading(false)
      })
    }
  }

  return (
    <Card
      isPressable
      className={`relative overflow-visible h-fit w-full py-4 px-1 ${isSessionActive ? 'bg-primary text-primary-foreground' : ''}`}
    >
      <CardBody>
        {editingSession ? (
          <Input
            type="text"
            className={'w-full'}
            defaultValue={session.title}
            readOnly={isLoading}
            onKeyDown={handleKeyDown}
            onBlur={(e) =>
              handleSessionEdit(
                session.id!,
                (e as React.FocusEvent<HTMLInputElement>).target.value,
              )
            }
            autoFocus={true}
          />
        ) : (
          <Button
            variant={'flat'}
            onPress={selectSession}
            disableRipple
            radius="none"
            className={'pr-0 pl-0 h-fit w-full bg-transparent -hover'}
          >
            <div
              className={`flex justify-between w-full ${isSessionActive ? 'text-primary-foreground' : ''}`}
            >
              <>
                <div className={'w-4/5 text-start'}>
                  <div>
                    {/* {session.title.substring(0, 20) + (session.title.length > 20 ? "..." : "")} */}
                    <div className="truncate chat-title">{session.title}</div>
                    <div className="truncate chat-footer opacity-50 ">
                      {session?.modified?.toLocaleString()}
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <OptionsMenu
                    items={[
                      {
                        label: 'Rename',
                        method: async () => setEditingSession(true),
                        icon: <PencilSquareIcon className={'h-5 w-5'} />,
                        showDivider: true,
                      },
                      {
                        label: 'Delete',
                        method: async () => await handleSessionDelete(session),
                        icon: <ICON_DELETE />,
                        className: 'text-danger',
                      },
                    ]}
                  />
                )}
              </>
            </div>
          </Button>
        )}
      </CardBody>
    </Card>
  )
}

export default SessionListItem
