import React, { useState } from 'react'
import { iFile } from '@/interfaces/iSourceFileCitations.ts'
import { iMessage } from '@/interfaces/iMessage.ts'
import { Badge, Tab, Tabs } from '@nextui-org/react'
import { ToolCall } from '@components/toolCalls/toolCall.tsx'
import { iDocumentChunk } from '@/interfaces/iDocumentChunk.ts'

export type ChatMessageViewProps = {
  navigation?: any
  message: iMessage
  selectedTab?: 'general' | 'artifacts' | 'toolCalls'
  selectedCitation?: iDocumentChunk
}

const ChatMessageView = ({
  navigation,
  message,
  selectedTab,
  selectedCitation,
}: ChatMessageViewProps) => {
  const [file, setFile] = useState<iFile>()
  const [isLoading, setIsLoading] = useState<boolean>()

  return (
    <Tabs variant="underlined" defaultSelectedKey={selectedTab}>
      <Tab key={'general'} title={'General'}>
        <div>completionTokenCount:{message.completionTokenCount}</div>
        <div>totalTokenCount:{message.totalTokenCount}</div>
        <div>messageTokenCount:{message.messageTokenCount}</div>
        <div>promptTokenCount:{message.promptTokenCount}</div>
      </Tab>
      <Tab
        key={'artifacts'}
        title={
          <Badge content={message.toolCalls?.length}>
            <div className="flex items-center space-x-2">
              <span>Documents</span>
            </div>
          </Badge>
        }
      ></Tab>
      <Tab
        key={'toolCalls'}
        title={
          <Badge content={message.toolCalls?.length}>
            <div className="flex items-center space-x-2">
              <span>Tool-Calls</span>
            </div>
          </Badge>
        }
      >
        {message.toolCalls?.map((t) => {
          return <ToolCall initialToolCall={t}></ToolCall>
        })}
      </Tab>
    </Tabs>
  )
}

export default ChatMessageView
