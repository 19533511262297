import {
  ArrowPathIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CloudArrowDownIcon,
  DocumentMagnifyingGlassIcon,
  DocumentPlusIcon,
  FolderIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusIcon,
  PuzzlePieceIcon,
  ShareIcon,
  SwatchIcon,
  TrashIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import React, { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import { CiWarning } from 'react-icons/ci'
import { FaSave } from 'react-icons/fa'
import { BiCheck, BiSave } from 'react-icons/bi'
import { CloseIcon } from '@nextui-org/shared-icons'
import CheckIcon from '@heroicons/react/24/outline/CheckIcon'
type IconProps = HTMLAttributes<HTMLDivElement> & {}

const defaultClassName = 'w-5 h-5'
export const ICON_ARTIFACT = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <DocumentMagnifyingGlassIcon className={classes} />
}
export const ICON_CANVAS_VIEW = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <DocumentPlusIcon className={classes} />
}
export const ICON_ASSISTANT = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <SwatchIcon className={classes} />
}
export const ICON_TOOL = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <PuzzlePieceIcon className={classes} />
}
export const ICON_KNOWLEDGE_CONTAINER = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <CloudArrowDownIcon className={classes} />
}

export const ICON_SHARE = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <ShareIcon className={classes} />
}

export const ICON_TEAM = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <UserGroupIcon className={classes} />
}

export const ICON_FOLDER = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <FolderIcon className={classes} />
}

export const ICON_CHAT = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <ChatBubbleOvalLeftEllipsisIcon className={classes} />
}

export const ICON_CREATE = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <PlusIcon className={classes} />
}

export const ICON_DELETE = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <TrashIcon className={classes} />
}

export const ICON_SEARCH = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <MagnifyingGlassIcon className={classes} />
}

export const ICON_EDIT = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <PencilSquareIcon className={classes} />
}

export const ICON_ADD = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <PlusCircleIcon className={classes} />
}

export const ICON_REFRESH = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <ArrowPathIcon className={classes} />
}

export const ICON_WARNING = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <CiWarning className={classes} />
}

export const ICON_SAVE = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <CheckIcon className={classes} />
}

export const ICON_CLOSE = ({ className }: IconProps) => {
  const classes = twMerge(defaultClassName, className)
  return <CloseIcon className={classes} />
}
