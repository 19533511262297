import { Skeleton } from '@nextui-org/react'
import React from 'react'

const SkeletonDetailPage = () => {
  return (
    <>
      <div className="flex flex-row gap-1">
        <Skeleton className="rounded-full h-12 w-12"></Skeleton>
        <div className="flex flex-col items-start truncate ml-2">
          <Skeleton className="rounded-large h-6 w-32"></Skeleton>
          <Skeleton className="rounded-large h-6 w-32"></Skeleton>
        </div>
      </div>
      <Skeleton className="rounded-large mt-6 h-10 w-56"></Skeleton>

      <Skeleton className="rounded-large mt-6 h-10 w-20"></Skeleton>
      <Skeleton className="rounded-large mt-6 h-10 w-full"></Skeleton>

      <Skeleton className="rounded-large mt-6 h-10 w-20"></Skeleton>
      <Skeleton className="rounded-large mt-6 h-36 w-full"></Skeleton>
    </>
  )
}

export default SkeletonDetailPage
