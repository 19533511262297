import React, { useMemo, useState } from 'react'
import { Avatar, Select, SelectItem } from '@nextui-org/react'
import { useSearchParams } from 'react-router-dom'
import useTeamStore from '@/states/teamStore.ts'
import { twMerge } from 'tailwind-merge'
import { SelectSlots, SlotsToClasses } from '@nextui-org/theme'
import { shallow } from 'zustand/shallow'
import TeamIcon from './TeamIcon.tsx'
import { isDefaultTeam, iTeam } from '@/interfaces/iTeam.ts'
import UserService from '@/services/UserService.ts'

type TeamSelectProps = React.HTMLAttributes<HTMLDivElement> & {
  onSelectionChange?: (team: iTeam) => void
  selectedKeys?: 'all' | Iterable<number | string> | undefined
  validate?: (
    value: string | string[],
  ) => string | string[] | true | null | undefined
  withoutDefaultTeam?: boolean
  isIconOnly?: boolean
  withoutTeamIcon?: boolean
  withoutCostcenter?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  classNames?: SlotsToClasses<SelectSlots>
}

const TeamSelect = ({
  onSelectionChange,
  withoutDefaultTeam,
  isIconOnly,
  withoutTeamIcon,
  withoutCostcenter,
  isRequired,
  validate,
  isDisabled,
  selectedKeys,
  className,
  classNames,
}: TeamSelectProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    teams,
    selectedTeam,
    setSelectedTeam,
    isLoading: isTeamLoading,
  } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      setSelectedTeam: state.setSelectedTeam,
      teams: state.teams,
      isLoading: state.isLoading,
    }),
    shallow,
  )

  const classes = twMerge(className)

  const handleSelectionChange = async (id: string) => {
    const team = teams?.find((team) => team.id === id)
    if (onSelectionChange && team) {
      onSelectionChange(team)
    } else if (team) {
      setIsLoading(true)
      await setSelectedTeam(team).then(() => setIsLoading(false))
      UserService.setLastTeamId(team.id!)
    }
  }

  const selectedTeamValues: 'all' | Iterable<string | number> | undefined = useMemo(
    () =>
      selectedKeys
        ? selectedKeys
        : selectedTeam
          ? ([selectedTeam.id?.toString()] as Iterable<string | number>)
          : new Set(),
    [selectedTeam, selectedKeys],
  )

  const selectLabel = useMemo(() => {
    if (isTeamLoading) {
      return 'Loading teams...'
    } else if (teams?.length) {
      return 'Select a team'
    } else if (!teams?.length) {
      return 'No teams available'
    }
    return ''
  }, [teams])

  const teamItems = useMemo(() => {
    const tmpTeams = teams ?? []
    tmpTeams.sort((x, y) => (isDefaultTeam(x) ? -1 : isDefaultTeam(y) ? 1 : 0))
    return tmpTeams.filter((team: iTeam) =>
      withoutDefaultTeam ? !isDefaultTeam(team) : true,
    )
  }, [teams])

  return (
    <Select
      selectedKeys={selectedTeamValues}
      isRequired={isRequired}
      validate={validate}
      items={teamItems}
      isLoading={isTeamLoading || isLoading}
      isDisabled={isTeamLoading || isDisabled}
      placeholder={!isIconOnly ? selectLabel : ''}
      aria-label={selectLabel}
      labelPlacement="outside"
      className={classes}
      classNames={{
        ...classNames,
        trigger: twMerge('h-12', classNames?.trigger),
        base: twMerge('overflow-hidden', classNames?.base),
      }}
      onChange={(e) => handleSelectionChange(e.target.value)}
      renderValue={(items) => {
        const teams = items.map((item) => (
          <div key={item.data!.id} className="flex items-center ">
            {!withoutTeamIcon && (
              <TeamIcon
                isLoading={isLoading || isTeamLoading}
                team={item.data!}
              ></TeamIcon>
            )}
            {!isIconOnly && (
              <div className="flex flex-col truncate">
                <span className="truncate">{item.data!.name}</span>
                {!withoutCostcenter && (
                  <span className="text-default-500 text-tiny truncate">
                    (Cost-Center: {item.data!.costCenter})
                  </span>
                )}
              </div>
            )}
          </div>
        ))
        return teams
      }}
    >
      {(team) => (
        <SelectItem key={team.id!} textValue={team.id} value={team.id}>
          <div className="flex gap-2 items-center">
            <TeamIcon isLoading={isLoading || isTeamLoading} team={team}></TeamIcon>
            <div className="flex flex-col truncate">
              <span className="text-small truncate">{team.name}</span>
              <span className="text-tiny text-default-400 truncate">
                {team.costCenter}
              </span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  )
}

export default TeamSelect
