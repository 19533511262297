// FilePicker.tsx
import React, { ChangeEvent } from 'react'
import { Input } from '@nextui-org/react'

type FilePickerProps = {
  onFilesSelect: (files: FileList) => void
  forFiles: boolean
  isDisabled?: boolean
}

const FilePicker = ({ onFilesSelect, forFiles, isDisabled }: FilePickerProps) => {
  const handleFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onFilesSelect(event.target.files)
      // reset input field
      event.target.value === null
    }
  }

  return (
    <>
      <input
        className="file-input w-full max-w-xs file-input-sm file-input-primary"
        disabled={isDisabled}
        type="file"
        id={forFiles ? 'file-picker' : 'folder-picker'}
        onChange={handleFilesChange}
        webkitdirectory={!forFiles ? 'true' : undefined}
        dir={!forFiles ? 'true' : undefined}
        multiple={true}
        style={{ display: 'none' }}
      />
      <p
        onClick={() =>
          document
            .getElementById(forFiles ? 'file-picker' : 'folder-picker')
            ?.click()
        }
        className={'text-blue-500 hover:underline cursor-pointer'}
      >
        browse for {forFiles ? 'files' : 'folders'}
      </p>
    </>
  )
}

declare module 'react' {
  interface InputHTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    webkitdirectory?: any
  }
}

export default FilePicker
