import { useState } from 'react'
import { iToolCallHttpContext } from '../../interfaces/iMessage.ts'
import { JSONDisplay } from '../basic/jsonViewer/JSONDisplay.tsx'

type HttpToolCallProps = {
  httpContext: iToolCallHttpContext
}

export const HttpToolCall = ({ httpContext }: HttpToolCallProps) => {
  const [context, setContext] = useState(httpContext)

  return (
    <div>
      {context.requestCompileError && (
        <div>
          <h3>Request Compile Error</h3>
          <pre>{context.requestCompileError}</pre>
        </div>
      )}
      {context.request && (
        <>
          <h3 className="mt-2 text-xl text-primary">Request</h3>
          <div className="ml-4">
            <div className="mt-1 text-lg text-primary font-mono">
              <span>{context.request.method}</span>
              <span>{context.request.url}</span>
            </div>
            <details className="mt-1">
              <summary className="text-lg text-primary">Headers</summary>
              {Object.entries(context.request.headers).map(([key, value]) => (
                <div className="pl-4 text-base" key={key}>
                  <span className="font-bold text-primary">{key}:</span>
                  <span className="text-slate-600">{value}</span>
                </div>
              ))}
            </details>
            {context.request.body && (
              <details className="mt-1">
                <summary className="text-lg text-primary">Body</summary>
                <JSONDisplay jsonString={context.request.body} />
              </details>
            )}
          </div>
        </>
      )}
      {context.requestError && (
        <div className="mt-1 text-error">
          <span>Request Error:</span> {context.requestError}
        </div>
      )}
      {context.response && (
        <div>
          <h3 className="mt-2 text-xl text-primary">Response</h3>
          <div className="mt-1 ml-4">
            <div className="text-lg text-primary font-mono">
              <span>Status Code:</span>
              <span
                className={context.responseError ? 'text-error' : 'text-success'}
              >
                {context.response.statusCode}
              </span>
            </div>
            <details className="mt-1">
              <summary className="text-lg text-primary">Headers</summary>
              {Object.entries(context.response.headers).map(([key, value]) => (
                <div className="pl-4 text-base" key={key}>
                  <span className="font-bold text-primary">{key}:</span>
                  <span className="text-slate-600">{value}</span>
                </div>
              ))}
            </details>
            <details className="mt-1">
              <summary className="text-lg text-primary">Body</summary>
              <JSONDisplay
                jsonString={context.responseError || context.response.body}
              />
            </details>
          </div>
        </div>
      )}
    </div>
  )
}
