import React, { useCallback, useMemo } from 'react'
import { Skeleton } from '@nextui-org/react'
import { iFolder } from '@/interfaces/iFolder.ts'
import useFolderStore from '@states/folderStore.ts'
import { shallow } from 'zustand/shallow'
import useSessionStore from '@states/sessionStore.ts'
import useTeamStore from '@/states/teamStore.ts'
import SessionFolderListItem from '@components/session/SessionFolderItem.tsx'

type SessionFolderListProps = React.HTMLAttributes<HTMLDivElement> & {
  searchValue?: string
}
function SessionFolderList({ searchValue = '' }: SessionFolderListProps) {
  const sessions = useSessionStore().sessions
  const { folders, isLoading: isFoldersLoading } = useFolderStore(
    (state) => ({
      folders: state.folders,
      isLoading: state.isLoading,
    }),
    shallow,
  )

  const selectedTeam = useTeamStore().selectedTeam

  const filteredSessions = useCallback(
    (folder: iFolder) => {
      return Array.from(sessions)
        .sort((a, b) => (a.modified! > b.modified! ? -1 : 1))
        .filter((session) => session.folderId === folder.id)
        .filter((session) => session.title.toLowerCase().indexOf(searchValue) >= 0)
    },
    [searchValue, sessions],
  )
  const filteredFolders = useMemo(() => {
    return Array.from(folders!).filter(
      (folder) => !searchValue.length || filteredSessions(folder).length,
    )
  }, [filteredSessions, folders])

  return (
    <Skeleton isLoaded={!isFoldersLoading} className="min-h-12 w-full rounded-md ">
      <div className="flex gap-2 flex-col">
        {filteredFolders.map((folder) => (
          <SessionFolderListItem
            key={folder.id}
            folder={folder}
            selectedTeam={selectedTeam!}
          ></SessionFolderListItem>
        ))}
      </div>
    </Skeleton>
  )
}

export default SessionFolderList
