import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckIcon, MicrophoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon'
import documentService from '../../services/documentService.ts'

import { Avatar, Button, Skeleton, Spinner } from '@nextui-org/react'
import './chatMessage.css'
import ChatMessageHeader from './ChatMessageHeader.tsx'
import 'katex/dist/katex.css'
import GaiaLogo from '../basic/logo/GaiaLogo.tsx'
import { ContentItemType, iMessage } from '@/interfaces/iMessage.ts'
import Markdown from '../markdown/Markdown.tsx'
import ProfileImage from '../basic/profileImage/ProfileImage.tsx'
import { iUser } from '@/interfaces/iUser.ts'
import FileViewer from '../files/FileViewer.tsx'
import FeedbackWidget from '@components/feedback/FeedbackWidget.tsx'
import useChatStore from '@states/chatStore.ts'

import { shallow } from 'zustand/shallow'
import { iDocument } from '@/interfaces/iFile.ts'
import { ChatMessageViewProps } from '@components/session/sessionChatControlViews/ChatMessageView.tsx'

type ChatMessageProps = {
  message: iMessage
  user: iUser
  transfered?: boolean
  isLastMessage?: boolean
}

function ChatMessage({
  message,
  user,
  transfered,
  isLastMessage,
}: ChatMessageProps) {
  const {
    session,
    isAssistantRunning,
    assistant,
    updateMessage,
    deleteMessage,
    isAssistantGenerating,
    setChatControlContentRoute,
  } = useChatStore(
    (state) => ({
      session: state.session,
      updateMessage: state.updateMessage,
      deleteMessage: state.deleteMessage,
      assistant: state.assistant,
      isAssistantRunning: state.isAssistantRunning,
      isAssistantGenerating: state.isAssistantGenerating,
      setChatControlContentRoute: state.setChatControlContentRoute,
    }),
    shallow,
  )
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [messageContent, setMessageContent] = useState([] as string[])
  const [contentLoaded, setContentLoaded] = useState(false)

  const convertMessage = async (message: iMessage): Promise<string[]> => {
    if (message.content) {
      return [message.content]
    }

    if (message.contentItems) {
      return await Promise.all(
        message.contentItems.map(async (item) => {
          if (item.type === ContentItemType.Text) {
            return item.content
          }
          if (item.type === ContentItemType.Image) {
            try {
              const imageBlob = await documentService.downloadDocument(item.content)
              return await readAsDataURL(imageBlob)
            } catch (error) {
              return ''
            }
          }
          if (item.type === ContentItemType.Document) {
            const document: iDocument = JSON.parse(item.content)
            if (!document.id) return item.content
            return `\`\`${document.metadata?.attributes?.fileName || 'Document'} (${document.size?.tokenCount} tokens)\`\``
          }
          return ''
        }),
      )
    }
    return []
  }

  useEffect(() => {
    setContentLoaded(false)
    if (message.role === 'assistant') {
      setMessageContent(message.content ? [message.content] : [])
      setContentLoaded(true)
    } else {
      convertMessage(message).then((content) => {
        setMessageContent([...content!])
        setContentLoaded(true)
      })
    }
  }, [message.content])

  const messageError = useMemo(() => {
    if (isAssistantGenerating && isLastMessage) return ''
    if (message.error) return message.error.message
    if (!messageContent.length && message.id && contentLoaded)
      return 'Empty answer from Backend'
    // error messages just for the last message
    if (!isLastMessage) return
    if (!isLastMessage) return
    // TODO implement better error handling
    //if (error) return `${error} ||->> Error disappears after refreshing session`
    return ''
  }, [message, messageContent])

  const showCitation = useCallback(() => {
    console.log('showCitation')
    setChatControlContentRoute<ChatMessageViewProps>('artifacts', 'MessageDetail', {
      message: message,
      selectedTab: 'artifacts',
    })
  }, [])

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSaveEdit = async () => {
    //TODO: fix
    //message.content = editedMessage
    setIsEditing(false)
    await updateMessage(message)
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
  }

  const handleDelete = async () => {
    await deleteMessage(message)
  }

  function isDefaultExtensionType(key: string): key is DefaultExtensionType {
    return key in defaultStyles
  }

  const readAsDataURL = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(`<img class="object-scale-down" src="${reader.result}"/>`)
      }
      reader.onerror = () => reject('Failed to read blob as data URL.')
      reader.readAsDataURL(blob)
    })
  }

  const chatClass = useMemo(() => {
    return message.ownerId === user.id && message.role === 'user'
      ? 'chat-end'
      : 'chat-start'
  }, [])

  if (message.contentType === 'contentMessage') {
    return (
      <div>
        <div
          className="flex justify-center items-center m-2"
          style={{ userSelect: 'none' }}
        >
          {/* <button className="btn glass btn-xs"> */}
          <div
            className="shadow-2xl w-fit rounded-lg hover:bg-base-200 hover:shadow-md transition duration-200 ease-in-out transform border-secondary border-t-4"
            onClick={handleExpand}
          >
            {message.filesData && message.filesData.length > 0 ? (
              <div className="flex justify-center items-center space-x-4">
                <div style={{ width: '48px', margin: '16px' }} className="m-4 stack">
                  {message.filesData?.map((file, index) =>
                    isDefaultExtensionType(file.extension) ? (
                      <FileIcon
                        extension={file.extension}
                        // {...defaultStyles[file.extension]}
                        key={index}
                      />
                    ) : (
                      <FileIcon extension={file.extension} key={index} /> // Fallback without styles
                    ),
                  )}
                </div>
                <h2 className="text-xl font-bold text-center text-primary">
                  {message.filesData?.length > 1
                    ? message.filesData?.length + ' Files'
                    : message.filesData?.[0].name}
                </h2>

                <div className="justify-end">
                  <Button className="mx-4 hover:text-error" onPress={handleDelete}>
                    <XMarkIcon className="h-6 w-6" />
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {isExpanded && (
          <dialog id={'message_model_' + message.id} className={'modal modal-open'}>
            <div className={'bg-white modal-box w-fit max-w-[60vw]'}>
              <FileViewer iMessage={message} closeFunction={handleExpand} />
            </div>
          </dialog>
        )}
      </div>
    )
  }

  const getFullChatBubble = () => {
    let content = <></>
    // Hide the chat bubble if the message is hidden
    if (isEditing) {
      content = (
        <div style={{ minWidth: '100%', minHeight: '100%' }}>
          <div className="flex justify-end items-center space-x-4 mt-2">
            <Button
              variant={'light'}
              onPress={handleCancelEdit}
              startContent={<XMarkIcon className="h-6 w-6 text-red-500 " />}
            >
              Cancel
            </Button>
            <Button
              variant={'light'}
              onPress={handleSaveEdit}
              startContent={<CheckIcon className="h-6 w-6 text-green-500" />}
            >
              Save
            </Button>
          </div>
        </div>
      )
    } else if (message.isAudio) {
      content = (
        <MicrophoneIcon className="w-6 h-6  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
      )
    } else {
      // standard message
      content = (
        <div className="space-x-1 w-full">
          {messageContent.map((item, index) => {
            return item.startsWith('<img') ? (
              <div dangerouslySetInnerHTML={{ __html: item }} key={index} />
            ) : (
              <Markdown
                key={index}
                value={item}
                message={message}
                onClickCitation={showCitation}
              ></Markdown>
            )
          })}
        </div>
      )
    }

    // no tabs for user messages
    if (message.role === 'user') {
      return (
        <>
          <ChatMessageHeader
            session={session!}
            message={message}
            onEditClick={() => setIsEditing(true)}
            disableButtons={{
              delete: isAssistantRunning,
              copy: isAssistantRunning,
              edit: isAssistantRunning,
            }}
          ></ChatMessageHeader>
          <div
            className={`chat-bubble shadow-medium relative transition-background max-w-5/6 min-w-48 ${transfered ? '!bg-content1' : 'bg-gray-100'}`}
          >
            {content}
            {message._isLoading && (
              <Spinner
                size={'sm'}
                className={'rounded-full bg-white absolute right-[5px] -top-[15%]'}
              />
            )}
          </div>
        </>
      )
    }

    // message from assistant
    return (
      <>
        <ChatMessageHeader
          session={session!}
          message={message}
          disableButtons={{
            delete: isAssistantRunning,
            copy: isAssistantRunning,
            edit: isAssistantRunning,
            regenerate: isAssistantRunning,
            speech: isAssistantRunning,
          }}
          hideButtons={{
            regenerate: !isLastMessage,
          }}
          onEditClick={() => setIsEditing(true)}
        />
        <div
          className={`min-w-[90%] chat-bubble shadow-medium min-h-[43.39px] transition-background ${messageError && message.id ? 'bg-yellow-100' : transfered ? 'bg-content1' : 'bg-gray-100'}`}
        >
          <>
            {message.id || messageError ? (
              <>
                {content}
                {messageError && (
                  <span className="text-error rounded p-2 bg-yellow-100 italic font-bold">
                    {messageError}
                  </span>
                )}
              </>
            ) : (
              <Skeleton className="rounded-lg">
                <div className="h-[21px] rounded-lg bg-default-300"></div>
              </Skeleton>
            )}
          </>
        </div>
      </>
    )
  }

  return (
    <div
      className={`chat ${chatClass} group ${message.id ? '' : 'animate-create'} w-full px-2 md:px-4`}
    >
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          {message.role === 'user' ? (
            <ProfileImage contact={user.email!}></ProfileImage>
          ) : (
            <Avatar
              color={'primary'}
              className="flex-shrink-0"
              showFallback
              fallback={<GaiaLogo opposite className="w-6 h-6 text-default-500" />}
              size={'md'}
              src={assistant?.image}
            ></Avatar>
          )}
        </div>
      </div>
      {getFullChatBubble()}
      <div
        className={`chat-footer relative flex items-center ${message.role === 'assistant' ? 'min-w-[90%]' : ''}`}
      >
        {message.role === 'assistant' && (
          <>
            <span className="flex-1">
              This answer can contain mistakes. Check this response and sources
              carefully.
            </span>
            {!isAssistantGenerating && (
              <div className="bg-content1 absolute right-0 z-10 rounded-lg -top-4 shadow-medium">
                <FeedbackWidget
                  size="sm"
                  commentingEnabled
                  initialFeedback={message.feedback}
                  ratingType="thumbs"
                  entityType="message"
                  entityId={message.id || ''}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(ChatMessage)
