import { iItem } from './iItem.ts'
import { iSourceFileCitation } from './iSourceFileCitations.ts'
import { iFeedback } from './iFeedback.ts'
import { iFileData } from './iFile.ts'

export interface iMessage extends iItem {
  role: string
  error?: iGenerationError
  content?: string
  contentItems?: iContentItem[]
  contentType?: string
  contentObject?: string | undefined
  ownerId?: string
  hidden?: boolean
  autoHide?: boolean
  messageTokenCount?: number
  promptTokenCount?: number
  completionTokenCount?: number
  totalTokenCount?: number
  citations?: iSourceFileCitation
  sessionId: string
  toolCalls?: iToolCall[]
  isAudio?: boolean
  files?: File[]
  filesData?: iFileData[]
  feedback?: iFeedback
  generationTime?: iGenerationTime
}

export interface iToolCall {
  toolCallId: string
  toolCallName: string
  toolCallDisplayName: string
  toolCallArguments: string
  toolCallContent: string
  toolCallHttpContext: iToolCallHttpContext
}

export interface iContentItem {
  index: number
  content: string
  type: ContentItemType
  mimeType?: string
}

export enum ContentItemType {
  Text,
  Image,
  Document,
}

interface iGenerationTime {
  backend: number
  end2end: number
}

export interface iToolCallHttpContext {
  requestCompileError: string
  requestError: string
  responseError: string
  request: iHttpToolRequest
  response: iHttpToolResponse
}

interface iHttpToolRequest {
  url: string
  method: string
  body: string
  headers: { [key: string]: string }
}

interface iHttpToolResponse {
  statusCode: number
  body: string
  headers: { [key: string]: string }
}

interface iGenerationError {
  message: string
  code: string
}
