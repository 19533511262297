import React, { useEffect } from 'react'
import { Avatar, Spinner, Tooltip } from '@nextui-org/react'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import GaiaLogo from '../basic/logo/GaiaLogo.tsx'
import { isDefaultTeam, iTeam } from '../../interfaces/iTeam.ts'

type TeamIconProps = React.HTMLAttributes<HTMLDivElement> & {
  team: iTeam
  isDisabled?: boolean
  isLoading?: boolean
}

const TeamIcon = ({ team, onClick, isLoading, isDisabled }: TeamIconProps) => {
  const teamIcon = isDefaultTeam(team) ? (
    <GaiaLogo className={'w-5 h-5'} />
  ) : (
    <UserGroupIcon className={'h-5 w-5'} />
  )
  return isLoading ? (
    <Spinner size="md" />
  ) : (
    <Avatar
      isDisabled={isDisabled || isLoading}
      alt={team.name}
      className="flex-shrink-0"
      size="md"
      onClick={onClick}
      icon={teamIcon}
    />
  )
}

export default React.memo(TeamIcon)
