import { createWithEqualityFn } from 'zustand/traditional'
import userService from '../services/UserService.ts'
import { iAssistantVariable, iUser } from '../interfaces/iUser.ts'

interface iUserProfileState {
  isLoading: boolean
  userProfile: iUser | null
  setIsLoading: (isLoading: boolean) => void
  setUserProfile: (userProfile: iUser) => void
  acceptedToUVersion: (resourceId: string) => Promise<void>
  loadUserProfile: () => Promise<iUser>
  updateUserProfileChangelog: (changelogDate: string) => Promise<void>
  updateAssistantVariable: (assistantVariable: iAssistantVariable) => Promise<void>
  deleteAssistantVariable: (variable: iAssistantVariable) => Promise<void>
}

const useUserProfileStore = createWithEqualityFn<iUserProfileState>(
  (set, getState) => ({
    isLoading: false,
    userProfile: null,
    setUserProfile: (userProfile: iUser) => {
      set(() => ({ userProfile }))
    },

    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    loadUserProfile: async () => {
      const user = await userService.getMe()
      getState().setUserProfile(user)
      return user
    },
    acceptedToUVersion: async (resourceId: string) => {
      const date = new Date().toISOString()
      await userService.acceptedToUVersion(date, resourceId)
      const tmpUserProfile = getState().userProfile!
      tmpUserProfile.acceptedToUVersion = {
        ...tmpUserProfile.acceptedToUVersion,
        [resourceId]: date,
      }
      getState().setUserProfile(tmpUserProfile)
    },
    updateUserProfileChangelog: async (changelogDate: string) => {
      const tmpUserProfile = getState().userProfile!
      tmpUserProfile.changelogdate = changelogDate
      getState().setUserProfile(tmpUserProfile)
      await userService.updateUserProfileChangelog(changelogDate)
    },
    updateAssistantVariable: async (assistantVariable: iAssistantVariable) => {
      const tmpUserProfile = getState().userProfile!
      const assistantVariables = tmpUserProfile.assistantVariables || []
      const index = assistantVariables.findIndex(
        (v) => v.name === assistantVariable.name,
      )
      if (index !== -1) {
        assistantVariables[index] = assistantVariable
      } else {
        assistantVariables.push(assistantVariable)
      }
      tmpUserProfile.assistantVariables = assistantVariables
      getState().setUserProfile(tmpUserProfile)
      await userService.updateUserProfileAssistantVariables(assistantVariable)
    },
    deleteAssistantVariable: async (variable: iAssistantVariable) => {
      const tmpUserProfile = getState().userProfile!
      const assistantVariables = tmpUserProfile.assistantVariables || []
      const index = assistantVariables.findIndex((v) => v.name === variable.name)
      if (index !== -1) {
        assistantVariables.splice(index, 1)
        tmpUserProfile.assistantVariables = assistantVariables
        getState().setUserProfile(tmpUserProfile)
        await userService.deleteUserProfileAssistantVariable(variable)
      }
    },
  }),
)

export default useUserProfileStore
