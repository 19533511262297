import { iRole, iShared, iShareLink } from './iShared.ts'
import { iItem } from './iItem.ts'

export interface iTeam extends iItem {
  name: string
  costCenter: string
  spent: number
  budget: number
  description: string
  shared: iShared[]
  selected?: boolean
  image?: string
  isAssistantAccessLimited?: boolean
  // TODO remove in future versions
  assistantId?: string
  apiAccess?: boolean
  shareLinks: iShareLink[]
  resourceRestrictions: {
    assistantIds: string[] | null
    knowledgeContainerIds: string[] | null
    modelIds: string[] | null
    toolIds: string[] | null
  }
}

export const isDefaultTeam = (team: iTeam): boolean =>
  team.id === '00000000-0000-0000-0000-000000000000'
